import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {BehaviorSubject, filter, Observable} from 'rxjs';
import {OnboardingOverlays} from '../../../types/overlays.types';
import {AnalyticsService, ExponeaTypes, OverlayService2} from 'shared';
import {HoldingService} from '../../../services/holding.service';
import {distinctUntilChanged, finalize, tap} from 'rxjs/operators';
import {CompanyHolding} from '../../../types/holding.types';

@Component({
	selector: 'b-registration-holding-company-component',
	templateUrl: './holding-company.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HoldingCompanyComponent implements OnInit {
	onboardingOverlays = OnboardingOverlays;
	holdingCompanies$: Observable<CompanyHolding[]>;
	sendClosePopapExponea: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true); //костыль на закрытие попап чтобы не отправлять событие
	loader = false;
	holdingCompanies: CompanyHolding[] = [];
	#isShowErrorCheck$ = new BehaviorSubject<boolean>(false);
	readonly isShowErrorCheck$ = this.#isShowErrorCheck$.asObservable().pipe(
		distinctUntilChanged(),
	);

	constructor(
		private overlayService: OverlayService2,
		private holdingService: HoldingService,
		private cdr: ChangeDetectorRef,
		private analyticsService: AnalyticsService,
	) {
	}

	ngOnInit() {
		this.holdingCompanies$ = this.holdingService.companiesHolding$.pipe(
			tap(company => {
				this.holdingCompanies = company;
			})
		);
	}

	shortName(companyName: string) {
		const shortName = companyName.substring(0, companyName.indexOf(' '));
		return shortName === 'ИП' ? 'ОГРНИП' : 'ОГРН';
	}

	openCompanyList() {
		this.analyticsService.exponeaTrack(ExponeaTypes.web_action, {
			action_type: 'button click add company notification',
		});

		this.overlayService.open({
			overlayId: this.onboardingOverlays.holdingCompanySelect
		});
	}

	noRegistrationHolding() {
		this.analyticsService.exponeaTrack(ExponeaTypes.web_action, {
			action_type: 'button click no need add company',
			'transition screen': 'registrated'
		});
		this.holdingService.noRegistrationHolding();
	}

	startRegMultiple(): void {
		if (!this.isSomeCompanyChecked) {
			this.#isShowErrorCheck$.next(true);
			return;
		}
		this.loader = true;
		this.holdingService.startRegMultiple(this.holdingCompanies)
			.pipe(
				finalize(() => {
					this.loader = false;
					this.sendClosePopapExponea.next(false);
					this.overlayService.close(this.onboardingOverlays.holdingCompanySelect);
					this.cdr.detectChanges();
				})
			)
			.subscribe();
	}

	hideHandler(): void {
		this.clearCheckedCompanyErrors();
		this.sendClosePopapExponea
			.pipe(
				filter((action) => action),
				tap(() => {
					this.analyticsService.exponeaTrack(ExponeaTypes.web_action, {
						action_type: 'button click other companies later',
						'transition screen': 'registrated'
					});
				})
			).subscribe();
	}

	get isSomeCompanyChecked(): boolean {
		return this.holdingCompanies.some((h) => h.checked);
	}

	clearCheckedCompanyErrors(): void {
		this.#isShowErrorCheck$.next(false);
	}
}
