import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AnalyticsService, StateService, EventAction, EventCategory } from 'shared';
import { AddressesState, Hint } from '../types/city-selection.types';

const initState: AddressesState = {
	addressHints: [],
	addressesLoader: false,
	selectedHint: null,
};

@Injectable({
	providedIn: 'root',
})
export class AddressesService extends StateService<AddressesState> {
	selectedHint$: Observable<Hint> = this.select((state) => state.selectedHint);

	constructor(
		private http: HttpClient,
		private analyticsService: AnalyticsService,
	) {
		super(initState);
	}

	resetState() {
		this.setState(initState);
	}

	setSelectedHint(hint: Hint) {
		this.setState({ selectedHint: hint });
	}

	addressAutocomplete(query: string, city: string): Observable<Hint[]> {
		this.setSelectedHint(null);
		this.setState({ addressHints: [], addressesLoader: true });

		return this.http
			.post<Hint[]>('/api/meetingmanager-service/maps/suggestion/address', {
				query,
				limit: 20,
				city,
				includeOrgs: true,
				ignoreFlat: true,
			})
			.pipe(
				map(hints => hints.filter(i => i?.containsHouse)),
				tap(
					addressHints => {
						this.analyticsService.sendAction(EventAction.manual_input_address_success, EventCategory.user_lead);
						this.setState({
							addressHints,
							addressesLoader: false,
						});
					},
					() => {
						this.setState({
							addressHints: [],
							addressesLoader: false,
						});
					},
				),
			);
	}
}
