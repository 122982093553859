import { HttpMock } from 'shared';
import { City } from '../types/cities-list.types';

const successLoadCities: HttpMock<City[]> = {
	url: '/api/meetingmanager-service/maps/suggestion/nearestcity',
	respBody: {
		value: [
			{
				displayName: 'Уфа',
				id: 'f5faa43c-57bc-43f7-b1c6-fbf8cb95f5a2',
				coordinates: {
					latitude: 54.730297082939394,
					longitude: 56.037724499999975,
				},
				isServiced: true,
				distance: 100,
				enabled: true,
			},
			{
				displayName: 'Москва',
				id: '1f8d2878-cd3f-47c6-82e1-8287fc256253',
				coordinates: {
					latitude: 55.75399399999374,
					longitude: 37.62209300000001,
				},
				isServiced: true,
				distance: 200,
				enabled: true,
			},
			{
				displayName: 'Новосибирск',
				id: 'bd39c764-27f8-4c20-a560-80521ec4fab2',
				coordinates: {
					latitude: 55.000665013597725,
					longitude: 82.95603900000002,
				},
				isServiced: true,
				distance: 300,
				enabled: true,
			},
			{
				displayName: 'Екатеринбург',
				id: 'e1b99cb3-5e9d-4b25-b532-d8e2cebaa823',
				coordinates: {
					latitude: 56.78875104810377,
					longitude: 60.60157099999987,
				},
				isServiced: true,
				distance: 400,
				enabled: true,
			},
			{
				displayName: 'Санкт-Петербург',
				id: '57237272-1b1c-409d-bfb6-5d978a29b206',
				coordinates: {
					latitude: 59.91806799340517,
					longitude: 30.304899499999895,
				},
				isServiced: true,
				distance: 150,
				enabled: true,
			},
			{
				displayName: 'Казань',
				id: '36e58951-7190-4997-a2b6-caf7b3fa44e1',
				coordinates: {
					latitude: 55.767303705804366,
					longitude: 49.09997749999995,
				},
				isServiced: true,
				distance: 600,
				enabled: true,
			},
			{
				displayName: 'Самара',
				id: 'c7630456-05fd-4ebf-a3c0-f5d55f19eb15',
				coordinates: {
					latitude: 53.32210366705235,
					longitude: 50.060883,
				},
				isServiced: true,
				distance: 250,
				enabled: true,
			},
		],
	},
	status: 200,
	// delay: 2500,
};

export const citySelectionMocks = [successLoadCities];
