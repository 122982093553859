import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { from, Observable, forkJoin, catchError, of, take } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { StateService, minTime, AnalyticsService, EventAction, EventCategory, LOCAL_STORAGE } from 'shared';
import { Message, MessageType, Senders } from '../types/chat.types';
import {
	Hint,
	MeetingState,
	Feature,
	MapView,
	GeoJsonResponse,
	GeoJson,
} from '../types/city-selection.types';
import { RegistrationSteps } from '../types/registration.types';
import { ChatService } from './chat.service';
import { DateSelectionService } from './date-selection.service';
import { AddressesService } from './addresses.service';
import { RegistrationStore } from './registration.store';
import { DeviceService } from 'shared';
import { getMessageTimeout } from '../enums/chat.enums';
import { Meeting } from '../types/searchCompany.types';
import { Zone } from '../types/Zone';
import { NearestDates } from '../types/NearestDates';
import { City } from '../types/cities-list.types';

declare let ymaps: any;

const initState: MeetingState = {
	map: null,
	geoJson: null,
	selectedAddressMark: null,
	canHoldMeeting: false,
	meetingPlacesObject: null,
	mapView: MapView.default,
	selectedPolygon: null,
	selectedFeature: null,
	comment: null,
	meeting: null,
	officeZones: [],
	nearestDates: null,
	selectedOffice: null,
	departureFlow: false,
};

@Injectable({
	providedIn: 'root',
})
export class MeetingService extends StateService<MeetingState> {
	companyId: string;

	canHoldMeeting$: Observable<boolean> = this.select((state) => state.canHoldMeeting);
	mapView$: Observable<MapView> = this.select((state) => state.mapView);
	enabledOffices$: Observable<Zone[]> = this.select(state => state.officeZones.filter(office => office.enabled));
	nearestDates$: Observable<NearestDates> = this.select(state => state.nearestDates);

	constructor(
		private http: HttpClient,
		private chat: ChatService,
		private addressesService: AddressesService,
		private dateSelectionService: DateSelectionService,
		private store: RegistrationStore,
		private deviceService: DeviceService,
		private analyticsService: AnalyticsService,
		@Inject(LOCAL_STORAGE) readonly localStorage: Storage,
	) {
		super(initState);
		this.store.selectedCompany$.subscribe((selectedCompany) => {
			if (selectedCompany) {
				this.companyId = selectedCompany.companyId;
			}
		});
	}

	resetMeeting() {
		this.setState({
			...initState,
			geoJson: this.state.geoJson,
		});
	}

	resetState() {
		this.setState(initState);
	}

	// getIconsState(organizationPrefixes: string[]) {
	// 	const iconsState = {};
	// 	const defaultSize = [44, 44];
	// 	const defaultOffset = [-22, -22];
	// 	const activeSize = [57, 62];
	// 	const activeOffset = [-28, -62];

	// 	organizationPrefixes.forEach((prefix) => {
	// 		iconsState[prefix] = {
	// 			default: {
	// 				icon: `/assets/img/meeting-points/${prefix}-default.svg`,
	// 				size: defaultSize,
	// 				offset: defaultOffset,
	// 			},
	// 			active: {
	// 				icon: `/assets/img/meeting-points/${prefix}-active.svg`,
	// 				size: activeSize,
	// 				offset: activeOffset,
	// 			},
	// 		};
	// 	});
	// 	return iconsState;
	// }

	changeComment(comment: string) {
		this.setState({ comment });
	}

	getGeoJson() {
		return this.http
			.get<GeoJsonResponse>('/api/meetingmanager-service/maps/geojson', {
				headers: new HttpHeaders({
					responseFormat: 'standart',
				}),
			})
			.pipe(
				map(
					(geoJson): GeoJson => ({
						points: {
							...geoJson,
							features: geoJson.features
								.filter(
									(object) => object.geometry.type === 'Point' && object.properties?.organizationId,
								)
								.map((object) => ({
									...object,
									geometry: {
										...object.geometry,
										coordinates: [...object.geometry.coordinates].reverse(), // spread, т.к. reverse мутирует исходный массив
									},
								})),
						},
						polygons: {
							...geoJson,
							features: geoJson.features
								.filter((object) => object.geometry.type === 'Polygon')
								.map((object) => ({
									...object,
									geometry: {
										...object.geometry,
										coordinates: object.geometry.coordinates.map((i) =>
											i.map((coords) => [...coords].reverse()),
										),
										// spread, т.к. reverse мутирует исходный массив
									},
								})),
						},
					}),
				),
				tap((geoJson: GeoJson) => {
					this.setState({ geoJson });
				}),
			);
	}

	resetMapState() {
		this.setState({
			selectedAddressMark: null,
			mapView: MapView.default,
		});
	}

	createServiceZones() {
		const zones = this.state.geoJson.polygons.features;
		if (zones?.length) {
			zones.forEach((feature) => {
				this.state.map?.geoObjects?.remove(feature.properties?.object);
			});
		}

		const features = this.state.geoJson.polygons.features.map((feature) => {
			const polygon = new ymaps.geometry.Polygon(feature.geometry.coordinates);
			polygon.options.setParent(this.state.map.options);
			polygon.setMap(this.state.map);
			const object = new ymaps.GeoObject(
				{ geometry: feature.geometry },
				{
					fillColor: '#A7FA00',
					fillOpacity: 0.2,
					strokeColor: '#A7FA00',
					strokeWidth: '3',
					strokeOpacity: 0.9,
					strokeStyle: 'solid',
				},
			);
			this.state.map.geoObjects.add(object);

			return {
				...feature,
				properties: feature?.properties
					? { ...feature.properties, polygon, object }
					: { polygon, object },
			};
		});

		this.setState({
			geoJson: {
				...this.state.geoJson,
				polygons: {
					...this.state.geoJson.polygons,
					features,
				},
			},
		});
	}

	getIconLayout({ prefix = 'cafe', state = 'default', style = 'svg placemark' }) {
		const path = `${prefix}-${state}`;

		const layout = ymaps.templateLayoutFactory.createClass(
			`<svg class="${style}">
				<use xlink:href="sprite/sprite.svg#meeting-points_${path}"></use>
			</svg>`,
			{
				build() {
					layout.superclass.build.call(this);
					const size = this.isActive ? 60 : 34;
					const defaultShape = { type: 'Circle', coordinates: [0, 0], radius: size / 2 };
					const activeShape = { type: 'Circle', coordinates: [0, -28.5], radius: size / 2 };
					this.getData().options.set('shape', this.isActive ? activeShape : defaultShape);
				},
			},
		);

		return layout;
	}

	setSelectedPolygon(polygon: Feature) {
		const selectedPolygon = polygon || null;
		this.setState({ selectedPolygon });

		if (selectedPolygon) {
			delete selectedPolygon?.properties?.object;
			delete selectedPolygon?.properties?.polygon;
			this.localStorage?.setItem(`SelectedZone_${this.companyId}`, JSON.stringify(selectedPolygon));
		} else {
			this.localStorage?.removeItem(`SelectedZone_${this.companyId}`);
		}
	}

	setSelectedFeature(selectedFeature: Feature) {
		this.setState({ selectedFeature });

		if (selectedFeature) {
			this.localStorage?.setItem(`SelectedAddressFeature_${this.companyId}`, JSON.stringify(selectedFeature));
		} else {
			this.localStorage?.removeItem(`SelectedAddressFeature_${this.companyId}`);
		}
	}

	selectPoint({ feature, pointPolygon, sessionFlow = false }: {
		feature?: Feature,
		pointPolygon?: Feature,
		sessionFlow?: boolean,
	}) {
		return from(ymaps.ready()).pipe(
			mergeMap(() => {
				this.store.changeStep(RegistrationSteps.empty);

				const point = { ...feature };

				this.setSelectedFeature(point);

				const options = {
					iconLayout: this.getIconLayout({
						prefix: point?.properties?.organizationId || 'cafe',
						state: 'active',
						style: 'svg placemark placemark--active',
					}),
					cursor: 'default',
				};

				const selectedPolygon =
					pointPolygon ||
					[...this.state.geoJson.polygons.features].find((f) => {
						return f.properties?.polygon?.contains(point.geometry.coordinates);
					});

				this.setSelectedPolygon(selectedPolygon);

				this.state?.map?.destroy();
				this.setState({ map: null });

				const meetingPlaceMark = new ymaps.Placemark(
					point.geometry.coordinates,
					{},
					options,
				);

				this.chat.pushMessage({
					from: Senders.client,
					type: MessageType.meetHere,
				});

				this.chat.postponeAction(() => {
					this.chat.changeChatLoader(true);
				}, getMessageTimeout(1)).subscribe();

				const isOfficeMeeting = point?.properties?.organizationId === 'office';
				this.chat.pushMessage({
					from: Senders.client,
					type: MessageType.meetingPlaceOnMap,
					editableStep: isOfficeMeeting ? RegistrationSteps.meetingTypeSelection : RegistrationSteps.addressSearch,
					data: { feature: point, meetingPlaceMark },
					timeout: getMessageTimeout(2),
				});

				const request = this.dateSelectionService
					.getAcessibleDates({
						geoZone: selectedPolygon.id,
					});

				const observable = sessionFlow ?
					request :
					request.pipe(minTime(getMessageTimeout(2) + 500));

				return observable.pipe(
					tap({
						next: () => {
							if (!sessionFlow) {
								this.analyticsService.sendAction(EventAction.address_success, EventCategory.user_lead);
							}

							this.chat.pushMessage({
								from: Senders.bank,
								type: MessageType.decideOfDate,
							});
							this.chat.changeChatLoader(false);
							this.store.changeStep(RegistrationSteps.datePicker);
						},
						error: (response) => {
							this.chat.pushMessage({
								from: Senders.bank,
								type: MessageType.errorMessage,
								data: {
									errorMessage: response?.error?.message
								},
							});
							this.chat.changeChatLoader(false);
							this.store.changeStep(RegistrationSteps.meetingTypeSelection);
						},
					}),
					take(1),
				);
			}),
		);
	}

	createMeetingPlaces() {
		this.state.map?.geoObjects?.remove(this.state.meetingPlacesObject);

		this.setState({ meetingPlacesObject: null });

		const meetingPlacesObject = new ymaps.ObjectManager({
			clusterize: false,
			geoObjectOpenHintOnHover: false,
			geoObjectOpenBalloonOnClick: false,
			geoObjectHasHint: false,
			geoObjectHasBalloon: false,
		});

		const objects = this.state.geoJson.points.features.map((feature) => {
			const prefix = feature?.properties?.organizationId === 'address' ? 'address' : 'cafe';
			return {
				...feature,
				properties: { organizationId: feature.properties.organizationId }, // исключаем прокидку iconCaption с описанием метки
				options: {
					...(feature?.options || {}),
					iconLayout: this.getIconLayout({ prefix, state: 'default', style: 'svg placemark' }),
				}
			};
		});

		meetingPlacesObject.add(objects);
		this.state.map.geoObjects.add(meetingPlacesObject);
		this.setState({ meetingPlacesObject });

		meetingPlacesObject.objects.events.add('click', (e) => {
			const objectId = e.get('objectId');
			const selectedPoint = this.state.geoJson.points.features.find(
				(feature) => feature.id === objectId,
			);

			if (selectedPoint) {
				this.selectPoint({ feature: selectedPoint }).subscribe();
			}
		});
	}

	changeActiveMeeting(meeting: Meeting) {
		this.setState({ meeting });
	}

	changeSelectedOffice(selectedOffice: Zone) {
		this.setState({ selectedOffice });
	}

	changeDepartureFlow(departureFlow: boolean) {
		this.setState({ departureFlow });
	}

	setZoom() {
		const mapBounds = this.state.canHoldMeeting
			? this.getZoomWithNearestPoints(3)
			: this.getZonesBounds(100);

		if (mapBounds && this.state.map) {
			this.state.map.setBounds(mapBounds);
		}
	}

	getZoomWithNearestPoints(pointsCount: number = 3) {
		const selectedHint = this.addressesService.state.selectedHint;

		if (!selectedHint) {
			return;
		}

		const hintCoords = [selectedHint.coordinates.latitude, selectedHint.coordinates.longitude];

		const points = [...this.state.geoJson.points.features].sort(
			(a, b) =>
				this.getDistance(hintCoords, a.geometry.coordinates) -
				this.getDistance(hintCoords, b.geometry.coordinates),
		);
		points.splice(pointsCount);

		const coords = points.map((i) => i.geometry.coordinates);
		coords.push(hintCoords);

		return this.addDiameterToBounds(ymaps.util.bounds.fromPoints(coords));
	}

	getBoundsBasedOnUnits(coords: number[], diameter: number = 0.01) {
		return [
			[coords[0] + diameter, coords[1] - diameter],
			[coords[0] - diameter, coords[1] + diameter],
		];
	}

	addDiameterToBounds(bounds: number[][], diameter: number = 0.005) {
		return [
			[bounds[0][0] + diameter, bounds[0][1] - diameter],
			[bounds[1][0] - diameter, bounds[1][1] + diameter],
		];
	}

	getZonesBounds(kmRadius: number = 100) {
		const selectedHint = this.addressesService.state.selectedHint;

		if (!selectedHint) {
			return;
		}

		const hintCoords = [selectedHint.coordinates.latitude, selectedHint.coordinates.longitude];

		const coords = [...this.state.geoJson.polygons.features]
			.filter((i) => {
				const tops = i.geometry.coordinates[0];
				return tops.some((top) => this.getDistance(top, hintCoords) <= kmRadius * 1000);
			})
			.reduce((accumalator, feature) => {
				const tops = feature.geometry.coordinates[0];
				return [...accumalator, ...tops];
			}, []);

		coords.push(hintCoords);

		return this.addDiameterToBounds(ymaps.util.bounds.fromPoints(coords));
	}

	getDistance(a: number[], b: number[]) {
		const EARTH_RADIUS = 6378137; // радиус земли в метрах
		// переводим угловые координаты в радианы
		const lat1 = (a[0] * Math.PI) / 180;
		const lat2 = (b[0] * Math.PI) / 180;
		const long1 = (a[1] * Math.PI) / 180;
		const long2 = (b[1] * Math.PI) / 180;

		const l = Math.acos(
			Math.sin(lat1) * Math.sin(lat2) + Math.cos(lat1) * Math.cos(lat2) * Math.cos(long2 - long1),
		);
		return EARTH_RADIUS * l;
	}

	showSelectedAddressMark(hint: Hint) {
		this.state.map?.geoObjects?.remove(this.state.selectedAddressMark);
		const coordinates = [hint.coordinates.latitude, hint.coordinates.longitude];

		const canHoldMeeting = this.state.canHoldMeeting;
		const iconLayout = canHoldMeeting
			? this.getIconLayout({
				prefix: 'address',
				state: 'active',
				style: 'svg placemark placemark--active',
			  })
			: this.getIconLayout({
				prefix: 'outside',
				state: 'address',
				style: 'svg placemark placemark--outside',
			  });

		const selectedAddressMark = new ymaps.Placemark(coordinates, {}, { iconLayout });

		this.state.map.geoObjects.add(selectedAddressMark);
		this.setState({ selectedAddressMark });

		if (canHoldMeeting) {
			selectedAddressMark.events.add('click', () => {
				const feature = this.getFeatureFromHint(hint);

				this.selectPoint({ feature }).subscribe();
			});
		}
	}

	getFeatureFromHint(hint: Hint) {
		const iconCaption = hint.organization || null;
		const coordinates = [hint.coordinates.latitude, hint.coordinates.longitude];

		return {
			type: 'Feature',
			id: '10000',
			geometry: { coordinates, type: 'Point' },
			properties: {
				description: hint.address,
				iconCaption,
				organizationId: 'address',
			},
		};
	}

	selectHint(hint: Hint, container) {
		const hintCoords = [hint.coordinates.latitude, hint.coordinates.longitude];

		if (!this.state.map) {
			this.createMap({
				container,
				center: hintCoords,
			});
		}

		this.createServiceZones();

		if (hint && this.state.geoJson.polygons.features.length) {
			const canHoldMeeting = this.state.geoJson.polygons.features.some((feature) =>
				feature?.properties?.polygon?.contains([
					hint.coordinates.latitude,
					hint.coordinates.longitude,
				]),
			);

			this.setState({
				canHoldMeeting,
				mapView: canHoldMeeting ? MapView.withCafesList : MapView.outOfZoneAddress,
			});

			if (canHoldMeeting) {
				const feature = this.getFeatureFromHint(hint);
				this.selectPoint({ feature }).subscribe();
			} else {
				this.showSelectedAddressMark(hint);
			}
		}
	}

	createMap({ center = this.defaultMapState.center, container }) {
		const mapRef = new ymaps.Map(
			container,
			{ ...this.defaultMapState, center },
			this.defaultMapOptions,
		);

		this.setState({ map: mapRef });
	}

	public get defaultMapState() {
		return {
			center: [55.751574, 37.573856],
			zoom: 16,
			controls: [],
			type: 'yandex#map',
		};
	}

	public get defaultMapOptions() {
		return {
			suppressMapOpenBlock: true,
		};
	}

	public async selectNotServicedCity(city: string, requestsCount: number) {
		this.chat.changeChatLoader(true);

		this.chat.pushMessage({
			from: Senders.client,
			type: MessageType.myCity,
			data: { city },
		});

		this.chat.pushMessage({
			from: Senders.client,
			type: MessageType.waitingForManagerArrive,
			editableStep: RegistrationSteps.citySelection,
			timeout: getMessageTimeout(1),
		});

		this.chat.pushMessage({
			from: Senders.bank,
			type: MessageType.excellent,
			timeout: getMessageTimeout(2),
		});

		this.chat.pushMessage({
			from: Senders.bank,
			type: this.deviceService.isDesktopDevice ?
				MessageType.accountReservedAndQr :
				MessageType.accountReservedAndDownloadApp,
			timeout: getMessageTimeout(3),
		});

		if (requestsCount > 0) {
			const plural = (await import('plural-ru')).default;
			const requests = typeof requestsCount === 'number' ?
				`${requestsCount} ${plural(requestsCount, 'заявку', 'заявки', 'заявок')}` :
				'несколько заявок';

			this.chat.pushMessage({
				from: Senders.bank,
				type: MessageType.infoAboutRequests,
				timeout: getMessageTimeout(4),
				data: { requests },
			});
		} else {
			this.chat.pushMessage({
				from: Senders.bank,
				type: MessageType.requestsHasBeenCollected,
				timeout: getMessageTimeout(4),
			});
		}

		if (this.deviceService.isDesktopDevice) {
			this.chat.pushMessage({
				from: Senders.client,
				type: MessageType.viewRequsities,
				timeout: getMessageTimeout(5),
			});
		}

		if (navigator?.share || navigator?.clipboard) {
			this.chat.pushMessage({
				from: Senders.client,
				type: MessageType.shareBank,
				timeout: getMessageTimeout(5),
			});
		}

		if (this.deviceService.isMobileDevice) {
			this.chat.pushMessage({
				from: Senders.client,
				type: MessageType.downloadApp,
				timeout: getMessageTimeout(5),
			});
		}

		this.chat.pushMessage({
			from: Senders.client,
			type: MessageType.openAccountToAnotherNumber,
			timeout: getMessageTimeout(5),
		});

		return this.chat.postponeAction(() => {
			this.store.changeStep(RegistrationSteps.waitingForRequests);
			this.chat.changeChatLoader(false);
		}, getMessageTimeout(5));
	}

	startMeetingTypeSelection(city: City) {
		this.store.changeStep(RegistrationSteps.empty);
		this.chat.changeChatLoader(true);

		this.chat.pushMessage({
			from: Senders.client,
			type: MessageType.myCity,
			editableStep: RegistrationSteps.citySelection,
			data: { city: city.displayName },
		});

		return forkJoin({
			officeZones: this.http.get<Zone[]>(`/api/meetingmanager-service/common/cities/${city.id}/zones/Office`).pipe(catchError(() => of([] as Zone[]))),
			nearestDates: this.http.get<NearestDates>(`/api/meetingmanager-service/client/availableDates/${city.id}/onmeettypes`).pipe(catchError(() => of(null))),
		})
			.pipe(
				tap({
					next: ({ officeZones, nearestDates }) => {
						this.setState({ officeZones, nearestDates });

						const messages: Message[] = [];
						const departureNearestDate = nearestDates?.slots?.departure ? new Date(nearestDates.slots.departure) : null;
						const officeNearestDate = nearestDates?.slots?.office ? new Date(nearestDates.slots.office) : null;
						const officesAreAvailable = Boolean(officeZones?.some(office => office.enabled));

						if (departureNearestDate) {
							messages.push({
								from: Senders.bank,
								type: MessageType.nearestDateOfDepartureMeeting,
								data: { nearestDate: departureNearestDate },
							});
						}

						if (officeNearestDate && officesAreAvailable) {
							messages.push({
								from: Senders.bank,
								type: MessageType.nearestDateOfOfficeMeeting,
								data: {
									nearestDate: officeNearestDate,
									withDeparture: Boolean(departureNearestDate),
								},
							});

							messages.push({
								from: Senders.bank,
								type: MessageType.hereIsHisAddress,
							});
						}

						if (departureNearestDate && !(officeNearestDate && officesAreAvailable)) {
							messages.push({
								from: Senders.bank,
								type: MessageType.messagePublicPlace,
							});
						}

						messages.forEach((message, index) => {
							this.chat.pushMessage({
								...message,
								timeout: getMessageTimeout(index),
							});
						});

						this.chat.postponeAction(() => {
							this.chat.changeChatLoader(false);
							this.store.changeStep(
								(officeNearestDate && officesAreAvailable) ?
									RegistrationSteps.meetingTypeSelection :
									RegistrationSteps.addressSearch
							);
						}, getMessageTimeout(messages.length - 1)).subscribe();
					},
					error: () => {
						this.chat.changeChatLoader(false);
						this.store.changeStep(RegistrationSteps.meetingTypeSelection);
					}
				}),
				take(1),
			);
	}
}
