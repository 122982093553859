import { Injectable } from '@angular/core';
import { RegistrationSteps } from '../types/registration.types';
import phoneStepAnimation from '../lottie-animations/phone-step-animation.json';
import smsStepAnimation from '../lottie-animations/sms-step-animation.json';
import companyStepAnimation from '../lottie-animations/company-step-animation.json';
import meetingStepAnimation from '../lottie-animations/meeting-step-animation.json';
import qrAnimation from '../lottie-animations/qr-animation.json';
import { AnimationConfigWithData, AnimationConfigWithPath, AnimationItem, LottiePlayer } from 'lottie-web';
import { DeviceService, StateService } from 'shared';
import { ChatService } from './chat.service';
import { RegistrationStore } from './registration.store';
import { combineLatest } from 'rxjs';

interface LottieAnimationsState {
	canShowQrCode: boolean;
	hasLoading: boolean;
	animationContainerClass: string;
}

const initState = {
	canShowQrCode: false,
	hasLoading: false,
	animationContainerClass: null,
};

@Injectable()
export class LottieAnimationsService extends StateService<LottieAnimationsState> {

	canShowQrCode$ = this.select(state => state.canShowQrCode);
	animationContainerClass$ = this.select(state => state.animationContainerClass);

	lottie: LottiePlayer;
	svgAnimation: AnimationItem;
	targetAnimation;
	listenLoopComplete = false;
	currentAnimation = null;
	animationInWaiting;
	animations = {
		[RegistrationSteps.start]: { json: phoneStepAnimation },
		[RegistrationSteps.whereRegistration]: { json: phoneStepAnimation },
		[RegistrationSteps.enterPhone]: { json: phoneStepAnimation },
		[RegistrationSteps.opfSelection]: { json: phoneStepAnimation },
		[RegistrationSteps.enterSms]: {
			json: smsStepAnimation,
			containerClass: 'xxl:max-w-60p xl:max-w-75p',
		},
		[RegistrationSteps.searchCompany]: {
			json: companyStepAnimation,
			containerClass: 'xl:max-w-400 max-w-300',
		},
		[RegistrationSteps.noCompanyInfoInTaxService]: { json: companyStepAnimation },
		[RegistrationSteps.accountIsAlreadyOpen]: { json: companyStepAnimation },
		[RegistrationSteps.citySelection]: { json: meetingStepAnimation },
		[RegistrationSteps.meetingTypeSelection]: { json: meetingStepAnimation },
		[RegistrationSteps.addressSearch]: { json: meetingStepAnimation },
		[RegistrationSteps.last]: { json: qrAnimation },
		[RegistrationSteps.accountOpeningInProcess]: { json: qrAnimation },
		[RegistrationSteps.waitingForRequests]: { json: qrAnimation },
	};

	constructor(
		private chatService: ChatService,
		private registrationStore: RegistrationStore,
		private deviceService: DeviceService,
	) {
		super(initState);

		combineLatest([
			this.chatService.chatLoader$,
			this.chatService.authLoader$,
			this.registrationStore.blockingLoader$,
		]).subscribe(loaders => {
			const hasLoading = loaders.some(Boolean);
			this.setState({ hasLoading });

			if (this.animationInWaiting && !hasLoading) {
				this.animationFlowByStep({...this.animationInWaiting});
				this.animationInWaiting = null;
			}
		});
	}

	resetState() {
		this.setState(initState);
		this.svgAnimation?.destroy();
		this.svgAnimation = null;
		this.targetAnimation = null;
		this.listenLoopComplete = false;
		this.animationInWaiting = null;
		this.currentAnimation = null;
	}

	async animationFlowByStep({ step, animationContainer }: {
		step: RegistrationSteps,
		animationContainer: HTMLElement,
	}) {
		if (!this.deviceService.viewport.min.lg) {
			return;
		}

		const stepAnimation = this.animations?.[step];
		this.targetAnimation = stepAnimation ?? this.targetAnimation;
		const isOldAnimation = this.currentAnimation &&
			this.targetAnimation?.json?.nm === this.currentAnimation?.nm;

		if (!stepAnimation || isOldAnimation) {
			return;
		}

		if (this.state.hasLoading) {
			this.animationInWaiting = { step, animationContainer };
			return;
		}

		this.lottie = (await import('lottie-web')).default;
		const isStepWithQrCode = [
			RegistrationSteps.last,
			RegistrationSteps.accountOpeningInProcess,
			RegistrationSteps.waitingForRequests,
		].includes(step);
		this.currentAnimation = {...this.targetAnimation?.json};

		if (!isStepWithQrCode) {
			this.setState({ canShowQrCode: false });
		}

		if (this.svgAnimation) {
			this.svgAnimation.play();

			if (!this.listenLoopComplete) {
				this.svgAnimation.addEventListener('loopComplete', async () => {
					this.svgAnimation.destroy();
					this.svgAnimation = null;

					this.createLottieAnimation(this.targetAnimation, animationContainer);

					if (isStepWithQrCode) {
						this.setState({ canShowQrCode: true });
					} else {
						this.startPauseListner(this.svgAnimation);
					}

					this.listenLoopComplete = false;
				});
				this.listenLoopComplete = true;
			}
		} else {
			this.createLottieAnimation(this.targetAnimation, animationContainer);
			if (isStepWithQrCode) {
				this.setState({ canShowQrCode: true });
			} else {
				this.startPauseListner(this.svgAnimation);
			}
		}
	}

	getLottieConfig(animationData, container: HTMLElement): AnimationConfigWithPath | AnimationConfigWithData {
		return {
			container,
			renderer: 'svg',
			loop: true,
			autoplay: true,
			animationData,
		};
	}

	startPauseListner(lottieAnimation) {
		const enterFrameHandler = (event) => {
			const frames = event.totalTime - event.direction;
			const middleFrame = Math.round(frames / 2);
			const currentTime = event.currentTime;
			const isMiddle = Math.abs(middleFrame - currentTime) <= 1;

			if (isMiddle) {
				lottieAnimation?.pause();
				lottieAnimation?.removeEventListener('enterFrame', enterFrameHandler);
			}
		};

		lottieAnimation?.addEventListener('enterFrame', enterFrameHandler);
	}

	createLottieAnimation(
		targetAnimation,
		animationContainer: HTMLElement,
	) {
		this.svgAnimation = this.lottie.loadAnimation(
			this.getLottieConfig(targetAnimation?.json, animationContainer)
		);

		this.setState({ animationContainerClass: targetAnimation?.containerClass || null });
	}

}
