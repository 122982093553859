import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
	AfterViewInit,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, takeWhile } from 'rxjs/operators';
import {ValidatorsService, MaskService, DeviceService, AnalyticsService, ExponeaTypes} from 'shared';
import { getMessageTimeout } from '../../../enums/chat.enums';
import { AuthService } from '../../../services/auth.service';
import { ChatService } from '../../../services/chat.service';
import { RegistrationStore } from '../../../services/registration.store';
import { SmsBlockingService } from '../../../services/sms-blocking.service';
import { VerifyService } from '../../../services/verify.service';
import { MessageType, Senders } from '../../../types/chat.types';
import { RegistrationSteps, SmsStepStatus } from '../../../types/registration.types';

@Component({
	selector: 'b-registration-sms-confirm',
	templateUrl: './sms-confirm.component.html',
})
export class SmsConfirmComponent implements OnInit, AfterViewInit {
	smsForm: UntypedFormGroup;
	subscriptions = new Subscription();

	@ViewChild('repeatCodeButton', { static: false }) repeatCodeButton;

	/** Для реги в шторке */
	@Input() isOverlay: boolean = false;

	@Output() login: EventEmitter<any> = new EventEmitter<any>();

	smsStep;
	smsStepStatus = SmsStepStatus;

	codeButtonClasses: { [key: string]: boolean } = { 'shaking-x': false };
	@ViewChild('smsInput') smsInput?: ElementRef<HTMLInputElement>;

	constructor(
		private validatorsService: ValidatorsService,
		private fb: UntypedFormBuilder,
		private verifyService: VerifyService,
		private authService: AuthService,
		private chat: ChatService,
		private mask: MaskService,
		private smsBlockingService: SmsBlockingService,
		private store: RegistrationStore,
		private deviceService: DeviceService,
		private changeDetector: ChangeDetectorRef,
		public elementRef: ElementRef,
		private analyticsService: AnalyticsService,
	) {}

	ngOnInit(): void {
		this.subscriptions.add(
			this.smsBlockingService.smsStep$.subscribe(smsStep => {
				this.smsStep = smsStep;
				this.changeDetector.detectChanges();
			})
		);

		this.smsForm = this.fb.group({
			smsCode: ['', [Validators.required, this.validatorsService.fieldLength(4)]],
		});

		this.subscribeOnSmsField();
	}

	submitSms(): void {
		if (this.smsForm.controls.smsCode.valid) {
			const phone = this.authService.state.lastNumberEntered.replace(/\D/g, '');
			this.store.changeStep(RegistrationSteps.empty);

			this.analyticsService.exponeaTrack(ExponeaTypes.web_action, {
				action_type: 'button click step 2 authorization',
			});

			this.subscriptions.add(
				this.verifyService
					.verify({
						code: this.smsForm.controls.smsCode.value,
						confirmToken: this.authService.state.confirmToken,
						phoneNumber: phone,
					})
					.subscribe({
						next: () => {
							this.smsBlockingService.changeSmsStep(SmsStepStatus.default);
							clearInterval(this.smsBlockingService.state.interval);
							this.smsBlockingService.changeTimeToUnlockSmsSending(null);

							this.authService.deleteSmsState(phone);
							this.authService.deleteOutdatedSms();
						},
						error: () => {
							this.smsForm.reset();
							clearInterval(this.smsBlockingService.state.interval);
							this.subscribeOnSmsField();
						},
					}),
			);
		}
	}

	subscribeOnSmsField() {
		this.subscriptions.add(
			this.smsForm.controls.smsCode.valueChanges
				.pipe(
					map((value) => {
						const sms = this.mask.onlyNumbers(value);
						this.smsForm.patchValue({ smsCode: sms }, { emitEvent: false });
						// устанавливаем состояние ввода смс
						this.store.setSmsValue(value, this.smsForm.valid);
						return sms;
					}),
					filter((value: string) => value?.length > 0),
					distinctUntilChanged(),
					takeWhile((value: string) => value?.length !== 4),
				)
				.subscribe({
					complete: () => this.submitSms(),
				}),
		);
	}

	codeNotCome() {
		this.store.changeStep(RegistrationSteps.empty);

		this.chat.pushMessage({
			from: Senders.client,
			type: this.smsStep === SmsStepStatus.default
				? MessageType.codeNotCome
				: MessageType.sendNewCode,
		});

		this.chat.postponeAction(() => {
			this.chat.changeChatLoader(true);
		}, getMessageTimeout(1)).subscribe();

		this.chat.pushMessage({
			from: Senders.bank,
			type: MessageType.clarifyingNumberCorrectness,
			timeout: getMessageTimeout(2),
			data: {
				phoneNumber: this.mask.parsePhone(this.authService.state.lastNumberEntered),
			},
		});

		this.chat.pushMessage({
			from: Senders.bank,
			type: MessageType.clarifyingOfTypoPresence,
			timeout: getMessageTimeout(3),
		});

		this.chat.postponeAction(() => {
			this.startBlockingFlow();
			this.chat.changeChatLoader(false);
			this.smsBlockingService.changeSmsStep(SmsStepStatus.codeNotCome);
			this.store.changeStep(RegistrationSteps.enterSms);
		}, getMessageTimeout(3)).subscribe();
	}

	startBlockingFlow() {
		const lifetime = this.authService.getSmsLifetime();
		const date = lifetime?.[this.authService?.state?.lastNumberEntered];

		if (!date) {
			return;
		}

		this.checkTime(date);

		const interval = setInterval(() => {
			this.checkTime(date);
			if (!this.smsBlockingService.state.timeToUnlockSmsSending) {
				clearInterval(this.smsBlockingService.state.interval);
			}
		}, 1000);

		this.smsBlockingService.changeInterval(interval);
	}

	checkTime(date: string) {
		const smsIsActive = this.authService.checkSmsActivity(this.authService.state.lastNumberEntered);

		if (!smsIsActive) {
			this.smsBlockingService.changeTimeToUnlockSmsSending(null);
			this.authService.deleteOutdatedSms();
		} else {
			const timeDifference = (new Date(date).getTime() - new Date().getTime()) / 1000;
			this.smsBlockingService.changeTimeToUnlockSmsSending(Math.ceil(Math.abs(timeDifference)));
		}
	}

	changePhoneNumber() {
		this.smsBlockingService.changeSmsStep(SmsStepStatus.default);
		clearInterval(this.smsBlockingService.state.interval);
		this.smsBlockingService.changeTimeToUnlockSmsSending(null);
		this.store.changeStep(RegistrationSteps.empty);

		this.chat.pushMessage({
			from: Senders.client,
			type: MessageType.changePhoneNumber,
		});

		this.chat.postponeAction(() => {
			this.chat.changeChatLoader(true);
		}, getMessageTimeout(1)).subscribe();

		this.chat.pushMessage({
			from: Senders.bank,
			type: MessageType.enterNewPhoneNumber,
			timeout: getMessageTimeout(2),
		});

		this.chat.postponeAction(() => {
			this.store.changeStep(RegistrationSteps.enterPhone);
			this.chat.changeChatLoader(false);
		}, getMessageTimeout(3)).subscribe();
	}

	sendCodeAgain() {
		if (this.smsBlockingService.state.timeToUnlockSmsSending) {
			this.codeButtonClasses['shaking-x'] = true;
			this.repeatCodeButton?.el?.blur();
			return;
		}

		clearInterval(this.smsBlockingService.state.interval);
		this.smsBlockingService.changeTimeToUnlockSmsSending(null);

		this.chat.pushMessage({
			from: Senders.client,
			type: MessageType.sendNewCode,
		});

		this.smsBlockingService.changeSmsStep(SmsStepStatus.default);
		this.smsBlockingService.startSmsBlockingFlow();

		const phone = this.authService.state.lastNumberEntered;
		this.login.emit({ phone, again: true, showPhone: false });
	}

	animationEnd() {
		this.codeButtonClasses['shaking-x'] = false;
	}

	ngAfterViewInit(): void {
		this.focusSmsInput();
	}

	private focusSmsInput(): void {
		if (!this.smsInput) {
			return;
		}

		this.smsInput.nativeElement.focus();
	}
}
