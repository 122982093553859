import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Message } from '../../../types/chat.types';

@Component({
	selector: 'b-registration-default-message',
	templateUrl: './default-message.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefaultMessageComponent {
	@Input() message: Message;

	constructor() {}
}
