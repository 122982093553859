<form [formGroup]="companySearchForm" class="w-100p">
	<div
		b-shared-validation
		class="field drop drop--button drop--overlay"
		[ngClass]="{
			'field--reg': isOverlay
		}"
		[control]="searchFieldControl"
		[errors]="{ noCompany: noCompany, defaultError: defaultError }"
		pos="top left"
	>
		<b-shared-autocomplete
			#autocompleteRef
			*ngIf="searchFieldControl"
			[placeholder]="placeholder"
			formControlName="searchField"
			[displayWith]="displayFn"
			[debounceTime]="400"
			overlayClass="z-10 brs-12"
			suggestPosition="above"
			(selectHandler)="selectCompany($event)"
			loaderType="input"
			[clearable]="false"
			[searchIcon]="true"
			[completeStream]="getCompanySearchStream"
			[hideOverlayWhenOutsideClicked]="false"
			(overlayAnimationEndHandler)="overlayAnimationEndHandler($event)"
			(clearHandler)="clearHandler()"
			[trackBySuggests]="trackByCompanies"
		>
			<ng-template let-option #optionTemplate>
				<h4 *ngIf="option.companyName" class="h4 text-wrap mb-4">{{ option.companyName }}</h4>
				<div class="text-sm d-flex flex-wrap">
					<p *ngIf="option.inn" class="mr-12">ИНН: {{ option.inn }}</p>
					<p *ngIf="option.ogrn">ОГРН: {{ option.ogrn }}</p>
				</div>
				<p *ngIf="option.registrationDate || option.city" class="text-sm text-wrap">
					Регистрация:
					<span *ngIf="option.registrationDate">{{
						option.registrationDate | date: 'dd.MM.yyyy'
					}}</span>
					<span *ngIf="option.registrationDate && option.city">, </span>
					<span *ngIf="option.city">{{ option.city }}</span>
				</p>
			</ng-template>

			<button
				ngProjectAs="contentAfterList"
				b-shared-button
				(click)="noMyCompany()"
				class="btn btn--secondary btn--sm pos-absolute pos-right-0 pos-bottom-100p mb-8"
				type="button"
			>
				Нет моей компании
			</button>
		</b-shared-autocomplete>


		<div
			*ngIf="companyNotFound"
			ngProjectAs="optionalContent"
			class="field__error-actions"
			[ngClass]="{'pt-24': isOverlay}"
		>
			<button type="button" class="btn btn--secondary btn--sm ml-auto" (click)="newlyRegistered()">
				Я&nbsp;недавно зарегистрировался
			</button>
		</div>

		<ng-template #noCompany>
			<p>Не&nbsp;можем найти компанию</p>
			<p class="mt-4">Это верные данные?</p>
		</ng-template>

		<ng-template #defaultError>
			Произошла ошибка при выполнении операции. Повторите попытку позже
		</ng-template>
	</div>
</form>
