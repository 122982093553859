import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { distinctUntilChanged, Subscription } from 'rxjs';
import { RegistrationPartner } from '../../../enums/RegistrationPartner';
import { LottieAnimationsService } from '../../../services/lottie-animations.service';
import { RegistrationStore } from '../../../services/registration.store';

@Component({
	selector: '[b-registration-step-animation]',
	templateUrl: './step-animation.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('fadeAnimation', [
			transition(':enter', [
				style({ opacity: 0 }),
				animate('500ms ease-out', style({ opacity: 1 })),
			]),
			transition(':leave', [
				animate('300ms ease-out', style({ opacity: 0 })),
			]),
		]),
	],
})
export class StepAnimationComponent implements AfterViewInit, OnDestroy {
	@Input() partner: RegistrationPartner | null = null;

	@Input() isReferral = false;

	@ViewChild('animationContainer', { static: false }) animationContainer: ElementRef;

	subscriptions = new Subscription();
	registrationPartner = RegistrationPartner;
	canShowQrCode$ = this.animationsService.canShowQrCode$;
	animationContainerClass$ = this.animationsService.animationContainerClass$
		.pipe(distinctUntilChanged());

	constructor(
		private animationsService: LottieAnimationsService,
		private store: RegistrationStore,
	) {}

	ngAfterViewInit() {
		if (!this.animationContainer) return;

		this.subscriptions.add(
			this.store.step$
				.pipe(distinctUntilChanged())
				.subscribe(step => {
					this.animationsService.animationFlowByStep({
						step,
						animationContainer: this.animationContainer.nativeElement,
					});
				})
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
		this.animationsService.resetState();
	}

}
