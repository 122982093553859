import { City } from './cities-list.types';
import { MeetingStatus } from './registration.types';

export interface Company {
	companyName: string;
	ogrn: string;
	inn: string;
	kpp: string;
	city: string;
	address: string;
	registrationDate: string;
	leaderFIO: string;
}

export enum RegistrationStatus {
	None = 'None',
	AssignedCompany = 'AssignedCompany', // компания выбрана
	CheckingCompany = 'CheckingCompany', // проверка компании
	AccountReservation = 'AccountReservation', // бронирование счёта
	CheckingPersons = 'CheckingPersons',
	Refusal = 'Refusal', // отказ в регистрации
	Completed = 'Completed', // регистрация завершена
	WaitCompanyProcess = 'WaitCompanyProcess',
}

export enum MeetingType {
	Video = 'Video',
	Office = 'Office',
	Departure = 'Departure',
}

export enum CompanyStatus {
	None = 'None',
	Registration = 'Registration',
	AccountOpened = 'AccountOpened',
	AccountClosed = 'AccountClosed',
}

export interface SelectedCompany {
	companyId: string;
	definedCity: string | null;
	inn: string;
	ogrn: string;
	kpp: string;
	registrationStatus: RegistrationStatus;
	shortname: string;
	status: CompanyStatus;
	fnsRegistrationDate: string;
	opfShort: string;
	opfFull: string;
	mainOkved: string;
	sharedCapital: number;
	accountNumber: string;
	foundInPublicRefs: boolean;
}

export interface SearchCompanyData {
	error: string | null;
	selectedCompanyCity: City | null;
}

export interface SearchCompaniesRequest {
	searchValue: string;
}

export type SearchCompaniesResponse = Company[];

export interface Meeting {
	id: string;
	slotId: string;
	companyId: string;
	status: MeetingStatus;
	city: string;
	address: string;
	meetPlacement: string;
	startAt: string;
	finishAt: string;
	comment: string;
	meetType: MeetingType;
	createdOn: string;
	lastModified: string;
}

export interface CompanyDetailsResponse {
	accountNumber: string;
	inn: string;
	kpp: string;
	ogrn: string;
	shortname: string;
};
