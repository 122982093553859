<div class="d-flex sm:flex-row flex-column flex-ai-end flex-jc-end">
	<button
		b-shared-button
		class="btn btn--secondary btn--sm sm:mr-8 sm:mb-0 mb-8"
		(click)="selectOpf.emit(opf.IndividualEntrepreneur)"
	>
		Нужен счёт для&nbsp;ИП
	</button>
	<button
		b-shared-button
		class="btn btn--secondary btn--sm"
		(click)="selectOpf.emit(opf.LimitedLiabilityCompany)"
	>
		Нужен счёт для&nbsp;ООО
	</button>
</div>
