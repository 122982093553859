import { HttpMock } from 'shared';
import { SearchCompaniesResponse } from '../types/searchCompany.types';

const successSearchCompany: HttpMock<SearchCompaniesResponse> = {
	url: '/api/dadata-service/company/find',
	respBody: {
		value: [
			{
				companyName: 'ООО "Артель"',
				ogrn: '325782389538495',
				inn: '3475834595',
				kpp: 'kpp',
				city: 'г. Москва',
				address: 'address',
				registrationDate: '2020-12-11T06:06:20.416Z',
				leaderFIO: 'leaderFIO',
			},
			{
				companyName: 'ОАО "ПРЭ"',
				ogrn: '534785873487534',
				inn: '5347853095',
				kpp: 'kpp',
				city: 'г. Тверь',
				address: 'address',
				registrationDate: '2018-08-04T06:06:20.416Z',
				leaderFIO: 'leaderFIO',
			},
			{
				companyName: 'ООО "Компания"',
				ogrn: '534785873487534',
				inn: '5347853095',
				kpp: 'kpp',
				city: 'г. Санкт-Петербург',
				address: 'address',
				registrationDate: '2010-01-01T06:06:20.416Z',
				leaderFIO: 'leaderFIO',
			},
		],
	},
	status: 200,
	delay: 2500,
};

const successSelectCompany: HttpMock = {
	url: '/api/accounts-service/registration/start',
	respBody: {
		value: {
			companyId: '2cdd9084-a5dc-4378-9972-6a8db101f2fb',
			definedCity: 'Уфа',
			ogrn: '534785873487534',
			inn: '5347853095',
			registrationStatus: 'AssignedCompany',
			shortname: 'ОАО "ПРЭ"',
			status: 'Registration',
		},
	},
	status: 200,
	// delay: 2500,
};

export const searchCompanyMocks = [successSearchCompany, successSelectCompany];
