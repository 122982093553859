<form [formGroup]="citiesFilter" class="mb-8 px-8">
	<div class="field field--secondary">
		<input
			type="text"
			pInputText
			class="field__control"
			formControlName="searchField"
			placeholder="Название города"
			#searchInput
		/>
		<div class="field__addon field__addon--icon layered-content d-grid">
			<svg
				*ngIf="!loader && fieldIsEmpty"
				[@fadeAnimation]
				b-shared-svg
				name="icons_24_2_search"
				class="layered-content__item icon icon--xl"
			></svg>
			<svg
				*ngIf="loader"
				[@fadeAnimation]
				b-shared-svg
				name="icons_cl_24_2_loader"
				class="layered-content__item icon icon--xl icon--cl rotating"
			></svg>
			<button
				*ngIf="!loader && !fieldIsEmpty"
				[@fadeAnimation]
				b-shared-button
				(click)="clearInput($event)"
				class="layered-content__item btn"
				icon="icons_24_2_cross-round"
				iconClass="icon--xl"
				title="Очистить поле"
			></button>
		</div>
	</div>
</form>

<div *ngIf="listIsEmpty" class="chat pl-8 mb-12 mt-auto lg:mt-0">
	<div class="chat__message chat__message--incoming chat__message--error mt-0">
		<div class="chat__message-body">
			В&nbsp;России такого города нет
		</div>
	</div>
</div>

<ng-container *ngIf="!listIsEmpty">
	<ng-container *ngIf="citiesListService.filteredCitiesList$ | async as cities">
		<div class="popup__content-inner autocomplete-list ov-auto">
			<ul *ngIf="cities.length" class="pt-0 px-0 pb-4 m-0">
				<li
					*ngFor="let city of cities; trackBy: trackByCities"
					(click)="selectCity(city)"
					class="autocomplete-list__item d-flex flex-jc-between"
				>
					<span>{{ city.displayName }}</span>
					<span *ngIf="cityIsNotServed && city.distance">{{ city.distance }} км</span>
				</li>
			</ul>
		</div>
	</ng-container>
</ng-container>

<button
	b-shared-button
	*ngIf="cityIsNotServed"
	class="btn btn--primary btn--lg mt-auto mx-8 mb-24"
	(click)="submitApplicationForNotServicedCity()"
>
	Готово
</button>
