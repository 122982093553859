import { RegistrationSteps } from './registration.types';

export enum MessageType {
	waiting,
	defaultMessageWithCustomContent,
	waitSecond,
	errorMessage,
	hi,
	whatDoYouWant,
	openAccountBuisnessWant,
	enterPhoneNumber,
	enterPhoneAndOpf,
	conditions,
	changePhoneNumber,
	enterNewPhoneNumber,
	noMyCompany,
	taxHasNotAddedCompany,
	enterRequisities,
	recentlyRegistered,
	codeNotCome,
	sendNewCode,
	clarifyingNumberCorrectness,
	clarifyingOfTypoPresence,
	cantSendConfirmationCode,
	bankEmployeeCall,
	enterNumberRegisteredInRussia,
	selectedCompany,
	meetingDate,
	registrationCompleted,
	documentsList,
	accountAlreadyReserved,
	pointCameraAtQrCode,
	pointQrCode,
	downloadApp,
	viewRequsities,
	decideOfDate,
	meetHere,
	meetingPlaceOnMap,
	remainsToAssignMeeting,
	specifyMeetingCity,
	selectAnotherCompany,
	accountForCompanyIsAlreadyOpen,
	enterOrganizationName,
	sentCode,
	confirmPhoneNumber,
	codeVerifyThrottled,
	blockingCodeEntry,
	timer,
	tryEnteringCodeAgain,
	waitingAccountOpening,
	timeToDownloadApp,
	sadNews,
	openingAccountRefusal,
	emailForDetails,
	openAccountToAnotherNumber,
	needAccountForIndividualEntrepreneur,
	needAccountForLLC,
	selectOpf,
	myCity,
	waitingForManagerArrive,
	excellent,
	infoAboutRequests,
	requestsHasBeenCollected,
	shareBank,
	accountReservedAndDownloadApp,
	accountReservedAndQr,
	nearestDateOfDepartureMeeting,
	nearestDateOfOfficeMeeting,
	hereIsHisAddress,
	alright,
	comeToMe,
	messagePublicPlace,
	haveHolding,
	registrationMultipleCompany,
	noCompanyRegistrationMultiple
}

export enum Senders {
	bank = 'bank',
	client = 'client',
}

export interface Message {
	from: Senders;
	type: MessageType;
	id?: string;
	editableStep?: RegistrationSteps;
	timeout?: number;
	data?: any;
	containerClass?: string;
}

export interface ChatState {
	chatLoader: boolean;
	accumulation: boolean;
	editableStep: RegistrationSteps;
	authLoader: boolean;
}
