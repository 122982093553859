<div
	class="reg-image"
	[ngClass]="{
		'reg-image--vendor': partner === registrationPartner.avito,
		'pos-absolute pos-bottom-0 h-100p': isReferral,
		'pos-sticky': !isReferral
	}"
>
	<img
		*ngIf="partner === registrationPartner.avito"
		alt="Промокод АВИТО"
		class="w-100p"
		src="assets/img/landing/avito.svg"
	>

	<ng-container *ngIf="!partner">
		<div #animationContainer class="reg-image__animation" [ngClass]="animationContainerClass$ | async"></div>
		<svg
			*ngIf="canShowQrCode$ | async"
			b-shared-svg
			name="marketing_qr-code"
			title="Скачать мобильное приложение"
			width="200"
			class="reg-image__qr-code"
			[@fadeAnimation]
			aria-hidden="false"
		></svg>
	</ng-container>
</div>
