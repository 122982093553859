import {
	APP_BASE_HREF,
	CommonModule,
	DatePipe,
	isPlatformServer,
	registerLocaleData,
	TitleCasePipe,
} from '@angular/common';
import { APP_ID, Inject, LOCALE_ID, NgModule, PLATFORM_ID, Provider } from '@angular/core';
import { BrowserModule, Meta, Title } from '@angular/platform-browser';
import localeRu from '@angular/common/locales/ru';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { environment } from '../environments/environment';
import {
	AnalyticsModule,
	AnalyticsService,
	DomEventsService,
	FeatureTogglingModule,
	FingerprintInterceptor,
	SharedModule,
	SpriteModule,
} from 'shared';
import { RegistrationTokenInterceptor } from 'registration';
import { ParseInterceptor } from './shared/interceptors/parse/parse.interceptor';
import { LottieModule } from '../../../landing/src/app/ui/modules/lottie/lottie.module';
import { IconModule } from '../../../landing/src/app/ui/ui/icon/module';

registerLocaleData(localeRu);
declare let Modernizr: any;

const appInterceptors: any[] = [ParseInterceptor, RegistrationTokenInterceptor, FingerprintInterceptor];

const interceptorsProviders = appInterceptors.map(
	(interceptor): Provider => ({
		provide: HTTP_INTERCEPTORS,
		useClass: interceptor,
		multi: true,
	}),
);

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		CommonModule,
		BrowserAnimationsModule,
		HttpClientModule,
		AnalyticsModule.forRoot(environment),
		SpriteModule,
		FeatureTogglingModule.forRoot(),
		SharedModule.forRoot({ environment }),
		LottieModule.forRoot({
			baseDir: '/assets/lottie/',
		}),
		IconModule.forRoot({
			symbolsPath: '/assets/icons.svg',
		}),
	],
	providers: [
		{ provide: APP_ID, useValue: 'serverApp' },
		...interceptorsProviders,
		{ provide: LOCALE_ID, useValue: 'ru-RU' },
		{ provide: APP_BASE_HREF, useValue: '/' },
		{ provide: 'googleTagManagerId', useValue: 'GTM-NKXBTTG' },
		DatePipe,
		TitleCasePipe,
		Title,
		Meta,
	],
	bootstrap: [AppComponent],
})
export class AppModule {
	isServer: boolean;

	constructor(
		@Inject(PLATFORM_ID) platformId: any,
		private gtmService: GoogleTagManagerService,
		private analyticsService: AnalyticsService,
	) {
		this.isServer = isPlatformServer(platformId);

		if (!this.isServer) {
			Modernizr?.on('webp', (result) => {
				if (!result) {
					document.querySelector('html').classList.add('no-webp');
				}
			});
			Modernizr?.on('hiddenscroll', (result) => {
				if (!result) {
					document.querySelector('html').classList.add('no-hiddenscroll');
				}
			});

			if (this.analyticsService.gtmEnabled) {
				this.gtmService.getDataLayer().push({ stageType: this.analyticsService.gtmStageType });
				this.gtmService.addGtmToDom();
			}

			if (this.analyticsService.exponeaEnabled) {
				// this.analyticsService.startExponea();
			}

			this.analyticsService.initSourcebuster();
		}
	}
}
