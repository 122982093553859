<div
	class="drop drop--overlay"
	[ngClass]="{
		'chat mt-8': !isOverlay,
		'w-100p': isOverlay
	}"
>
	<div
		#mapOverlay
		class="drop__content pos-bottom-100p max-h-none"
		[ngClass]="{
			'd-none': !mapOverlayVisibility,
			'drop__content--popup pt-0 ov-hidden': mapView === mapViewTypes.withCafesList,
			'drop__wrapper d-block shadow-none ov-visible mb-8': mapView === mapViewTypes.outOfZoneAddress,
			'bg-body': !isOverlay,
			'bg-white brs-24': isOverlay
		}"
	>
		<div
			class="z-20 w-100p"
			[ngClass]="{
				'chat__message chat__message--incoming mt-0 mb-8': mapView === mapViewTypes.outOfZoneAddress,
				'drop__error-message': isOverlay
			}"
		>
			<div
				class="pb-0 px-0 w-100p max-w-100p"
				[ngClass]="{
					'chat__message-body': mapView === mapViewTypes.outOfZoneAddress,
					'brs-br-0': !isOverlay,
					'brs-bl-4': isOverlay
				}"
			>
				<div *ngIf="mapView === mapViewTypes.outOfZoneAddress" class="text-md px-24 pb-16">
					Вот зона работы наших менеджеров
				</div>

				<div #mapContainer [ngClass]="mapContainerClass"></div>

				<div *ngIf="mapView === mapViewTypes.withCafesList" class="drop__list autocomplete-list p-4">
					<b-registration-address-list-item
						*ngIf="addressesService.selectedHint$ | async as selectedHint"
						title="Встретимся здесь"
						[address]="selectedHint?.address"
						[organization]="selectedHint?.ogranization"
						[point]="selectedHint"
						[isHint]="true"
						iconUrl="meeting-points_address-default"
						class="autocomplete-list__item d-flex p-0"
					></b-registration-address-list-item>

					<ng-container *ngIf="nearbyAddresses.length">
						<p class="text-xs text-disabled mt-12 mb-4 ml-12">Или выберите удобные кафе рядом</p>
						<div>
							<b-registration-address-list-item
								*ngFor="let point of nearbyAddresses; trackBy: trackByPoints"
								[title]="point.properties.iconCaption"
								[address]="point.properties.description"
								[iconUrl]="point.properties.icon"
								[point]="point"
								[time]="point.properties?.routeTimeView"
								class="autocomplete-list__item d-flex p-0"
							></b-registration-address-list-item>
						</div>
					</ng-container>
				</div>
			</div>
		</div>

		<div
			*ngIf="mapOverlayVisibility && mapView === mapViewTypes.outOfZoneAddress"
			class="chat__message chat__message--error mb-0"
			[ngClass]="{
				'chat__message--incoming': isOverlay
			}"
		>
			<div class="chat__message-body">
				Мы&nbsp;не&nbsp;сможем сюда приехать <b-shared-emoji emoji="😢"></b-shared-emoji>
			</div>
		</div>
	</div>

	<form
		[formGroup]="addressSearchForm"
		class="w-100p"
	>
		<div
			b-shared-validation
			class="field drop drop--lg drop--overlay"
			[ngClass]="{
				'field--disabled': searchControl.disabled,
				'field--reg': isOverlay
			}"
			[control]="searchControl"
			[errors]="{
				noAddress: noAddress,
				serviceUnavailable: serviceUnavailable
			}"
			pos="top left"
		>
			<b-shared-autocomplete
				#autocompleteRef
				*ngIf="searchControl"
				placeholder="Введите адрес или место"
				formControlName="searchField"
				[suggestions]="hints"
				[displayWith]="displayFn"
				[debounceTime]="400"
				overlayClass="brs-12"
				suggestPosition="above"
				[completeStream]="getAddressSearchStream"
				(selectHandler)="selectHint($event)"
				loaderType="input"
				[clearable]="true"
				[searchIcon]="true"
				[hideOverlayWhenOutsideClicked]="false"
				(overlayAnimationEndHandler)="overlayAnimationEndHandler($event)"
				(clearHandler)="clearHandler()"
				[trackBySuggests]="trackByHints"
			>
				<ng-template let-option #optionTemplate>
					<ng-container *ngIf="!option.ogranization">
						<p class="text-md text-truncate dir-rtl ta-l my-4" [title]="option.address">
							{{ option.address }}
						</p>
					</ng-container>
					<ng-container *ngIf="option.ogranization">
						<p class="text-md text-wrap mb-4">
							{{ option.ogranization }}
						</p>
						<p class="text-sm text-wrap text-disabled">
							{{ option.ogrCategory }} ∙ {{ option.address }}
						</p>
					</ng-container>
				</ng-template>
			</b-shared-autocomplete>

			<ng-template #noAddress>
				Такого адреса не&nbsp;существует <b-shared-emoji emoji="😕"></b-shared-emoji><br>Попробуй
				ещё раз
			</ng-template>

			<ng-template #serviceUnavailable>
				Сервис временно недоступен. Мы&nbsp;уже в&nbsp;курсе
			</ng-template>
		</div>
	</form>
</div>
