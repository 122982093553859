/** Конфиг таймингов анимаций в чате регистрации */
export const animationTimings = {
	destroy: 200,
	init: 400,
	pause: 400,
	scroll: 500,
	scrollDebounce: 300,
};

/** Метод для получения таймаута появления сообщения с учётом пауз и анимации предыдущих сообщений */
export const getMessageTimeout = (count: number) => {
	return animationTimings.pause * count + animationTimings.init * count;
};
