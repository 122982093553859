import {
	ChangeDetectionStrategy,
	Component,
	ElementRef, Input,
	OnInit,
	ViewEncapsulation,
} from '@angular/core';
import { ChatService } from '../../../../services/chat.service';
import { RegistrationSteps } from '../../../../types/registration.types';
import { RegistrationStore } from '../../../../services/registration.store';
import { Senders } from '../../../../types/chat.types';
import { MessageType } from '../../../../types/chat.types';
import { getMessageTimeout } from '../../../../enums/chat.enums';
import { MeetingService } from '../../../../services/meeting.service';
import { Zone } from '../../../../types/Zone';
import { Feature } from '../../../../types/city-selection.types';

@Component({
	selector: 'b-registration-meeting-type-selection',
	templateUrl: './meeting-type-selection.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeetingTypeSelectionComponent implements OnInit {
	/** Для реги в шторке */
	@Input() isOverlay: boolean = false;

	constructor(
		private store: RegistrationStore,
		private chat: ChatService,
		public meetingService: MeetingService,
		public elementRef: ElementRef,
	) {}

	ngOnInit(): void {
		this.meetingService.changeSelectedOffice(null);
		this.meetingService.changeDepartureFlow(false);
		this.meetingService.setSelectedFeature(null);
		this.meetingService.setSelectedPolygon(null);
	}

	trackByOfficeZones(index: number, zone: Zone) {
		return zone?.id || zone?.officeAddress;
	}

	comeToMe() {
		this.meetingService.changeDepartureFlow(true);

		this.store.changeStep(RegistrationSteps.empty);
		this.chat.changeChatLoader(true);

		this.chat.pushMessage({
			from: Senders.client,
			type: MessageType.comeToMe,
			editableStep: RegistrationSteps.meetingTypeSelection,
		});

		this.chat.pushMessage({
			from: Senders.bank,
			type: MessageType.alright,
		});

		this.chat.pushMessage({
			from: Senders.bank,
			type: MessageType.messagePublicPlace,
			timeout: getMessageTimeout(1),
		});

		this.chat.postponeAction(() => {
			this.store.changeStep(RegistrationSteps.addressSearch);
			this.chat.changeChatLoader(false);
		}, getMessageTimeout(2)).subscribe();
	}

	selectOffice(officeZone: Zone) {
		const feature = {
			type: 'Feature',
			id: '10000',
			geometry: {
				coordinates: [
					officeZone.officeCoordinates.latitude,
					officeZone.officeCoordinates.longitude,
				],
				type: 'Point'
			},
			properties: {
				description: officeZone.officeAddress,
				iconCaption: officeZone.displayName,
				organizationId: 'office',
			},
		};

		const pointPolygon: Feature = {
			id: officeZone.geoZone.toString(),
			type: 'Feature',
			geometry: { coordinates: [0, 0], type: 'Polygon' },
		};

		this.meetingService.selectPoint({ feature, pointPolygon }).subscribe({
			next: () => {
				this.meetingService.changeSelectedOffice(officeZone);
			}
		});
	}

	get selectOfficeIcon(): string {
		return !this.isOverlay ? 'icons_16_2_bank' : '';
	}
}
