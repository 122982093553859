import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import {
	AnalyticsService,
	DeviceService,
	ExponeaTypes,
	FEATURES,
	FeatureTogglingService,
	OverlayService2,
	ScrollService,
	WINDOW,
	urlRegexp
} from 'shared';
import { BehaviorSubject, combineLatest, distinctUntilChanged, Subscription } from 'rxjs';
import { AddressesService } from '../../../services/addresses.service';
import { ChatService } from '../../../services/chat.service';
import { CitiesListService } from '../../../services/cities-list.service';
import { DateSelectionService } from '../../../services/date-selection.service';
import { LottieAnimationsService } from '../../../services/lottie-animations.service';
import { MeetingAssignService } from '../../../services/meeting-assign.service';
import { MeetingService } from '../../../services/meeting.service';
import { RegistrationService } from '../../../services/registration.service';
import { RegistrationStore } from '../../../services/registration.store';
import { SessionService } from '../../../services/session.service';
import { SmsBlockingService } from '../../../services/sms-blocking.service';
import { Message, MessageType, Senders } from '../../../types/chat.types';
import { RegistrationSteps } from '../../../types/registration.types';
import { MeetingType, SelectedCompany } from '../../../types/searchCompany.types';
import { AuthService } from '../../../services/auth.service';
import { OnboardingOverlays } from '../../../types/overlays.types';
import animateScrollTo from 'animated-scroll-to';
import { animationTimings } from '../../../enums/chat.enums';
import { CookieService } from 'ngx-cookie-service';
import { HoldingService } from '../../../services/holding.service';


@Component({
	selector: 'b-registration-chat-message',
	templateUrl: './message.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageComponent implements OnInit, OnDestroy {
	@Input() message: Message;

	/** Для реги в шторке */
	@Input() isOverlay: boolean = false;

	messageType = MessageType;
	step: RegistrationSteps;
	stepsAvailableForEditing: RegistrationSteps[];
	editableStep: RegistrationSteps;
	subscribtions = new Subscription();
	canEdit = true;
	messages: Message[];
	selectedCompany: SelectedCompany;
	chatLoader: boolean;
	onboardingOverlays = OnboardingOverlays;

	/** флаг, указывающий на редактируемое сообщение от клиента перед другим сообщением клиента */
	isEditableClientMessage = new BehaviorSubject<boolean>(false);
	isEditableClientMessage$ = this.isEditableClientMessage
		.asObservable()
		.pipe(distinctUntilChanged());

	constructor(
		private chat: ChatService,
		public registrationService: RegistrationService,
		public store: RegistrationStore,
		private changeDetector: ChangeDetectorRef,
		private sessionService: SessionService,
		@Inject('env') private env,
		private addressesService: AddressesService,
		private citiesListService: CitiesListService,
		private dateSelectionService: DateSelectionService,
		private lottieAnimationsService: LottieAnimationsService,
		private meetingService: MeetingService,
		private smsBlockingService: SmsBlockingService,
		private meetingAssignService: MeetingAssignService,
		private featureToggling: FeatureTogglingService,
		private authService: AuthService,
		private overlayService: OverlayService2,
		private scrollService: ScrollService,
		private analyticsService: AnalyticsService,
		private cookieService: CookieService,
		private deviceService: DeviceService,
		private holdingService: HoldingService,
		@Inject(WINDOW) private _window: Window,
	) {}

	ngOnInit() {
		this.subscribtions.add(
			this.store.selectedCompany$.subscribe(selectedCompany => {
				this.selectedCompany = selectedCompany;
				this.changeDetector.detectChanges();
			})
		);

		if (this.message.editableStep) {
			this.subscribtions.add(
				combineLatest([
					this.store.messages$,
					this.chat.chatLoader$,
					this.store.step$,
					this.store.canEdit$,
					this.chat.editableStep$,
				])
					.subscribe({
						next: ([ messages ]) => {
							const currentIndexOfMessage = messages.findIndex(messageItem => messageItem?.id === this.message?.id);
							this.isEditableClientMessage.next(
								this.message.editableStep &&
								currentIndexOfMessage !== -1 &&
								this.messages?.[currentIndexOfMessage + 1]?.from === Senders.client
							);
						}
					})
			);

			this.subscribtions.add(
				this.chat.chatLoader$.subscribe(chatLoader => {
					this.chatLoader = chatLoader;
					this.changeDetector.detectChanges();
				})
			);

			this.subscribtions.add(
				this.store.canEdit$.subscribe(canEdit => {
					this.canEdit = canEdit;
					this.changeDetector.detectChanges();
				}),
			);

			this.subscribtions.add(
				this.chat.editableStep$.subscribe(editableStep => {
					this.editableStep = editableStep;
					this.changeDetector.detectChanges();
				})
			);

			this.subscribtions.add(
				this.store.messages$.subscribe(messages => {
					this.messages = messages;
					this.changeDetector.detectChanges();
				}),
			);

			this.subscribtions.add(
				this.store.step$.subscribe(step => {
					this.step = step;

					switch (step) {
					case RegistrationSteps.opfSelection:
						this.stepsAvailableForEditing = [RegistrationSteps.enterPhone];
						break;
					case RegistrationSteps.enterSms:
					case RegistrationSteps.searchCompany:
					case RegistrationSteps.noCompanyInfoInTaxService:
						this.stepsAvailableForEditing = [RegistrationSteps.enterPhone, RegistrationSteps.opfSelection];
						break;
					case RegistrationSteps.citySelection:
					case RegistrationSteps.accountIsAlreadyOpen:
						this.stepsAvailableForEditing = [
							RegistrationSteps.searchCompany,
							RegistrationSteps.noCompanyInfoInTaxService,
						];
						break;
					case RegistrationSteps.meetingTypeSelection:
						this.stepsAvailableForEditing = [
							RegistrationSteps.searchCompany,
							RegistrationSteps.noCompanyInfoInTaxService,
							RegistrationSteps.citySelection,
						];
						break;
					case RegistrationSteps.addressSearch:
						this.stepsAvailableForEditing = [
							RegistrationSteps.meetingTypeSelection,
							RegistrationSteps.citySelection,
							RegistrationSteps.searchCompany,
							RegistrationSteps.noCompanyInfoInTaxService,
						];
						break;
					case RegistrationSteps.datePicker:
						this.stepsAvailableForEditing = [
							RegistrationSteps.citySelection,
							RegistrationSteps.meetingTypeSelection,
							RegistrationSteps.addressSearch,
						];
						break;
					case RegistrationSteps.waitingForRequests:
						this.stepsAvailableForEditing = [RegistrationSteps.citySelection];
						break;
					case RegistrationSteps.last:
						this.stepsAvailableForEditing = [
							RegistrationSteps.datePicker,
							RegistrationSteps.citySelection,
							RegistrationSteps.meetingTypeSelection,
							RegistrationSteps.addressSearch,
						];
						break;
					case RegistrationSteps.enterPhone:
					case RegistrationSteps.empty:
					default:
						this.stepsAvailableForEditing = null;
						break;
					}

					this.changeDetector.detectChanges();
				}),
			);
		}
	}

	ngOnDestroy() {
		this.subscribtions.unsubscribe();
	}

	get isEditable() {
		return this.message.editableStep && (
			this.editableStep ?
				this.editableStep === this.message.editableStep :
				Boolean(
					this.stepsAvailableForEditing?.some(step => step === this.message.editableStep) &&
					this.canEdit &&
					this.step !== this.message.editableStep &&
					!this.chatLoader &&
					[...this.messages]?.reverse()?.find(i => i?.editableStep === this.message.editableStep)?.id === this.message.id, // только у последнего сообщения с данным типом изменяемого шага
				)
		);
	}

	deletePrevMessages() {
		const messageIndex = this.messages.findIndex(i => i.id === this.message.id);
		const exceptions = [
			{ deviation: -1, step: RegistrationSteps.addressSearch },
			{ deviation: -1, step: RegistrationSteps.waitingForRequests },
			{
				deviation: (this.authService.state.selectedOpf && this.authService.state.opfSelectionStep === RegistrationSteps.enterPhone) ? -1 : 0,
				step: RegistrationSteps.enterPhone,
			},
		];

		if (this.step !== RegistrationSteps.addressSearch) {
			exceptions.push({ deviation: -1, step: RegistrationSteps.meetingTypeSelection });
		}

		if (this.message.type === MessageType.waitingForManagerArrive) {
			exceptions.push({ deviation: -1, step: RegistrationSteps.citySelection });
		}

		const deviation = exceptions.find(
			exception => exception.step === this.message.editableStep
		)?.deviation || 0;

		const deletedMessages = [...this.messages].slice(Math.round(messageIndex + deviation));
		deletedMessages.forEach((i) => this.chat.deleteMessage(i.id));
	}

	changeData(event: Event) {
		let canEdit = false;
		let targetStep = this.message.editableStep;
		const isPlannedVirtualMeeting = this.step === RegistrationSteps.last &&
			this.meetingService?.state?.meeting?.meetType === MeetingType.Video;
		const targetElement = event.target as HTMLElement;

		if (targetElement) {
			if (this.deviceService.openedInIframe) {
				this._window?.parent?.postMessage({
					type: 'blancChangeRegistrationData',
					targetElementRect: targetElement.getBoundingClientRect()
				}, '*');
			} else {
				(animateScrollTo as unknown as { default: typeof animateScrollTo }).default(
					this.scrollService.getElementScrollPositions(targetElement).bottom -
					(this._window.visualViewport ? this._window.visualViewport.height : this.scrollService.getViewportSize().viewportHeight), {
						cancelOnUserAction: true,
						minDuration: animationTimings.scroll,
						maxDuration: animationTimings.scroll,
					}
				);
			}
		}

		switch (this.message.editableStep) {
		case RegistrationSteps.searchCompany:
		case RegistrationSteps.noCompanyInfoInTaxService:
			const currentCompanyId = this.store.state?.selectedCompany?.companyId;
			if (currentCompanyId) {
				this.store.setSelectedCompany(null);
				this.registrationService.deleteCompany(currentCompanyId).subscribe();
			}
			break;
		case RegistrationSteps.datePicker:
			if (isPlannedVirtualMeeting) {
				targetStep = RegistrationSteps.citySelection;
			}
			canEdit = true;
			break;
		case RegistrationSteps.addressSearch:
		case RegistrationSteps.meetingTypeSelection:
			canEdit = true;
			break;
		case RegistrationSteps.enterPhone:
			this.resetRegistration(false);
			this.store.changeStep(RegistrationSteps.start);
			break;

		default:
			break;
		}

		this.chat.setEditableStep(null);
		this.deletePrevMessages();

		if (isPlannedVirtualMeeting) {
			this.chat.pushMessage({
				from: Senders.bank,
				type: MessageType.specifyMeetingCity,
			});
		}

		this.store.changeStep(targetStep, canEdit);
	}

	showRequisities() {
		if (this.selectedCompany?.companyId) {
			this.store.getCompanyDetails(this.selectedCompany.companyId).subscribe({
				next: () => {
					this.overlayService.open({
						overlayId: OnboardingOverlays.onboardingRequisities,
					});
				}
			});
		}
	}

	get linkWeblk(): string {
		const stageName = this._window.location.hostname.split('.')[1];
		const isStage = urlRegexp.stageName.test(stageName);
		return isStage
			? `https://web.${stageName}.vestabankdev.ru`
			: `https://web.${this.env.productionUrl.hostname}`;
	}

	resetRegistration(opfSelectionIsAvailable: boolean) {
		this.registrationService.resetState();
		this.addressesService.resetState();
		this.chat.resetState();
		this.citiesListService.resetState();
		this.dateSelectionService.resetState();
		this.lottieAnimationsService.resetState();
		this.meetingService.resetState();
		this.sessionService.resetState();
		this.smsBlockingService.resetState();
		this.store.resetState();
		this.meetingAssignService.resetState();
		this.holdingService.resetState();

		this.registrationService.initRegistration(this.env);
		this.sessionService.setSessionData(null);

		this.chat.pushMessage({
			from: Senders.bank,
			type: MessageType.hi,
		});

		this.chat.pushMessage({
			from: Senders.bank,
			type: opfSelectionIsAvailable ? MessageType.enterPhoneAndOpf : MessageType.enterPhoneNumber,
		});

		this.chat.pushMessage({
			from: Senders.bank,
			type: MessageType.conditions,
		});

		this.store.changeStep(RegistrationSteps.enterPhone);
	}

	openAnotherAccount() {
		this.featureToggling.checkFeatureActivity('accounts-service', FEATURES.OpenAccountsInBeginningOfRegistration).subscribe({
			next: featureIsActive => {
				this.resetRegistration(featureIsActive);
			},
			error: () => {
				this.resetRegistration(false);
			},
		});
	}

	shared: boolean = false;
	shareTimeout;
	async shareBank() {
		const url = 'https://blanc.ru/?utm_source=sharing&utm_medium=link';

		try {
			await navigator.share({ url })
				.catch(() => {}); // чтобы при отмене шаринга не перебрасывало в catch
		} catch {
			// если web share api не поддерживается, используем clipboard api:
			this.overlayService.open({
				overlayId: OnboardingOverlays.shareBank,
			});
		}
	}

	copy() {
		const url = 'https://blanc.ru/?utm_source=sharing&utm_medium=link';

		navigator.clipboard.writeText(url);
		this.shared = true;
		this.changeDetector.detectChanges();
		clearTimeout(this.shareTimeout);

		this.shareTimeout = setTimeout(() => {
			this.shared = false;
			this.changeDetector.detectChanges();
			clearTimeout(this.shareTimeout);
		}, 2000);
	}

	get clipboardIsSupported(): boolean {
		return Boolean(this._window.navigator.clipboard);
	}

	getUrlWithCookies(url: string): URL {
		const sbCurrentCookie = this.cookieService.get('sbjs_current');
		const sbCookies = this.analyticsService.splitSourcebusterCookies(sbCurrentCookie);
		return this.analyticsService.addSourcebusterCookiesToUrl(url, sbCookies, {
			mdm: 'af_sub1',
			src: 'af_sub2',
			cmp: 'af_sub3',
			cnt: 'af_sub4',
			trm: 'af_sub5',
		});
	}

	trackByItem(index: number, item: any) {
		return item;
	}

	get isAvito(): boolean {
		return this.registrationService.state.isAvitoRegistration;
	}

	get linkReg(): string {
		const stageName = this._window.location.hostname.split('.')[1];
		const isStage = urlRegexp.stageName.test(stageName);
		return isStage
			? `https://landing.${stageName}.vestabankdev.ru/reg`
			: `https://${this.env.productionUrl.hostname}/reg`;
	}

	openReg() {
		this.analyticsService.exponeaTrack(ExponeaTypes.web_action, {
			action_type: 'company registration with main',
		});
	}
}
