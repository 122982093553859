<ng-container *ngIf="requisities.length">
	<div *ngFor="let requisite of requisities; trackBy: trackByRequisities" class="px-8">
		<ng-container *ngIf="requisite.title && requisite.value">
			<h4 class="text-xs my-0 text-disabled">{{ requisite.title }}</h4>
			<p class="text-md mb-8">{{ requisite.value }}</p>
		</ng-container>
	</div>

	<button
		*ngIf="clipboardIsSupported"
		b-shared-button
		class="btn btn--primary btn--lg w-100p mt-16"
		(click)="copy()"
		icon="icons_24_1-5_check"
		[iconClass]="{
			'icon--xl': true,
			'd-none': !copied
		}"
		[ngClass]="{
			'btn-copy': copied
		}"
	>
		{{ copied ? 'Скопировано' : 'Скопировать' }}
	</button>
</ng-container>
