import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {AnalyticsService, EventAction, EventCategory, MaskService} from 'shared';
import {CitiesListService} from '../../../../services/cities-list.service';
import {CitiesListView, City, SendApplicationErrors} from '../../../../types/cities-list.types';
import {RegistrationStore} from '../../../../services/registration.store';
import {animate, style, transition, trigger} from '@angular/animations';
import {MeetingService} from '../../../../services/meeting.service';
import {ChatService} from '../../../../services/chat.service';
import {MeetingAssignService} from '../../../../services/meeting-assign.service';
import {RegistrationSteps} from '../../../../types/registration.types';

@Component({
	selector: '[b-registration-cities-list]',
	templateUrl: './cities-list.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('fadeAnimation', [
			transition(':enter', [
				style({ opacity: 0 }),
				animate('200ms ease-out', style({ opacity: 1 })),
			]),
			transition(':leave', [
				animate('200ms ease-out', style({ opacity: 0 })),
			]),
		]),
	]
})
export class CitiesListComponent implements OnInit, OnDestroy, AfterViewInit {
	citiesFilter: UntypedFormGroup;
	subscriptions: Subscription = new Subscription();
	selectedCompanyCity: City;
	cityIsNotServed: boolean;
	listIsEmpty: boolean;
	loader = false;
	filterCitiesSub: Subscription;
	selectedCity: City;

	@Output() hideCitiesList: EventEmitter<any> = new EventEmitter();
	@ViewChild('searchInput', { static: false }) searchInput: ElementRef;

	constructor(
		private fb: UntypedFormBuilder,
		private citiesListService: CitiesListService,
		private changeDetector: ChangeDetectorRef,
		private store: RegistrationStore,
		private mask: MaskService,
		private meetingService: MeetingService,
		private chat: ChatService,
		private meetingAssignService: MeetingAssignService,
		private analyticsService: AnalyticsService
	) {}

	ngOnInit(): void {
		this.citiesFilter = this.fb.group({
			searchField: [''],
		});

		this.store.selectedCity$.subscribe((selectedCity) => {
			if (selectedCity) {
				this.selectedCompanyCity = selectedCity;
				this.changeDetector.detectChanges();
			}
		});

		this.subscriptions.add(
			this.citiesListService.view$.subscribe((view) => {
				this.cityIsNotServed = view === CitiesListView.noService;
				this.listIsEmpty = view === CitiesListView.empty;
				this.changeDetector.detectChanges();
			}),
		);

		this.subscriptions.add(
			this.citiesFilter.controls.searchField.valueChanges
				.pipe(
					map((searchValue) => {
						const value = this.mask.cyrillic(searchValue);
						this.citiesFilter.patchValue({ searchField: value }, { emitEvent: false });
						this.citiesListService.changeView(CitiesListView.default);
						if (!value?.length) {
							this.citiesListService.changeCitiesList(this.citiesListService.state.availableCities);
						}
						return value;
					}),
					distinctUntilChanged(),
					debounceTime(800),
				)
				.subscribe({
					next: (searchValue) => {
						if (!searchValue?.length) {
							return;
						}
						this.loader = true;
						this.changeDetector.detectChanges();

						this.filterCitiesSub = this.citiesListService.filterCities(searchValue).subscribe(
							() => {
								this.loader = false;
								this.changeDetector.detectChanges();
							},
							() => {
								this.loader = false;
								this.changeDetector.detectChanges();
							},
						);
					},
				}),
		);

		const companyCity = this.selectedCompanyCity;
		this.selectedCity = companyCity;
		this.store.setChosenCity(this.selectedCity);
	}

	ngAfterViewInit() {
		this.searchInput.nativeElement?.focus();
	}

	ngOnDestroy() {
		this.subscriptions?.unsubscribe();
		this.citiesListService.changeView(CitiesListView.default);
		this.citiesListService.resetCitiesList();
	}

	showListBasedOnCity(city: City) {
		if (!city.coordinates) {
			this.citiesListService.changeView(CitiesListView.default);
			this.citiesListService.resetCitiesList();
		} else {
			this.submitApplicationForNotServicedCity();
		}
	}

	selectCity(city: City) {
		this.selectedCity = city;
		this.store.setChosenCity(city);
		this.loader = false;
		this.filterCitiesSub?.unsubscribe();
		this.citiesListService.changeView(CitiesListView.default);
		this.citiesListService.saveCity(city.displayName).subscribe();

		if (city.isServiced) {
			this.citiesListService.selectCity(city);
			this.meetingService.startMeetingTypeSelection(city).subscribe();
			this.analyticsService.sendAction(EventAction.city_is_on, EventCategory.user_lead);
			this.hideCitiesList.emit();
		} else {
			this.submitApplicationForNotServicedCity();
		}
	}

	trackByCities(index: number, city: City) {
		return `${city.id}${index}`;
	}

	get searchControl() {
		return this.citiesFilter.controls?.searchField;
	}

	get fieldIsEmpty() {
		return !this.searchControl.value;
	}

	clearInput() {
		this.searchControl.reset();
	}

	submitApplicationForNotServicedCity() {
		this.hideCitiesList?.emit();
		// TODO: это шаг - хак для прогресс бара
		// Будет поправлено в сторе с назначением видео встречи клиентом
		this.store.changeStep(RegistrationSteps.notServicedCity);
		this.store.changeStep(RegistrationSteps.empty);
		this.chat.changeChatLoader(true);

		this.citiesListService.submitApplicationForCity({
			city: this.selectedCity.displayName,
			companyId: this.store.state.selectedCompany.companyId,
		}).subscribe({
			next: async (response) => {
				const selectNotServicedCityFlow = await this.meetingService.selectNotServicedCity(
					response.city,
					response.maxApplicationCount - response.applicationCount
				);

				this.analyticsService.sendAction(EventAction.city_is_off, EventCategory.user_lead);

				selectNotServicedCityFlow.subscribe();
			},
			error: response => {
				const errorType = response?.error?.type as SendApplicationErrors;

				switch (errorType) {
				case SendApplicationErrors.Failure:
					this.meetingAssignService.refusalToOpenAccount(this.store.state.selectedCompany);
					break;
				default:
					break;
				}

				this.chat.changeChatLoader(false);
			}
		});
	}
}
