<div
	class="field field--btn py-0 pl-0"
	[ngClass]="{
		'field--reg field--date-picker': isOverlay
	}"
>
	<b-shared-dropdown
		#dateDropdown
		[(ngModel)]="state.selectedDate"
		suggestPosition="above"
		loaderType="input"
		[suggestions]="state.dates"
		[uniqueElementPropFn]="getUniqueDateProp"
		placeholder="Укажите дату"
		class="field__control-divided drop drop--divided d-flex w-50p h-100p"
		overlayClass="brs-12"
		dropListClass="pt-4 px-4"
		dropElementClass="drop__item--bordered mb-4"
		dropFieldWrapperClass="px-4 m-auto"
		dropLoaderClass="m-auto pt-0"
		dropIconName="icons_16_2_arrow-down-sm"
		(selectHandler)="changeDate($event)"
		[dropIconClass]="isOverlay ? 'mr-12': ''"
	>
		<ng-template let-option #dropdownItem>
			<div class="text-md" *ngIf="option?.title">{{ option.title }}</div>
			<div
				[ngClass]="{
					'text-mark': !isOverlay,
					'text-xs': isOverlay
				}"
				*ngIf="option?.subtitle"
			>{{ option.subtitle }}</div>
		</ng-template>

		<ng-template let-selectedOption #viewTemplate>
			<div class="ta-c px-4 m-auto">
				<div class="text-md" *ngIf="selectedOption?.title">{{ selectedOption.title }}</div>
				<div
					[ngClass]="{
						'text-mark': !isOverlay,
						'text-xs': isOverlay
					}"
					*ngIf="selectedOption?.subtitle"
				>{{ selectedOption.subtitle }}</div>
			</div>
		</ng-template>
	</b-shared-dropdown>

	<b-shared-dropdown
		*ngIf="state.slots.length"
		[(ngModel)]="state.selectedTime"
		placeholder="Укажите время"
		#timeDropdown
		suggestPosition="above"
		loaderType="input"
		class="field__control-divided drop drop--divided d-flex w-50p h-100p"
		overlayClass="brs-12"
		dropListClass="pt-4 px-4"
		dropElementClass="drop__item--bordered mb-4"
		dropFieldWrapperClass="px-4 m-auto"
		dropLoaderClass="m-auto pt-0"
		dropIconName="icons_16_2_arrow-down-sm"
		(selectHandler)="changeTime($event)"
		[suggestions]="state.slots"
		[uniqueElementPropFn]="getUniqueSlotProp"
		[loader]="slotsLoader"
		[dropIconClass]="isOverlay ? 'mr-12': ''"
	>
		<ng-template let-option #dropdownItem>
			<div>{{ option.view }}</div>
		</ng-template>

		<ng-template let-selectedOption #viewTemplate>
			<div class="text-md w-56 px-4 m-auto">
				<div>{{ selectedOption?.view }}</div>
			</div>
		</ng-template>
	</b-shared-dropdown>

	<button
		b-shared-button
		class="field__addon btn btn--primary"
		(click)="assign()"
		[disabled]="slotsLoader"
		[ngClass]="{
			'lg:w-auto brs-12 lg:px-22 p-0': isOverlay,
			'lg:ml-20': !isOverlay
		}"
	>
		<span class="lg:d-block d-none">В&nbsp;это время</span>
		<svg
			b-shared-svg
			[name]="submitButtonIcon"
			class="icon lg:d-none"
			[ngClass]="{
				'icon--xl': isOverlay
			}"
		></svg>
	</button>
</div>
