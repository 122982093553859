import {
	afterNextRender,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	computed,
	DestroyRef,
	effect,
	inject,
	isDevMode,
	signal,
} from '@angular/core';
import { RegistrationStore } from '../../services/registration.store';
import { RangeSliderModule } from 'shared';
import { FormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import {
	RegistrationSteps,
	ProgressBarInputValueStatus,
} from '../../types/registration.types';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { RegistrationStatus } from '../../types/searchCompany.types';
import { TypografPipe } from '../../pipes';

@Component({
	selector: 'b-registration-progress-bar',
	templateUrl: './progress-bar.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [RangeSliderModule, FormsModule, TypografPipe],
})
export class ProgressBarComponent {
	readonly #destroy = inject(DestroyRef);
	#inputValuesSub?: Subscription;
	readonly ratingTitle = signal<string>('');
	readonly ratingValue = signal<number>(0);
	readonly isBlocked = toSignal<boolean, boolean>(
		this.store.isRegistrationBlocked$,
		{
			initialValue: false,
		}
	);

	constructor(public store: RegistrationStore, private cdr: ChangeDetectorRef) {
		afterNextRender(() => {
			this.initStepValueChangingSub();
			this.initCompanySelectedSub();
		});

		this.#destroy.onDestroy(() => {
			this.#inputValuesSub?.unsubscribe();
		});
	}

	readonly ratingType = computed<string>(() =>
		this.isBlocked() ? 'error' : 'success'
	);

	private initStepValueChangingSub(): void {
		this.store.step$
			.pipe(takeUntilDestroyed(this.#destroy))
			.subscribe((step) => {
				this.calculateRating(step);
			});
	}

	private initCompanySelectedSub(): void {
		this.store.selectedCompany$
			.pipe(takeUntilDestroyed(this.#destroy))
			.subscribe((data) => {
				if (data?.registrationStatus === RegistrationStatus.Refusal) {
					this.store.setRegistrationBlock(true);
					this.ratingValue.set(100);
				}
			});
	}

	private startCompanyChanging(): void {
		this.#inputValuesSub?.unsubscribe();
		this.#inputValuesSub = this.store.companyChosen$
			.pipe(takeUntilDestroyed(this.#destroy))
			.subscribe((data) => {
				if (data) {
					this.ratingValue.set(50);
				} else {
					this.ratingValue.set(25);
				}
			});
	}

	private startCityChanging(): void {
		this.#inputValuesSub?.unsubscribe();
		this.#inputValuesSub = this.store.cityChosen$
			.pipe(takeUntilDestroyed(this.#destroy))
			.subscribe((data) => {
				if (data) {
					this.ratingValue.set(60);
				} else {
					this.ratingValue.set(50);
				}
			});
	}

	private startAddressChanging(): void {
		this.#inputValuesSub?.unsubscribe();
		this.#inputValuesSub = this.store.addressChosen$
			.pipe(takeUntilDestroyed(this.#destroy))
			.subscribe((data) => {
				if (data) {
					this.ratingValue.set(80);
				} else {
					this.ratingValue.set(60);
				}
			});
	}

	private startTimeChanging(): void {
		this.#inputValuesSub?.unsubscribe();
		this.#inputValuesSub = this.store.timeChosen$
			.pipe(takeUntilDestroyed(this.#destroy))
			.subscribe((data) => {
				if (data) {
					this.ratingValue.set(100);
				} else {
					this.ratingValue.set(80);
				}
			});
	}

	private startListeningPhoneEvent(): void {
		this.#inputValuesSub?.unsubscribe();
		this.#inputValuesSub = this.store.phoneEnteredStatus$.subscribe((data) => {
			switch (data) {
			case ProgressBarInputValueStatus.Start:
				this.ratingValue.set(5);
				break;
			case ProgressBarInputValueStatus.Full:
				this.ratingValue.set(15);
				break;
			default:
				this.ratingValue.set(0);
				break;
			}
		});
	}

	private startListeningSmsEvent(): void {
		this.#inputValuesSub?.unsubscribe();
		this.#inputValuesSub = this.store.smsEnteredStatus$.subscribe((data) => {
			switch (data) {
			case ProgressBarInputValueStatus.Start:
				this.ratingValue.set(20);
				break;
			case ProgressBarInputValueStatus.Full:
				this.ratingValue.set(25);
				break;
			default:
				this.ratingValue.set(15);
				break;
			}
		});
	}

	private startListeningInnEvent(): void {
		this.#inputValuesSub?.unsubscribe();
		this.#inputValuesSub = this.store.innEnteredStatus$.subscribe((data) => {
			switch (data) {
			case ProgressBarInputValueStatus.Start:
				this.ratingValue.set(35);
				break;
			case ProgressBarInputValueStatus.Full:
				this.ratingValue.set(50);
				break;
			default:
				this.ratingValue.set(25);
				break;
			}
		});
	}

	private selectCompanyBlock(): void {
		this.store.setRegistrationBlock(false);
		this.ratingTitle.set('+25% за компанию');
		this.ratingValue.set(25);
	}

	private calculateRating(step: RegistrationSteps): void {
		switch (step) {
		case RegistrationSteps.start:
		case RegistrationSteps.enterPhone:
			this.ratingTitle.set('+25% за номер телефона');
			this.ratingValue.set(0);
			this.store.setPhoneValue('', false);
			this.startListeningPhoneEvent();
			break;
		case RegistrationSteps.enterSms:
			this.ratingValue.set(15);
			this.store.setSmsValue(null, false);
			this.startListeningSmsEvent();
			break;
		case RegistrationSteps.searchCompany:
			this.startCompanyChanging();
			this.selectCompanyBlock();
			break;
		case RegistrationSteps.noCompanyInfoInTaxService:
			this.startListeningInnEvent();
			this.selectCompanyBlock();
			break;
		case RegistrationSteps.citySelection:
			this.store.setChosenAddress(null);
			this.store.setChosenCity(null);
			this.ratingTitle.set('Осталось назначить встречу');
			this.ratingValue.set(50);
			this.startCityChanging();
			break;
		case RegistrationSteps.addressSearch:
			this.store.setChosenTime(false);
			this.startAddressChanging();
			this.ratingTitle.set('Осталось назначить встречу');
			this.ratingValue.set(60);
			break;
		case RegistrationSteps.notServicedCity:
		case RegistrationSteps.datePicker:
			this.startTimeChanging();
			this.ratingValue.set(80);
			break;
		case RegistrationSteps.accountOpeningInProcess:
		case RegistrationSteps.last:
		case RegistrationSteps.waitingForRequests:
		case RegistrationSteps.haveHolding:
			this.#inputValuesSub?.unsubscribe();
			this.ratingTitle.set('Готово! Регистрация завершена');
			this.ratingValue.set(100);
			break;
		case RegistrationSteps.accountIsAlreadyOpen:
			this.#inputValuesSub?.unsubscribe();
			this.ratingTitle.set('Не сможем открыть счёт');
			break;
		}

		this.cdr.detectChanges();
	}
}
