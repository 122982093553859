import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
	DeviceService,
	minTime,
	AnalyticsService,
	EventAction,
	EventCategory,
	ExponeaTypes,
	ExponeaActionTypes,
	ExponeaActionStatus,
	LOCAL_STORAGE,
	EnvironmentConfig,
} from 'shared';
import { MessageType, Senders } from '../types/chat.types';
import {
	CheckSmsErrors,
	CheckSmsRequestBody,
	RegistrationSteps,
	SessionDataResponse,
	SmsStepStatus,
} from '../types/registration.types';
import { ChatService } from './chat.service';
import { SessionService } from './session.service';
import { SmsBlockingService } from './sms-blocking.service';
import {CookieService} from 'ngx-cookie-service';
import {RegistrationService} from './registration.service';
import { getMessageTimeout } from '../enums/chat.enums';
import { RegistrationStore } from './registration.store';

declare var ym: any;

@Injectable({
	providedIn: 'root',
})
export class VerifyService {
	constructor(
		private chat: ChatService,
		private http: HttpClient,
		private sessionService: SessionService,
		private smsBlockingService: SmsBlockingService,
		private deviceService: DeviceService,
		private analyticsService: AnalyticsService,
		private cookieService: CookieService,
		private registrationService: RegistrationService,
		private store: RegistrationStore,
		@Inject(LOCAL_STORAGE) readonly localStorage: Storage,
		@Inject('env') private env: EnvironmentConfig,
	) {}

	verify(body: CheckSmsRequestBody): Observable<SessionDataResponse> {
		this.chat.pushMessage({
			from: Senders.client,
			type: MessageType.defaultMessageWithCustomContent,
			data: { content: body.code },
		});

		this.chat.postponeAction(() => {
			this.chat.changeChatLoader(true);
		}, getMessageTimeout(1)).subscribe();

		return this.http.post<SessionDataResponse>('/api/sso/entrance/verify', body).pipe(
			minTime(2000),
			tap(
				(response) => {
					if (response) {
						this.analyticsService.sendAction(EventAction.phone_confirmed, EventCategory.user_lead);
						this.analyticsService.exponeaTrack(ExponeaTypes.registration, {
							action_type: ExponeaActionTypes.phone,
							status: ExponeaActionStatus.confirmed,
							host: this.deviceService.isServer ? null : window.location.host,
							device_type: 'desktop',
							path: this.deviceService.isServer ? null : window.location.pathname,
							'product': 'rko'
						});

						this.sessionService.setSessionData(response);
						if (!this.deviceService.isServer) {
							this.localStorage?.setItem('LastValidSmsRegistrationCode', body.code);
						}

						this.registrationService.sendExponeaId(this.cookieService.get('__exponea_etc__'));

						this.sessionService.getClientData('auth');
					}
				},
				(response) => {
					this.chat.changeChatLoader(false);

					switch (response?.error?.type) {
					case CheckSmsErrors.InvalidConfirmCode:
						const invalidSmsCount = this.deviceService.isServer
							? null
							: this.localStorage?.getItem('RegistrationInvalidSmsCount');
						const count = (Number(invalidSmsCount) || 0) + 1;

						if (!this.deviceService.isServer) {
							this.localStorage?.setItem('RegistrationInvalidSmsCount', count.toString());
						}
						const millisecondsToUnlock = this.smsBlockingService.getMsToUnlock(count);
						if (millisecondsToUnlock) {
							const now = new Date().getTime();
							if (!this.deviceService.isServer) {
								this.localStorage?.setItem(
									'RegistrationSmsUnlockDate',
									new Date(now + millisecondsToUnlock).toString(),
								);
							}
							this.smsBlockingService.startSmsBlockingFlow();
						} else {
							this.chat.pushMessage({
								from: Senders.bank,
								type: MessageType.errorMessage,
								data: {
									errorMessage: response?.error?.message,
								},
							});

							this.smsBlockingService.changeSmsStep(SmsStepStatus.sendNewCode);
							this.store.changeStep(RegistrationSteps.enterSms);
						}
						break;
					default:
						this.chat.pushMessage({
							from: Senders.bank,
							type: MessageType.errorMessage,
							data: {
								errorMessage: response?.error?.message,
							},
						});

						this.smsBlockingService.changeSmsStep(SmsStepStatus.sendNewCode);
						this.store.changeStep(RegistrationSteps.enterSms);
					}

					if (!this.deviceService.isServer) {
						this.localStorage?.removeItem('LastValidSmsRegistrationCode');
					}
				},
			),
		);
	}

	agentSecret({ confirmToken, phoneNumber }) {
		return this.http.get<any>('/api/sso/common/agentSecret')
			.pipe(
				tap({
					next: code => {
						this.verify({
							code: code,
							confirmToken,
							phoneNumber,
						}).subscribe();
					},
				})
			);
	}

}
