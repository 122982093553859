/** Оверлеи регистрации */
export enum OnboardingOverlays {
	/** Реквизиты */
	onboardingRequisities = 'onboardingRequisities',
	/** Выбор города встречи */
	onboardingSelectCity = 'onboardingSelectCity',
	/** Попап для копирования ссылки на сайт банка */
	shareBank = 'shareBank',
	/** Выбор города встречи */
	holdingCompanySelect = 'holdingCompanySelect',
}
