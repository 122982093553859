<div
	class="chat pos-relative flex-jc-end md:min-w-0 min-w-100p mb-0"
	[ngClass]="isOverlay ? chatClass : chatClass + ' container-secondary__content'"
	#chatContainer
>
	<ng-container
		*ngIf="isOverlay; else chatMessages"
	>
		@if (!blockingLoader && messages?.length) {
			<div class="chat__header mb-auto">
				<b-registration-progress-bar></b-registration-progress-bar>
			</div>
		}

		<div class="ov-hidden h-100p">
			<ngx-simplebar class="chat__content">
				<ng-container *ngTemplateOutlet="chatMessages"></ng-container>
			</ngx-simplebar>
		</div>
	</ng-container>

	<ng-template #chatMessages>
		<ng-container *ngIf="!blockingLoader && messages">
			<b-registration-chat-message
				[isOverlay]="isOverlay"
				*ngFor="let message of messages; trackBy: trackByMessages"
				[message]="message"
				class="d-block"
				[attr.class]="message.containerClass"
				[@messageTrigger]
				(@messageTrigger.start)="start($event)"
				(@messageTrigger.done)="done($event)"
			></b-registration-chat-message>
		</ng-container>

		<ng-container *ngIf="blockingLoader">
			<b-registration-chat-message
				[message]="blockingLoaderMessage"
				[isOverlay]="isOverlay"
				class="chat__message chat__message--incoming"
				[@messageTrigger]
				(@messageTrigger.start)="start($event)"
				(@messageTrigger.done)="done($event)"
			></b-registration-chat-message>
		</ng-container>

		<b-registration-chat-message
			*ngIf="chatLoader || blockingLoader || authLoader"
			[message]="chatLoaderMessage"
			[isOverlay]="isOverlay"
			class="chat__message chat__message--incoming"
			[@messageTrigger]
			(@messageTrigger.start)="start($event)"
			(@messageTrigger.done)="done($event)"
		></b-registration-chat-message>
	</ng-template>

	<div
		*ngIf="isOverlay && stepContent; else stepContent"
		class="pos-sticky pos-bottom-0 bg-white z-10 p-12"
	>
		<ng-container *ngTemplateOutlet="stepContent"></ng-container>
	</div>

	<ng-template #stepContent>
		<ng-container [ngSwitch]="step">
			<ng-container *ngSwitchCase="registrationSteps.whereRegistration">
				<ng-container *ngIf="!isOverlay">
					<div
					class="chat__message chat__message--outgoing"
					[@lifecycleTrigger]
					(@lifecycleTrigger.start)="start($event)"
					(@lifecycleTrigger.done)="done($event)"
					>
						<button
							b-shared-button
							class="btn btn--primary btn--sm"
							(click)="withOverlay ? openRegistrationOverlay() : registrationAccount()"
						>
							Открыть счёт для бизнеса
						</button>
						<button
							b-shared-button
							class="btn btn--secondary btn--sm mt-8"
							(click)="registrationBusiness()"
						>
							Зарегистрировать новый бизнес
						</button>
					</div>
				</ng-container>

			</ng-container>

			<ng-container *ngSwitchCase="registrationSteps.enterPhone">
				<b-registration-phone
					*ngIf="phoneForm"
					[isOverlay]="isOverlay"
					[phoneForm]="phoneForm"
					[phoneControl]="phoneControl"
					[canSendPhone]="canSendPhone"
					(submitPhone)="submitPhone($event)"
					[ngClass]="stepNgClass"
					[attr.class]="!isOverlay ? 'd-block mt-20' : ''"
					[@lifecycleTrigger]
					(@lifecycleTrigger.start)="start($event)"
					(@lifecycleTrigger.done)="done($event)"
					#stepContainer
				></b-registration-phone>
			</ng-container>

			<ng-container *ngSwitchCase="registrationSteps.opfSelection">
				<b-registration-opf-selection
					[ngClass]="stepNgClass"
					class="chat__actions"
					(selectOpf)="selectOpf($event)"
					[@lifecycleTrigger]
					(@lifecycleTrigger.start)="start($event)"
					(@lifecycleTrigger.done)="done($event)"
					#stepContainer
				></b-registration-opf-selection>
			</ng-container>

			<ng-container *ngSwitchCase="registrationSteps.enterSms">
				<b-registration-sms-confirm
					[isOverlay]="isOverlay"
					[ngClass]="stepNgClass"
					(login)="login($event)"
					[@lifecycleTrigger]
					(@lifecycleTrigger.start)="start($event)"
					(@lifecycleTrigger.done)="done($event)"
					#stepContainer
				></b-registration-sms-confirm>
			</ng-container>

			<ng-container *ngSwitchCase="registrationSteps.searchCompany">
				<b-registration-company-search
					class="chat__actions"
					[placeholder]="isOverlay ? 'ИНН, ОГРН, название компании или ФИО гендира' : ''"
					[isOverlay]="isOverlay"
					[ngClass]="autocompleteStepsNgClass"
					[scrollableChatContainer]="scrollableChatContainer"
					[@lifecycleTrigger]
					(@lifecycleTrigger.start)="start($event)"
					(@lifecycleTrigger.done)="done($event)"
					[registrationSection]="registrationSection"
					#stepContainer
				></b-registration-company-search>
			</ng-container>

			<ng-container *ngSwitchCase="registrationSteps.citySelection">
				<b-registration-city-selection
					class="chat__actions"
					[isOverlay]="isOverlay"
					[ngClass]="stepNgClass"
					[@lifecycleTrigger]
					(@lifecycleTrigger.start)="start($event)"
					(@lifecycleTrigger.done)="done($event)"
					#stepContainer
				></b-registration-city-selection>
			</ng-container>

			<ng-container *ngSwitchCase="registrationSteps.meetingTypeSelection">
				<b-registration-meeting-type-selection
					class="chat__actions"
					[ngClass]="stepNgClass"
					[isOverlay]="isOverlay"
					[@lifecycleTrigger]
					(@lifecycleTrigger.start)="start($event)"
					(@lifecycleTrigger.done)="done($event)"
					#stepContainer
				></b-registration-meeting-type-selection>
			</ng-container>

			<ng-container *ngSwitchCase="registrationSteps.addressSearch">
				<b-registration-address-search
					class="chat__actions"
					[isOverlay]="isOverlay"
					[ngClass]="autocompleteStepsNgClass"
					[registrationSection]="registrationSection"
					[scrollableChatContainer]="scrollableChatContainer"
					[@lifecycleTrigger]
					(@lifecycleTrigger.start)="start($event)"
					(@lifecycleTrigger.done)="done($event)"
					#stepContainer
				></b-registration-address-search>
			</ng-container>

			<ng-container *ngSwitchCase="registrationSteps.datePicker">
				<b-registration-date-selection
					[ngClass]="stepNgClass"
					[attr.class]="!isOverlay ? 'chat__sticky mt-20': ''"
					[isOverlay]="isOverlay"
					[scrollableChatContainer]="scrollableChatContainer"
					[@lifecycleTrigger]
					(@lifecycleTrigger.start)="start($event)"
					(@lifecycleTrigger.done)="done($event)"
					[registrationSection]="registrationSection"
					#stepContainer
				></b-registration-date-selection>
			</ng-container>

			<ng-container *ngSwitchCase="registrationSteps.noCompanyInfoInTaxService">
				<b-registration-no-company-info-step
					[isOverlay]="isOverlay"
					[ngClass]="stepNgClass"
					[@lifecycleTrigger]
					(@lifecycleTrigger.start)="start($event)"
					(@lifecycleTrigger.done)="done($event)"
					#stepContainer
				></b-registration-no-company-info-step>
			</ng-container>

			<ng-container *ngSwitchCase="registrationSteps.accountIsAlreadyOpen">
				<b-registration-change-company-button-component
					class="chat__actions"
					[ngClass]="stepNgClass"
					[@lifecycleTrigger]
					(@lifecycleTrigger.start)="start($event)"
					(@lifecycleTrigger.done)="done($event)"
					#stepContainer
				></b-registration-change-company-button-component>
			</ng-container>

			<ng-container *ngSwitchCase="registrationSteps.haveHolding">
				<b-registration-holding-company-component
					class="chat__actions"
					[ngClass]="stepNgClass"
					[@lifecycleTrigger]
					(@lifecycleTrigger.start)="start($event)"
					(@lifecycleTrigger.done)="done($event)"
					#stepContainer
				></b-registration-holding-company-component>
			</ng-container>
		</ng-container>
	</ng-template>
</div>

<div
	b-registration-step-animation
	*ngIf="isAsideVisible"
	[@fadeAnimation]
	class="pos-relative lg:d-flex d-none flex-ai-end flex-jc-end"
	[ngClass]="{
		'flex-grow-1': !partner,
		'md:d-flex w-40p mx-auto': partner === registrationPartner.avito
	}"
	[partner]="partner"
></div>
