import { HttpMock } from 'shared';
import {
	GetSmsResponse,
	SessionDataResponse,
	AcessibleSlotsResponse,
} from '../types/registration.types';

const successCheckPhone: HttpMock<GetSmsResponse> = {
	url: '/api/sso/entrance/login',
	respBody: {
		value: {
			confirmToken: '84c3fc56-2de5-4d89-adfd-e71655388250',
		},
	},
	status: 200,
	// delay: 2500,
};

const errorCheckPhone: HttpMock = {
	url: '/api/sso/entrance/login',
	respBody: {
		error: {
			type: 'AlreadySended',
			message: 'Дождитесь завершения интервала между повторной отправкой СМС',
		},
	},
	status: 200,
	// delay: 2500,
};

const successCheckSms: HttpMock<SessionDataResponse> = {
	url: '/api/sso/entrance/verify',
	respBody: {
		value: {
			// eslint-disable-next-line max-len
			accessToken:
				'eyJhbGciOiJSUzI1NiIsImtpZCI6IkVDREM1NTU1RDA0RTY5MjZENUI5RUY5NTA0QTM3OThDIiwidHlwIjoiYXQrand0In0.eyJuYmYiOjE2MTAzNTEyODQsImV4cCI6MTYxMDM1ODQ4NCwiaXNzIjoiaHR0cDovL2xvY2FsaG9zdCIsImNsaWVudF9pZCI6InBob25lX251bWJlcl9hdXRoZW50aWNhdGlvbiIsInN1YiI6ImQ2Yjk4M2RkLWFkNWQtNGRiZi05MjcyLTU5NWZmNTQ2MWFkMCIsImF1dGhfdGltZSI6MTYxMDM1MTI4NCwiaWRwIjoibG9jYWwiLCJJZCI6ImQ2Yjk4M2RkLWFkNWQtNGRiZi05MjcyLTU5NWZmNTQ2MWFkMCIsIlBob25lTnVtYmVyIjoiNzk5NjQzNjQ2MzYiLCJQaG9uZU51bWJlckNvbmZpcm1lZCI6IlRydWUiLCJFTWFpbCI6IiIsIkVtYWlsQ29uZmlybWVkIjoiRmFsc2UiLCJOYW1lIjoiNzk5NjQzNjQ2MzYiLCJqdGkiOiJEQzhGOUQyNDlBMThCQTE1MTFEMkY2QjREMzJDMUQ3NCIsImlhdCI6MTYxMDM1MTI4NCwic2NvcGUiOlsiYXBpIiwib3BlbmlkIiwib2ZmbGluZV9hY2Nlc3MiXSwiYW1yIjpbInNtcyJdfQ.Aq4WK6-cdS16S_uQ0hz0DDFw6PTvIlGcW9eo8xD5tSWsi_oV-0_tz8XNUdxHmm89JxhQpE3YPuMB68NGfd0jBvYqF0vprphPL8-prcgE6NDzkKQbdN3R9S7bBk_QubiShNqZHQNA4mNWTCdf7SLaDKf7JsMBToJ_Bjzc-txEEUYtyIbjnVMuqjiH62uDrd4HBk8C2W3tXu9t9gTX713sJ-XEKZBjqyUD9XObOVD6OwELFT3sm4HkWkj6l0Sn6xqDICiPUbWNBPaAMYK77qDzDUxiZFiliaZd1Wpr-IFZqRVl9cmKeNLMw8gFbuOu12ja5euw6afNsYgUmSkSsuIGrw',
			refreshToken: 'E7A559A5067DD9551DD2621B63402D1B0AC0B42839CA0DCC51E2710FC2F28A2F',
			expiresIn: 3600,
		},
	},
	status: 200,
	// delay: 2500,
};

const errorCheckSms: HttpMock = {
	url: '/api/sso/entrance/verify',
	respBody: {
		error: {
			type: 'InvalidConfirmCode',
			message: 'invalid_request',
		},
	},
	status: 200,
	// delay: 2500,
};

const successGetGeoJson: HttpMock = {
	url: '/api/meetingmanager-service/maps/geojson',
	respBody: {
		value: {
			type: 'FeatureCollection',
			features: [
				// Ufa:
				{
					type: 'Feature',
					id: 0,
					geometry: {
						type: 'Polygon',
						coordinates: [
							[
								[55.92576801562496, 54.72193360959895],
								[55.949285624267546, 54.7182569345468],
								[55.95804035449217, 54.72014499871073],
								[55.97606479907221, 54.72451701870796],
								[55.98121464038081, 54.73524633237429],
								[55.984132883789, 54.73465033425325],
								[55.98825275683593, 54.73623964300562],
								[55.99099933886717, 54.737630236792214],
								[55.99855243945307, 54.7443838674775],
								[55.98550617480463, 54.75063989832075],
								[55.96937000537105, 54.75411505154838],
								[55.93950092578124, 54.744681795721654],
								[55.92576801562496, 54.72193360959895],
							],
						],
					},
					properties: {
						fill: '#b3b3b3',
						'fill-opacity': 0.6,
						stroke: '#b3b3b3',
						'stroke-width': '2',
						'stroke-opacity': 0.9,
					},
				},
				{
					type: 'Feature',
					id: 1,
					geometry: {
						type: 'Polygon',
						coordinates: [
							[
								[56.04404270434572, 54.76349646879586],
								[56.048936131320154, 54.759905121932874],
								[56.06490063937679, 54.7599547576768],
								[56.069449665866024, 54.76104672857499],
								[56.06507230075374, 54.76377652636304],
								[56.076144459567196, 54.76968218319848],
								[56.079921009860165, 54.773602267193034],
								[56.078633549533016, 54.77454501529398],
								[56.0721104172088, 54.77960570683973],
								[56.07088676939792, 54.780380984536535],
								[56.06577984343364, 54.774849028218554],
								[56.06470644259643, 54.77420300992138],
								[56.05681001925659, 54.76988599851147],
								[56.0544496753235, 54.771424297016246],
								[56.049686072113055, 54.77254076720331],
								[56.04404270434572, 54.76349646879586],
							],
						],
					},
					properties: {
						fill: '#ed4543',
						'fill-opacity': 0.6,
						stroke: '#ed4543',
						'stroke-width': '5',
						'stroke-opacity': 0.9,
					},
				},
				{
					type: 'Feature',
					id: 2,
					geometry: { coordinates: [55.940385, 54.725623], type: 'Point' },
					properties: {
						description: 'ул. Карла Маркса, 23',
						iconCaption: 'Кофикс',
						'marker-color': '#1e98ff',
						organizationId: 'kofics',
					},
				},
				{
					type: 'Feature',
					id: 3,
					geometry: { coordinates: [55.988028, 54.741607], type: 'Point' },
					properties: {
						description: 'просп. Октября, 4/1',
						iconCaption: 'Кофикс',
						'marker-color': '#1e98ff',
						organizationId: 'kofics',
					},
				},
				{
					type: 'Feature',
					id: 4,
					geometry: { coordinates: [55.946133, 54.724091], type: 'Point' },
					properties: {
						description: 'ул. Ленина, 5',
						iconCaption: 'Starbucks',
						'marker-color': '#1e98ff',
						organizationId: 'starbucks',
					},
				},
				{
					type: 'Feature',
					id: 5,
					geometry: { coordinates: [55.955931, 54.736515], type: 'Point' },
					properties: {
						description: 'ул. Цюрупы, 97, корп. 6',
						iconCaption: 'Starbucks',
						'marker-color': '#1e98ff',
						organizationId: 'starbucks',
					},
				},
				// Moscow:
				{
					type: 'Feature',
					id: 6,
					geometry: {
						type: 'Polygon',
						coordinates: [
							[
								[37.43298983984371, 55.862263357548876],
								[37.72550082617184, 55.81976325621782],
								[37.61151767187497, 55.91551570783785],
								[37.43298983984371, 55.862263357548876],
							],
						],
					},
					properties: {
						fill: '#ed4543',
						'fill-opacity': 0.6,
						stroke: '#ed4543',
						'stroke-width': '5',
						'stroke-opacity': 0.9,
					},
				},
				{
					type: 'Feature',
					id: 7,
					geometry: {
						type: 'Polygon',
						coordinates: [
							[
								[37.516760591796846, 55.632207983462195],
								[37.59503817968747, 55.6399761292589],
								[37.519507173828096, 55.64463627428102],
								[37.516760591796846, 55.632207983462195],
							],
						],
					},
					properties: {
						fill: '#ed4543',
						'fill-opacity': 0.6,
						stroke: '#ed4543',
						'stroke-width': '5',
						'stroke-opacity': 0.9,
					},
				},
				{
					type: 'Feature',
					id: 8,
					geometry: { coordinates: [37.622504, 55.753215], type: 'Point' },
					properties: {
						description: 'ул. Цюрупы, 97, корп. 6',
						iconCaption: 'Starbucks',
						'marker-color': '#1e98ff',
						organizationId: 'starbucks',
					},
				},
				{
					type: 'Feature',
					id: 9,
					geometry: {
						coordinates: [37.59091830664058, 55.85994637314193],
						type: 'Point',
					},
					properties: {
						'marker-color': '#1e98ff',
						organizationId: 'starbucks',
						description: 'ул. Цюрупы, 97, корп. 6',
						iconCaption: 'Starbucks',
					},
				},
			],
		},
	},
	status: 200,
	// delay: 2500,
};

const successAccessibleSlots: HttpMock<AcessibleSlotsResponse> = {
	url: '/api/meetingmanager-service/client/accessibleslots',
	respBody: {
		value: [
			{
				id: '22b8815b-f54e-4c4f-b363-c397479ea282',
				startAt: '2021-01-13T15:00:00.503Z',
				finishAt: '2021-01-13T15:30:00.503Z',
			},
			{
				id: '06f876e1-bc3c-42f6-baae-3a80feddc570',
				startAt: '2021-01-13T17:00:00.503Z',
				finishAt: '2021-01-13T17:30:00.503Z',
			},
			{
				id: '44eee3ec-e76b-4694-b205-20af2c9f6395',
				startAt: '2021-01-13T18:00:00.503Z',
				finishAt: '2021-01-13T18:30:00.503Z',
			},
			{
				id: 'ac5ace4a-f3dc-4836-b095-c6eb0d5918b6',
				startAt: '2021-01-13T19:00:00.503Z',
				finishAt: '2021-01-13T19:30:00.503Z',
			},
		],
	},
	status: 200,
	// delay: 2500,
};

const successAssign: HttpMock = {
	url: '/api/meetingmanager-service/client/assign',
	respBody: {
		value: {},
	},
	status: 200,
	// delay: 2500,
};

const successAcessibleDates: HttpMock = {
	url: '/api/meetingmanager-service/client/accessibledates',
	respBody: {
		value: Array.from({ length: 10 }, (v, i) =>
			new Date(new Date().setDate(new Date().getDate() + i)).toISOString(),
		),
	},
	status: 200,
	// delay: 2500,
};

export const registrationMockedUrls = [
	successCheckPhone,
	// errorCheckPhone,

	successCheckSms,
	// errorCheckSms,

	successGetGeoJson,

	successAccessibleSlots,
	successAssign,
	successAcessibleDates,
];
