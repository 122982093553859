import { ChangeDetectionStrategy, Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { LOCAL_STORAGE } from 'shared';
import { MeetingService } from '../../../services/meeting.service';
import { RegistrationStore } from '../../../services/registration.store';
import { Message } from '../../../types/chat.types';

declare let ymaps: any;

@Component({
	selector: 'b-registration-meeting-place-map',
	templateUrl: './meeting-place-map.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeetingPlaceMapComponent implements OnInit {
	@Input() message: Message;
	@ViewChild('mapContainer', { static: true }) mapContainer: ElementRef;
	companyId: string;

	comment: string;
	description: string | null;

	constructor(
		private meetingService: MeetingService,
		private store: RegistrationStore,
		@Inject(LOCAL_STORAGE) readonly localStorage: Storage,
	) {}

	ngOnInit(): void {
		this.store.selectedCompany$.subscribe(selectedCompany => {
			if (selectedCompany?.companyId) {
				this.companyId = selectedCompany.companyId;

				if (!this.comment) {
					const comment = this.localStorage?.getItem(`MeetingComment_${this.companyId}`);
					this.comment = comment || '';
				}
			}
		});

		const coords = this.message.data.feature.geometry.coordinates;
		const map = new ymaps.Map(
			this.mapContainer.nativeElement,
			{
				...this.meetingService.defaultMapState,
				bounds: this.meetingService.getBoundsBasedOnUnits(coords),
				restrictMapArea: true,
				behaviors: [],
			},
			this.meetingService.defaultMapOptions,
		);

		map.geoObjects.add(this.message.data.meetingPlaceMark);

		this.description = this.message.data?.feature?.properties?.description;
	}

	commentChange() {
		if (this.companyId) {
			this.localStorage?.setItem(`MeetingComment_${this.companyId}`, this.comment || '');
			this.meetingService.changeComment(this.comment || '');
		}
	}
}
