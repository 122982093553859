import { Injectable } from '@angular/core';
import Typograf from 'typograf';

@Injectable({
	providedIn: 'root',
})
export class TypografService {
	private readonly typograf: any = new Typograf({
		locale: ['ru', 'en-US'],
		htmlEntity: { type: 'name' },
	}) as any;

	constructor() {
		this.typograf.enableRule('common/nbsp/afterNumber');
		this.typograf.enableRule('common/number/digitGrouping');
		this.typograf.enableRule('ru/money/currency');

		this.typograf.disableRule('common/punctuation/delDoublePunctuation');
		this.typograf.disableRule('common/space/beforeBracket');
		this.typograf.disableRule('common/space/bracket');
		this.typograf.disableRule('common/space/delRepeatN');
		this.typograf.disableRule('common/space/replaceTab');
		this.typograf.disableRule('common/space/squareBracket');
		this.typograf.disableRule('common/symbols/arrow');
		this.typograf.disableRule('common/symbols/cf');
		this.typograf.disableRule('common/symbols/copy');
		this.typograf.disableRule('en-US/dash/main');
		this.typograf.disableRule('ru/date/fromISO');
		this.typograf.disableRule('ru/date/weekday');
		this.typograf.disableRule('ru/nbsp/m');
		this.typograf.disableRule('ru/number/comma');
		this.typograf.disableRule('ru/punctuation/exclamation');
		this.typograf.disableRule('ru/punctuation/exclamationQuestion');
	}

	public execute(text: string): string {
		return this.typograf.execute(text);
	}
}
