import { JwtPayload } from 'jwt-decode';
import { RegistrationPartner } from '../enums/RegistrationPartner';
import { Message } from './chat.types';
import { City } from './cities-list.types';
import { SelectedCompany } from './searchCompany.types';
import { Hint } from './city-selection.types';

export enum RegistrationSession {
	RegistrationAccessToken = 'RegistrationAccessToken',
	RegistrationRefreshToken = 'RegistrationRefreshToken',
	AccessTokenExpiresDate = 'AccessTokenExpiresDate',
}

export enum RegistrationSteps {
	start = 'start',
	whereRegistration = 'whereRegistration',
	enterPhone = 'enterPhone',
	opfSelection = 'opfSelection',
	enterSms = 'enterSms',
	searchCompany = 'searchCompany',
	accountOpeningInProcess = 'accountOpeningInProcess',
	noCompanyInfoInTaxService = 'noCompanyInfoInTaxService',
	citySelection = 'citySelection',
	meetingTypeSelection = 'meetingTypeSelection',
	addressSearch = 'addressSearch',
	datePicker = 'datePicker',
	accountIsAlreadyOpen = 'accountIsAlreadyOpen',
	empty = 'empty',
	last = 'last',
	waitingForRequests = 'waitingForRequests',
	haveHolding = 'haveHolding',
	/**
	 * пока пользователь не может самостоятельно создать видео встречу
	 * данный шаг определяет, что в выбранном городе нет КМов
	 */
	notServicedCity = 'notServicedCity',
}

export enum RegistrationSource {
	None = 'None',
	Mobile = 'Mobile',
	Landing = 'Landing',
	Agent = 'Agent',
	IncomingCall = 'IncommingCall',
	Sales = 'Sales',
	Avito = 'Avito',
}

export enum MeetingStatus {
	None = 'None',
	Planned = 'Planned',
	InProgress = 'InProgress',
	Cancelled = 'Cancelled',
	Completed = 'Completed',
}

export enum SmsStepStatus {
	default,
	codeNotCome,
	sendNewCode,
}

export type Phone = string;
export type SmsCode = string;
export type ConfirmToken = string;
export type AccessToken = string;
export type RefreshToken = string;

export interface GetSmsRequestBody {
	phone: Phone;
}

export interface GetSmsResponse {
	confirmToken: ConfirmToken;
	timeoutMs?: number;
}

export interface CheckSmsRequestBody {
	confirmToken: string;
	phoneNumber: string;
	code: string;
}

export type JwtPayloadType = {
	PhoneNumber: string;
	Id: string;
} & JwtPayload;

export interface SessionDataResponse {
	accessToken: AccessToken | null;
	refreshToken: RefreshToken | null;
	expiresIn: number | null;
	tokenPayload?: JwtPayloadType | null;
}

export enum CheckSmsErrors {
	InvalidConfirmCode = 'InvalidConfirmCode',
}

export interface StepServiceState {
	step: RegistrationSteps | null;
}

export interface SessionState {
	session: SessionDataResponse;
	clientCompanies: SelectedCompany[];
}

export interface AuthState {
	confirmToken: ConfirmToken | null;
	lastNumberEntered: string | null;
	selectedOpf: OrganizationalLegalForm;
	opfSelectionStep: RegistrationSteps;
}

export interface SmsBlockingState {
	secondsToUnlock: number | null;
	timeToUnlockSmsSending: number | null;
	smsStep: SmsStepStatus;
	interval: number | null;
}

export interface RegistrationState {
	environment: any;
	registrationProcessIsActive: boolean;
	isLanding: boolean;
	isAgentLanding: boolean;
	isInternalAgentLanding: boolean;
	partner: RegistrationPartner | null;
	isAvitoRegistration: boolean;
}

export interface AcessibleDate {
	date: Date;
	iso: string;
	title: string;
	subtitle: string;
}

export interface AcessibleSlot {
	id: string;
	startAt: string | Date;
	finishAt: string | Date;
	view?: string;
}

export type AcessibleSlotsResponse = AcessibleSlot[];

export interface DateSelectionState {
	dates: AcessibleDate[];
	selectedDate: AcessibleDate | null;
	slots: AcessibleSlot[];
	selectedTime: AcessibleSlot | null;
}

export type AcessibleDatesResponse = string[];

export interface AssignResponse {
	meetingId: string;
	status: MeetingStatus;
}

export interface RegistrationStoreState {
	selectedCompany: SelectedCompany;
	selectedCity: City;
	step: RegistrationSteps;
	messages: Message[];
	blockingLoader: boolean;
	canEdit: boolean;
	accumulatedMessages: Message[];
	phoneEnteredStatus: ProgressBarInputValueStatus;
	smsEnteredStatus: ProgressBarInputValueStatus;
	innEnteredStatus: ProgressBarInputValueStatus;
	isRegistrationBlocked: boolean;
	companyChosen: boolean;
	cityChosen: boolean;
	selectedAddress: Hint | null;
	timeChosen: boolean;
}

/** Организационно-правовая форма */
export enum OrganizationalLegalForm {
	/** ИП */
	IndividualEntrepreneur = 'IndividualEntrepreneur',
	/** ООО */
	LimitedLiabilityCompany = 'LimitedLiabilityCompany',
}

// определение шагов для прогресс бара
export enum ProgressBarInputValueStatus {
	/**
	 * ничего не ввели
	 */
	Empty = 'Empty',
	/**
	 * начали ввод
	 */
	Start = 'Start',
	/**
	 * полностью ввели
	 */
	Full = 'Full',
}
