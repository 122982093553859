<button
	b-shared-button
	[icon]="selectCompanyCityIcon"
	class="btn btn--primary btn--sm ml-auto mb-8"
	(click)="selectCompanyCity()"
	*ngIf="selectedCity?.displayName"
>
	{{ selectedCity.displayName }}
</button>

<button
	b-shared-button
	class="btn btn--sm ml-auto"
	(click)="showCitiesList()"
	[ngClass]="{
		'btn--primary': !selectedCity?.displayName,
		'btn--secondary': selectedCity?.displayName
	}"
>
	{{ selectedCity?.displayName ? 'Другой город' : 'Указать мой город' }}
</button>

<b-shared-overlay
	[overlayId]="onboardingOverlays.onboardingSelectCity"
	type="popup"
	overlayClass="overlay-content--popup-reg"
	containerClass="popup--sm popup--header-fixed popup--full-height"
	[routable]="false"
>
	<ng-template #header>
		<h3 class="h3">Место встречи</h3>
	</ng-template>

	<ng-template #content>
		<div
			b-registration-cities-list
			(hideCitiesList)="hideCitiesList()"
			class="popup__content-wrapper"
		></div>
	</ng-template>
</b-shared-overlay>
