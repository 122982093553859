import { effect, Injectable } from '@angular/core';
import { Company, CompanyDetailsResponse, SelectedCompany } from '../types/searchCompany.types';
import { RegistrationSteps, RegistrationStoreState, ProgressBarInputValueStatus } from '../types/registration.types';
import { Message } from '../types/chat.types';
import { City } from '../types/cities-list.types';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { StateService } from 'shared';
import { toSignal } from '@angular/core/rxjs-interop';
import { Hint } from '../types/city-selection.types';

const initState: RegistrationStoreState = {
	selectedCompany: null,
	selectedCity: null,
	step: null,
	messages: [],
	accumulatedMessages: [],
	blockingLoader: false,
	canEdit: false,
	phoneEnteredStatus: ProgressBarInputValueStatus.Empty, // шаги для прогресса: не ввели телефон, начали ввод, полностью ввели
	smsEnteredStatus: ProgressBarInputValueStatus.Empty, // шаги для прогресса
	innEnteredStatus: ProgressBarInputValueStatus.Empty, // шаги для прогресса
	isRegistrationBlocked: false, // попали на шаг, где дальнейшая рега не возможна: компания уже есть, телефон заблокирован
	companyChosen: false, // для прогресс бара: не ждать ответа с бека при выборе из списка дадата, сразу указывать, что выбрали
	cityChosen: false, // событие выбора города
	selectedAddress: null, // событие выбора адреса
	timeChosen: false, // событие выбора времени встречи
};

@Injectable({
	providedIn: 'root',
})
export class RegistrationStore extends StateService<RegistrationStoreState> {

	messages$ = this.select(state => state.messages);
	blockingLoader$ = this.select(state => state.blockingLoader);
	step$ = this.select(state => state.step);
	selectedCompany$ = this.select(state => state.selectedCompany);
	canEdit$ = this.select(state => state.canEdit);
	selectedCity$ = this.select(state => state.selectedCity);
	isQrStep$ = this.select(state => [
		RegistrationSteps.last,
		RegistrationSteps.accountOpeningInProcess,
		RegistrationSteps.waitingForRequests,
	].includes(state.step));
	readonly phoneEnteredStatus$ = this.select((s) => s.phoneEnteredStatus);
	readonly smsEnteredStatus$ = this.select((s) => s.smsEnteredStatus);
	readonly innEnteredStatus$ = this.select((s) => s.innEnteredStatus);
	readonly isRegistrationBlocked$ = this.select((s) => s.isRegistrationBlocked);
	readonly companyChosen$ = this.select((s) => s.companyChosen);
	readonly cityChosen$ = this.select((s) => s.cityChosen);
	readonly #selectedCompany = toSignal<SelectedCompany | null, null>(
		this.selectedCompany$,
		{
			initialValue: null,
		}
	);
	readonly #selectedCity = toSignal<City | null, null>(this.selectedCity$, {
		initialValue: null,
	});
	readonly addressChosen$ = this.select((s) => s.selectedAddress).pipe(
		map((value) => Boolean(value))
	);
	readonly timeChosen$ = this.select((s) => s.timeChosen);


	constructor(
		private http: HttpClient,
	) {
		super(initState);

		effect(() => {
			this.setChosenCompany(this.#selectedCompany());
			this.setChosenCity(this.#selectedCity());
		});
	}

	resetState() {
		this.setState(initState);
	}

	setSelectedCompany(selectedCompany: SelectedCompany): void {
		this.setState({ selectedCompany });
	}

	setSelectedCity(selectedCity: City): void {
		this.setState({ selectedCity });
	}

	changeStep(step: RegistrationSteps, canEdit: boolean = true): void {
		this.setState({ step });
		this.switchTheChangeability(canEdit);
	}

	switchTheChangeability(canEdit: boolean) {
		this.setState({ canEdit });
	}

	addMessage(message: Message): void {
		const messages = [...this.state.messages];
		messages.push(message);

		this.setState({ messages });
	}

	addStoredMessage(message: Message): void {
		const accumulatedMessages = [...this.state.accumulatedMessages];
		accumulatedMessages.push(message);

		this.setState({ accumulatedMessages });
	}

	showAccumulatedMessages() {
		this.setState({
			messages: [
				...this.state.messages,
				...this.state.accumulatedMessages,
			],
			accumulatedMessages: [],
		});
	}

	deleteMessage(id: string): void {
		const messageIndex = this.state.messages.findIndex(i => i.id === id);
		if (messageIndex !== -1) {
			const messages = [...this.state.messages];
			messages.splice(messageIndex, 1);

			this.setState({ messages });
		}

		const accumulatedMessageIndex = this.state.accumulatedMessages.findIndex(i => i.id === id);
		if (accumulatedMessageIndex !== -1) {
			const accumulatedMessages = [...this.state.accumulatedMessages];
			accumulatedMessages.splice(accumulatedMessageIndex, 1);

			this.setState({ accumulatedMessages });
		}
	}

	changeBlockingLoader(blockingLoader: boolean) {
		this.setState({ blockingLoader });
	}

	getCompanyDetails(companyId: string) {
		return this.http.get<CompanyDetailsResponse>(`/api/accounts-service/companies/detail/${companyId}`).pipe(
			tap({
				next: (res) => {
					this.setSelectedCompany({
						...this.state.selectedCompany,
						...res,
					});
				},
			})
		);
	}

	/**
	 * определяем на каком этапе ввода номера телефона
	 * @param value
	 * @returns
	 */
	setPhoneValue(value: string | null, isValid: boolean): void {
		if (typeof value !== 'string') {
			return;
		}

		if (!value) {
			// пустое значение
			this.setState({ phoneEnteredStatus: ProgressBarInputValueStatus.Empty });
			return;
		}

		if (isValid) {
			// полностью ввели телефон
			this.setState({ phoneEnteredStatus: ProgressBarInputValueStatus.Full });
			return;
		}

		// ввели какое-то кол-во символов
		this.setState({ phoneEnteredStatus: ProgressBarInputValueStatus.Start });
	}

	/**
	 * определяем на каком этапе ввода смс
	 * @param value
	 * @returns
	 */
	setSmsValue(value: string | null, isValid: boolean): void {
		if (!value) {
			// пустое значение
			this.setState({ smsEnteredStatus: ProgressBarInputValueStatus.Empty });
			return;
		}

		if (isValid) {
			// полностью ввели код из смс
			this.setState({ smsEnteredStatus: ProgressBarInputValueStatus.Full });
			return;
		}

		// ввели какое-то кол-во символов
		this.setState({ smsEnteredStatus: ProgressBarInputValueStatus.Start });
	}

	/**
	 * определяем на каком этапе ввода смс
	 * @param value
	 * @returns
	 */
	setInnValue(value: string | null): void {
		if (typeof value !== 'string') {
			return;
		}

		if (!value) {
			// пустое значение
			this.setState({ innEnteredStatus: ProgressBarInputValueStatus.Empty });
			return;
		}

		if (value.length >= 10) {
			//
			this.setState({ innEnteredStatus: ProgressBarInputValueStatus.Full });
			return;
		}

		// ввели какое-то кол-во символов
		this.setState({ innEnteredStatus: ProgressBarInputValueStatus.Start });
	}

	/**
	 * переключаем цвет статус бара в зависимости от возможности продолжения регистрации
	 * @param isBlock
	 */
	setRegistrationBlock(isBlock: boolean): void {
		this.setState({ isRegistrationBlocked: isBlock });
	}

	/**
	 * выбираем/сбрасываем выбор компании
	 * @param company
	 */
	setChosenCompany(company: Company | SelectedCompany | null): void {
		this.setState({ companyChosen: Boolean(company) });
	}

	/**
	 * выбираем/сбрасываем выбор города для встречи
	 * @param company
	 */
	setChosenCity(company: City | null): void {
		this.setState({ cityChosen: Boolean(company) });
	}

	/**
	 * выбираем/сбрасываем по какому адресу будет проходить встреча
	 * @param address
	 */
	setChosenAddress(address: Hint | null): void {
		this.setState({ selectedAddress: address });
	}

	/**
	 * выбираем/сбрасываем время встречи
	 * @param chosen
	 */
	setChosenTime(chosen: boolean): void {
		this.setState({ timeChosen: chosen });
	}
}
