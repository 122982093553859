<div class="d-flex flex-shrink-0 w-100p pr-12 py-12 pl-8" (click)="selectPoint()">
	<svg
		*ngIf="iconUrl"
		b-shared-svg
		class="text-xs w-44 h-100p my-auto mr-8"
		[name]="iconUrl"
		width="36"
	></svg>

	<div class="w-100p">
		<div class="d-flex">
			<h4 *ngIf="title" [innerHTML]="title | safeHtml" class="h4 mr-auto"></h4>
			<p *ngIf="time" class="text-sm d-flex">
				{{ time }}
				<svg b-shared-svg name="icons_20_walk" class="icon icon--lg"></svg>
			</p>
		</div>
		<p *ngIf="organization" [innerHTML]="organization | safeHtml" class="text-sm"></p>
		<p *ngIf="address" [innerHTML]="address | safeHtml" class="text-sm"></p>
	</div>
</div>
