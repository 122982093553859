<div #mapContainer class="chat__message-map map mt-n12 mb-16"></div>
<div class="px-16">
	<h4
		*ngIf="message.data?.feature?.properties?.iconCaption"
		[innerHTML]="message.data.feature.properties.iconCaption | safeHtml"
		class="h4 mb-4"
	></h4>

	<p *ngIf="description" [innerHTML]="description | safeHtml" class="text-sm mb-12"></p>

	<b-shared-textarea
		placeholder="Комментарий к адресу"
		class="field field--map-comment field--textarea mb-8"
		textareaClass="field__control"
		[minRows]="1"
		[maxRows]="7"
		[autoResize]="true"
		[(ngModel)]="comment"
		(ngModelChange)="commentChange()"
	></b-shared-textarea>
</div>
