<div class="d-flex flex-column flex-ai-end mt-n8">
	<button
		*ngFor="let office of (meetingService.enabledOffices$ | async); trackBy: trackByOfficeZones"
		b-shared-button
		[icon]="selectOfficeIcon"
		class="btn btn--secondary px-20 mt-8"
		[ngClass]="{
			'ta-l': !isOverlay
		}"
		(click)="selectOffice(office)"
	>
		<span
			class="d-block"
			*ngIf="office.displayName"
			[ngClass]="{
				'text-xs': !isOverlay,
				'text-md': isOverlay
			}"
		>{{ office.displayName }}</span>
		<span
			class="d-block"
			*ngIf="office?.officeAddress"
			[ngClass]="{
				'h4': !isOverlay,
				'text-md': isOverlay
			}"
		>{{ office.officeAddress }}</span>
	</button>

	<button
		*ngIf="(meetingService.nearestDates$ | async)?.slots?.departure"
		b-shared-button
		class="btn btn--primary btn--sm mt-8"
		(click)="comeToMe()"
	>
		Приедьте ко&nbsp;мне
	</button>
</div>
