import { Inject, Injectable } from '@angular/core';
import { DateService, StateService, DeviceService, LOCAL_STORAGE } from 'shared';
import { Guid } from 'guid-typescript';
import plural from 'plural-ru';
import { RegistrationStore } from './registration.store';
import { ChatService } from './chat.service';
import { MessageType, Senders } from '../types/chat.types';
import { RegistrationSteps, SmsBlockingState, SmsStepStatus } from '../types/registration.types';

const initState: SmsBlockingState = {
	secondsToUnlock: null,
	timeToUnlockSmsSending: null,
	smsStep: SmsStepStatus.default,
	interval: null,
};

@Injectable({
	providedIn: 'root',
})
export class SmsBlockingService extends StateService<SmsBlockingState> {
	secondsToUnlock$ = this.select((state) => state.secondsToUnlock);
	timeToUnlockSmsSending$ = this.select((state) => state.timeToUnlockSmsSending);
	smsStep$ = this.select((state) => state.smsStep);

	constructor(
		private store: RegistrationStore,
		private dateService: DateService,
		private chat: ChatService,
		private deviceService: DeviceService,
		@Inject(LOCAL_STORAGE) readonly localStorage: Storage,
	) {
		super(initState);
	}

	resetState() {
		this.setState(initState);
	}

	changeTimeToUnlockSmsSending(time: number | null) {
		this.setState({ timeToUnlockSmsSending: time });
	}

	changeSmsStep(smsStep: SmsStepStatus) {
		this.setState({ smsStep });
	}

	changeInterval(interval) {
		this.setState({ interval });
	}

	getMsToUnlock(count: number): number | null {
		if (!count) {
			return null;
		}

		let seconds;
		switch (count) {
		case 0:
			return null;
		case 5:
			seconds = 1000 * 60 * 1;
			break;
		case 10:
			seconds = 1000 * 60 * 5;
			break;
		case 15:
			seconds = 1000 * 60 * 15;
			break;
		case 20:
			seconds = 1000 * 60 * 60;
			break;
		default:
			if (count % 5 === 0) {
				seconds = 1000 * 60 * 60 * 24;
				break;
			}
			return null;
		}
		return seconds;
	}

	startSmsBlockingFlow(): void {
		const unlockDate = this.deviceService.isServer
			? null
			: this.localStorage?.getItem('RegistrationSmsUnlockDate');
		const unlocked: boolean = new Date(unlockDate)?.getTime() - new Date().getTime() <= 0;
		if (!unlockDate || unlocked) {
			if (!this.deviceService.isServer) {
				this.localStorage?.removeItem('RegistrationSmsUnlockDate');
			}
			return;
		}

		this.store.changeStep(RegistrationSteps.empty);

		const invalidSmsCount = this.deviceService.isServer
			? null
			: this.localStorage?.getItem('RegistrationInvalidSmsCount');
		const milliseconds = this.getMsToUnlock(Number(invalidSmsCount));
		let time;

		if (milliseconds) {
			const { hours, minutes } = this.dateService.parseMilliseconds(milliseconds);

			const hoursView: string | null = hours
				? `${hours} ${plural(hours, 'час', 'часа', 'часов')}`
				: null;
			const minutesView: string | null = minutes
				? `${minutes} ${plural(minutes, 'минуту', 'минуты', 'минут')}`
				: null;
			time = [hoursView, minutesView].filter(Boolean).join(', ');
		}

		this.chat.pushMessage({
			from: Senders.bank,
			type: MessageType.codeVerifyThrottled,
			data: {
				count: invalidSmsCount,
			},
		});

		this.chat.pushMessage({
			from: Senders.bank,
			type: MessageType.blockingCodeEntry,
			data: { time },
		});

		const timerMessageId = Guid.create().toString();

		this.chat.pushMessage({
			from: Senders.bank,
			type: MessageType.timer,
			id: timerMessageId,
			data: { timer: this.secondsToUnlock$ },
		});

		const handler = () => {
			const isUnlocked: boolean = new Date(unlockDate)?.getTime() - new Date().getTime() <= 0;

			if (isUnlocked) {
				this.setState({ secondsToUnlock: null });
				if (!this.deviceService.isServer) {
					this.localStorage?.removeItem('RegistrationSmsUnlockDate');
				}
				this.chat.deleteMessage(timerMessageId);

				this.chat.pushMessage({
					from: Senders.bank,
					type: MessageType.tryEnteringCodeAgain,
				});

				this.changeSmsStep(SmsStepStatus.sendNewCode);
				this.store.changeStep(RegistrationSteps.enterSms);
			} else {
				const timeDifference = (new Date(unlockDate).getTime() - new Date().getTime()) / 1000;
				const secondsToUnlock = Math.ceil(timeDifference);
				this.setState({ secondsToUnlock });
			}
		};

		handler();

		const interval = setInterval(() => {
			handler();
			if (!this.state.secondsToUnlock) {
				clearInterval(interval);
			}
		}, 1000);
	}
}
