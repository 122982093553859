import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { RegistrationService } from '../../../services/registration.service';
import { Company } from '../../../types/searchCompany.types';
import { RegistrationSteps } from '../../../types/registration.types';
import { MessageType, Senders } from '../../../types/chat.types';
import { ChatService } from '../../../services/chat.service';
import { Subscription } from 'rxjs';
import { RegistrationStore } from '../../../services/registration.store';
import { ScrollService, AutocompleteComponent } from 'shared';
import { animate, AnimationEvent, style, transition, trigger } from '@angular/animations';
import { tap } from 'rxjs/operators';
import { getMessageTimeout } from '../../../enums/chat.enums';

@Component({
	selector: 'b-registration-company-search',
	templateUrl: './company-search.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('fadeAnimation', [
			transition(':enter', [
				style({ opacity: 0 }),
				animate('200ms ease-out', style({ opacity: 1 })),
			]),
			transition(':leave', [
				animate('200ms ease-out', style({ opacity: 0 })),
			]),
		]),
	]
})
export class CompanySearchComponent implements OnInit, OnDestroy {
	companySearchForm: UntypedFormGroup;
	companiesList: Company[] = [];
	loading = false;
	error = null;
	selectedCompany: Company = null;
	noMyCompanyFlow: boolean = false;

	subscriptions = new Subscription();

	@ViewChild('autocompleteRef', { static: false }) autocompleteRef: AutocompleteComponent;

	@Input() registrationSection: ElementRef;
	@Input() scrollableChatContainer: boolean = false;
	@Input() placeholder: string = '';

	/** Для реги в шторке */
	@Input() isOverlay: boolean = false;

	constructor(
		private fb: UntypedFormBuilder,
		public registrationService: RegistrationService,
		public chat: ChatService,
		private store: RegistrationStore,
		private scrollService: ScrollService,
		private changeDetecor: ChangeDetectorRef,
		public elementRef: ElementRef,
	) {}

	ngOnInit(): void {
		this.companySearchForm = this.fb.group({
			searchField: [''],
		});

		if (this.registrationService.state.isLanding && !this.scrollableChatContainer) {
			this.subscriptions.add(
				this.scrollService.subscribeOnTopPartVisible(this.registrationSection, () => {
					this.autocompleteRef?.hideOverlay();
					this.autocompleteRef?.blur();
				})
			);
		}
	}

	ngOnDestroy() {
		this.subscriptions?.unsubscribe();
	}

	get searchFieldControl() {
		return this.companySearchForm.controls.searchField;
	}

	displayFn(company: Company) {
		return company?.companyName || '';
	}

	noMyCompany(): void {
		this.autocompleteRef?.hideOverlay();
		this.noMyCompanyFlow = true;
		this.changeDetecor.detectChanges();
	}

	getCompanySearchStream = (searchValue: string) => {
		this.loading = true;
		this.error = null;

		return this.registrationService
			.searchCompanies({ searchValue })
			.pipe(
				tap({
					next: (companiesList) => {
						this.loading = false;
						this.companiesList = companiesList || [];

						if (!this.companiesList.length) {
							this.searchFieldControl.setErrors({ noCompany: true });
						}

						this.changeDetecor.detectChanges();
					},
					error: (error) => {
						this.error = error;
						this.loading = false;
						this.searchFieldControl.setErrors({ defaultError: true });

						this.changeDetecor.detectChanges();
					},
				})
			);
	};

	selectCompany(company: Company): void {
		this.selectedCompany = company;
		this.store.setChosenCompany(company);
	}

	overlayAnimationEndHandler(event: AnimationEvent) {
		const isDestroyAnimationEnd = event.toState === 'void' && event.phaseName === 'done';

		if (!isDestroyAnimationEnd) return;

		if (this.selectedCompany) {
			this.registrationService.selectCompany({ company: this.selectedCompany }).subscribe({
				next: () => {
					this.registrationService.goToMeetingStep({});
				}
			});
		}

		if (this.noMyCompanyFlow) {
			this.noMyCompanyFlow = false;
			this.store.changeStep(RegistrationSteps.empty);

			this.chat.pushMessage({
				from: Senders.client,
				type: MessageType.noMyCompany,
			});

			this.chat.postponeAction(() => {
				this.chat.changeChatLoader(true);
			}, getMessageTimeout(1)).subscribe();

			this.chat.pushMessage({
				from: Senders.bank,
				type: MessageType.taxHasNotAddedCompany,
				timeout: getMessageTimeout(2),
			});

			this.chat.pushMessage({
				from: Senders.bank,
				type: MessageType.enterRequisities,
				timeout: getMessageTimeout(3),
			});

			this.chat.postponeAction(() => {
				this.chat.changeChatLoader(false);
				this.store.changeStep(RegistrationSteps.noCompanyInfoInTaxService);
			}, getMessageTimeout(3)).subscribe();
		}

	}

	clearHandler() {
		this.selectedCompany = null;
	}

	newlyRegistered(): void {
		this.store.changeStep(RegistrationSteps.empty);

		this.chat.pushMessage({
			from: Senders.client,
			type: MessageType.recentlyRegistered,
		});

		this.chat.postponeAction(() => {
			this.chat.changeChatLoader(true);
		}, getMessageTimeout(1)).subscribe();

		this.chat.pushMessage({
			from: Senders.bank,
			type: MessageType.taxHasNotAddedCompany,
			timeout: getMessageTimeout(2),
		});

		this.chat.pushMessage({
			from: Senders.bank,
			type: MessageType.enterRequisities,
			timeout: getMessageTimeout(3),
		});

		this.chat.postponeAction(() => {
			this.chat.changeChatLoader(false);
			this.store.changeStep(RegistrationSteps.noCompanyInfoInTaxService);
		}, getMessageTimeout(3)).subscribe();
	}

	get companyNotFound() {
		const notLoading = !this.loading;
		const fieldIsEmpty = !this.companySearchForm.controls.searchField.value;
		const companiesListIsEmpty = !this.companiesList?.length;
		const noError = !this.error;

		return noError && notLoading && companiesListIsEmpty && !fieldIsEmpty;
	}

	trackByCompanies = (index: number, hint) => hint.inn || hint.ogrn || `${hint.address}${hint.companyName}`;

}
