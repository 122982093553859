// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { EnvironmentConfig, Project } from 'shared';

export const environment: EnvironmentConfig = {
	project: Project['sol-landing'],
	production: false,
	productionUrl: new URL('https://sol.blanc.ru'),
	name: 'local',
	sentry: {
		enabled: false,
		tracingOrigins: ['https://sol.blanc.ru/'],
		debug: true,
	},
	yandexMapsConfig: {
		apikeys: {
			maps: '1277fc94-9532-4e20-b53a-77c9afda4f20',
			organizationsSearch: '6efb12fc-d89e-4fc7-adc2-8cf0b7ad78e6',
		},
		lang: 'ru_RU',
		host: 'api-maps.yandex.ru',
		mode: 'debug',
		version: '2.1.77',
	},
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
