import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ValidatorsService, MaskService } from 'shared';
import { RegistrationService } from '../../../services/registration.service';
import { RegistrationStore } from '../../../services/registration.store';

@Component({
	selector: 'b-registration-no-company-info-step',
	templateUrl: './no-company-info-step.component.html',
	animations: [
		trigger('lifecycleTrigger', [
			transition(':enter', [
				style({ opacity: 0, transform: 'translate3d(0, 100%, 0)' }),
				animate('200ms ease-out', style({ opacity: 1, transform: 'translate3d(0, 0, 0)' })),
			]),
			transition(':leave', [
				animate('200ms ease-out', style({ opacity: 0 })),
			]),
		]),
	],
})
export class NoCompanyInfoStepComponent implements OnInit {
	/** Для реги в шторке */
	@Input() isOverlay: boolean = false;

	innOgrnForm: UntypedFormGroup;

	constructor(
		private fb: UntypedFormBuilder,
		private validatorsService: ValidatorsService,
		private mask: MaskService,
		private registrationService: RegistrationService,
		private store: RegistrationStore,
	) {}

	ngOnInit(): void {
		this.innOgrnForm = this.fb.group({
			innOgrn: ['', [Validators.required]],
		});

		this.innOgrnForm.controls.innOgrn.valueChanges.subscribe((value) => {
			const innOgrn = this.mask.onlyNumbers(value);
			this.innOgrnForm.patchValue({ innOgrn }, { emitEvent: false });
			this.store.setInnValue(value);
		});
	}

	get innOgrnControl() {
		return this.innOgrnForm?.controls.innOgrn as UntypedFormControl;
	}

	get canSend() {
		return this.innOgrnControl.value;
	}

	get submitButtonIcon(): string {
		return this.isOverlay
			? 'icons_scaling_arrow-up'
			: 'icons_16_2_arrow-up';
	}

	onBlur() {
		const isInvalid = this.validatorsService.companyRequisities(this.innOgrnControl);

		if (isInvalid) {
			this.innOgrnControl.setErrors({ invalidInnAndOgrn: true });
		}
	}

	submit() {
		const isInvalid = this.validatorsService.companyRequisities(this.innOgrnControl);

		if (isInvalid) {
			this.innOgrnControl.setErrors({ invalidInnAndOgrn: true });
			return;
		}

		const isInn = !(
			this.validatorsService.validLengthInn({ lengthOfFiveIsAvailable: false })(
				this.innOgrnControl,
			) &&
			this.validatorsService.innTwoFirstCharactersIsZeroes(this.innOgrnControl) &&
			this.validatorsService.validInn(this.innOgrnControl)
		);

		const isOgrn = !(
			this.validatorsService.validLengthOgrn(this.innOgrnControl) &&
			this.validatorsService.validOgrnValue(this.innOgrnControl)
		);

		let key;

		if (isOgrn) {
			key = 'ogrn';
		} else if (isInn) {
			key = 'inn';
		}

		const company = {
			[key]: this.innOgrnControl.value,
			foundInPublicRefs: false,
		};

		this.registrationService.selectCompany({ company, isManual: true }).subscribe({
			next: () => {
				this.registrationService.goToMeetingStep({});
			}
		});
	}
}
