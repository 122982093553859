import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';
import { expandRoutes } from 'shared';

const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		loadChildren: () => import('./home-page/home-page.module').then((m) => m.HomePageModule),
	},
	{
		path: '**',
		redirectTo: ''
	},
];

const extendedRoutes: Routes = expandRoutes({
	routes,
	defaultMeta: {
		title: 'Оформи самозанятость в SOL',
		description: 'Начни работать на себя. А мы сами сформируем чеки и оплатим за тебя налоги — всё онлайн',
		keywords: 'расчетный счет, расчетный счет для ИП, открыть расчетный счет, открыть расчетный счет для ООО, открыть расчетный счет для ИП, расчетный счет онлайн, открытие счета в банке для юридических лиц, расчетный счет юридического лица, РКО',
		ogTitle: 'Оформи самозанятость в SOL',
		ogDescription: 'Начни работать на себя. А мы сами сформируем чеки и оплатим за тебя налоги — всё онлайн',
		ogImage: 'https://blanc.ru/assets/img/open-graph/sol-landing.jpg',
		ogAlt: 'Оформи самозанятость в SOL',
		ogUrl: 'https://sol.blanc.ru/',
		viewport: 'width=device-width, initial-scale=1',
	},
});

@NgModule({
	imports: [
		RouterModule.forRoot(extendedRoutes, {
			initialNavigation: 'enabledBlocking', // чтобы не было мигания страницы
			preloadingStrategy: NoPreloading,
			useHash: false,
			anchorScrolling: 'enabled',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
