import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import plural from 'plural-ru';
import { MeetingService } from '../../../../services/meeting.service';
import { Feature, Hint, MapView } from '../../../../types/city-selection.types';
import { ScrollService, AutocompleteComponent } from 'shared';
import { AddressesService } from '../../../../services/addresses.service';
import { CitiesListService } from '../../../../services/cities-list.service';
import { DOCUMENT } from '@angular/common';
import {RegistrationService} from '../../../../services/registration.service';
import { tap } from 'rxjs/operators';
import { AnimationEvent } from '@angular/animations';
import { RegistrationStore } from '../../../../services/registration.store';

declare let ymaps: any;

@Component({
	selector: 'b-registration-address-search',
	templateUrl: './address-search.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressSearchComponent implements OnInit, OnDestroy, AfterViewInit {
	addressSearchForm: UntypedFormGroup;
	hints: Hint[] = [];
	mapViewTypes = MapView;
	mapView: MapView;
	mapOverlayVisibility = false;
	mapContainerClass = 'small-map-container';
	loader = false;
	routesLoader = false;
	selectedHint: Hint = null;
	nearbyAddresses: Feature[] = [];
	subscriptions: Subscription = new Subscription();
	observer: MutationObserver;
	canHoldMeeting: boolean;

	@ViewChild('mapContainer', { static: false }) mapContainer: ElementRef;
	@ViewChild('mapOverlay', { static: false }) mapOverlay: ElementRef;
	@ViewChild('autocompleteRef', { static: false }) autocompleteRef: AutocompleteComponent;

	@Input() registrationSection: ElementRef;
	@Input() scrollableChatContainer: boolean = false;

	/** Для реги в шторке */
	@Input() isOverlay: boolean = false;

	constructor(
		public meetingService: MeetingService,
		private changeDetector: ChangeDetectorRef,
		public addressesService: AddressesService,
		private citiesListService: CitiesListService,
		private scrollService: ScrollService,
		public registrationService: RegistrationService,
		private fb: UntypedFormBuilder,
		@Inject(DOCUMENT) private document: Document,
		public elementRef: ElementRef,
		private store: RegistrationStore,
	) {}

	ngOnInit(): void {
		this.addressSearchForm = this.fb.group({
			searchField: [''],
		});

		this.subscriptions.add(
			this.meetingService.canHoldMeeting$.subscribe({
				next: (canHoldMeeting) => {
					this.canHoldMeeting = canHoldMeeting;
					this.mapContainerClass = canHoldMeeting ? 'drop__map drop__map--sm map' : 'drop__map map';
					this.changeDetector.detectChanges();
				},
			}),
		);

		this.subscriptions.add(
			this.meetingService.mapView$.subscribe(mapView => {
				this.mapView = mapView;
				this.changeDetector.detectChanges();
			}),
		);

		this.document.addEventListener('pointerdown', this.documentListener);

		if (this.registrationService.state.isLanding && !this.scrollableChatContainer) {
			this.subscriptions.add(
				this.scrollService.subscribeOnTopPartVisible(this.registrationSection, () => {
					this.autocompleteRef?.hideOverlay();
					this.autocompleteRef?.blur();
					this.mapOverlayVisibility = false;
					this.changeDetector.detectChanges();
				})
			);
		}
	}

	ngAfterViewInit() {
		this.observer = new MutationObserver(() => {
			this.meetingService.state.map?.container?.fitToViewport();
			this.meetingService.setZoom();
		});

		this.observer.observe(this.mapContainer.nativeElement, {
			attributes: true,
			attributeFilter: ['class'],
		});

		this.observer.observe(this.mapOverlay.nativeElement, {
			attributes: true,
			attributeFilter: ['class'],
		});

		this.autocompleteRef?.focus();
	}

	documentListener = (event) => {
		const isBeyondBorders = [
			this.autocompleteRef?.elementRef?.nativeElement,
			this.mapOverlay?.nativeElement,
		].every((i) => !i?.contains(event.target));

		if (isBeyondBorders) {
			this.mapOverlayVisibility = false;
			this.changeDetector.detectChanges();
		}
	};

	ngOnDestroy() {
		this.document.removeEventListener('pointerdown', this.documentListener);
		this.observer?.disconnect();
		this.subscriptions?.unsubscribe();
		this.selectedHint = null;
	}

	getMapVisibility() {
		return Boolean(
			!this.canHoldMeeting &&
			this.selectedHint &&
			!this.loader &&
			!this.routesLoader
		);
	}

	displayFn(hint: Hint) {
		return hint?.address || '';
	}

	getAddressSearchStream = (searchValue: string) => {
		const selectedCity = this.citiesListService.state.selectedCity.displayName;
		const value = selectedCity ? `${selectedCity} ${searchValue}` : searchValue;

		this.mapOverlayVisibility = false;
		this.loader = true;
		this.selectAddress = null;

		return this.addressesService
			.addressAutocomplete(value, this.citiesListService.state.selectedCity.displayName)
			.pipe(
				tap({
					next: () => {
						this.hints = [...this.addressesService.state.addressHints];
						this.loader = false;

						if (!this.hints.length) {
							this.searchControl.setErrors({ noAddress: true });
						}

						this.changeDetector.detectChanges();
					},
					error:(response) => {
						this.hints = [];
						this.loader = false;
						let errors;

						switch (response?.error?.type) {
						case 'ServiceIsUnavailable':
							errors = { serviceUnavailable: true };
							break;
						default:
							errors = { noAddress: true };
							break;
						}

						this.searchControl.setErrors(errors);
						this.changeDetector.detectChanges();
					},
				})
			);
	};

	overlayAnimationEndHandler(event: AnimationEvent) {
		const isDestroyAnimationEnd = event.toState === 'void' && event.phaseName === 'done';
		// когда анимация дестроя завершилась:
		if (isDestroyAnimationEnd && this.selectedHint) {
			const el: HTMLElement = this.mapContainer.nativeElement;
			this.meetingService.selectHint(this.selectedHint, el);

			if (this.meetingService.state.canHoldMeeting) {
				// если адрес в зоне обслуживания, то грузим ближайшие кафе:
				// this.calculateRouteTimes([hint.coordinates.latitude, hint.coordinates.longitude]);
			} else {
				this.mapOverlayVisibility = true;
			}

			this.changeDetector.detectChanges();
		}
	}

	selectHint(hint: Hint): void {
		this.selectAddress = hint;
	}

	clearHandler(): void {
		this.selectAddress = null;
	}

	trackByHints = (index: number, hint) => `${hint.address}${hint.ogrCategory}${hint.ogranization}`;

	trackByPoints(index: number, point) {
		return `${point.id}${index}`;
	}

	get searchControl() {
		return this.addressSearchForm.controls.searchField;
	}

	get fieldIsEmpty() {
		return !this.searchControl.value;
	}

	getNearbyAddresses(coords: number[]) {
		const nearbyAddresses = [...this.meetingService.state.geoJson.points.features].sort(
			(a, b) =>
				this.meetingService.getDistance(coords, a.geometry.coordinates) -
				this.meetingService.getDistance(coords, b.geometry.coordinates),
		);
		nearbyAddresses.splice(3);

		return [...nearbyAddresses];
	}

	calculateRouteTimes(coords: number[]) {
		const nearbyAddresses = this.getNearbyAddresses(coords);

		if (nearbyAddresses?.length) {
			this.routesLoader = true;
		}

		const result = [];

		nearbyAddresses?.forEach((address) => {
			const multiRouteModel = new ymaps.multiRouter.MultiRouteModel(
				[address.geometry.coordinates, coords],
				{
					avoidTrafficJams: true,
					routingMode: 'pedestrian',
				},
			);

			multiRouteModel.events.add('requestsuccess', () => {
				const routes = multiRouteModel.getRoutes();
				const sortedRoutes = [...routes].sort(
					(a, b) => a.properties.get('duration').value - b.properties.get('duration').value,
				);

				const shortestDuration = sortedRoutes[0].properties.get('duration');
				const routeTime = Math.ceil(shortestDuration.value / 60);
				const routeTimeView = `${routeTime} ${plural(routeTime, 'минута', 'минуты', 'минут')}`;

				const feature = {
					...address,
					properties: {
						...address.properties,
						routeTime,
						routeTimeView,
					},
				};

				result.push(feature);

				if (nearbyAddresses.length === result.length) {
					this.routesLoader = false;
					this.nearbyAddresses = result
						.sort((a, b) => a.properties.routeTime - b.properties.routeTime)
						.map((i) => {
							const orgId = i.properties?.organizationId;
							const icon =
								orgId === 'address'
									? 'meeting-points_address-default'
									: 'meeting-points_cafe-default';
							return { ...i, properties: { ...i.properties, icon } };
						})
						.filter((i) => i.properties?.icon);
					this.mapOverlayVisibility = true;
				}
			});
		});
	}

	set selectAddress(value: Hint | null) {
		this.selectedHint = value;
		this.store.setChosenAddress(value);
	}
}
