import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ChatService } from '../../../services/chat.service';
import { RegistrationService } from '../../../services/registration.service';
import { RegistrationStore } from '../../../services/registration.store';
import { Message, MessageType } from '../../../types/chat.types';
import { RegistrationSteps } from '../../../types/registration.types';

@Component({
	selector: 'b-registration-change-company-button-component',
	templateUrl: './change-company-button.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeCompanyButtonComponent implements OnInit, OnDestroy {

	subscriptions = new Subscription();
	messages: Message[];

	constructor(
		private store: RegistrationStore,
		private registrationService: RegistrationService,
		private chat: ChatService,
		private changeDetector: ChangeDetectorRef,
		public elementRef: ElementRef,
	) {}

	ngOnInit() {
		this.subscriptions.add(
			this.store.messages$.subscribe(messages => {
				this.messages = messages;
				this.changeDetector.detectChanges();
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions?.unsubscribe();
	}

	deleteMessages() {
		const messageIndex = this.messages.findIndex(message => message.type === MessageType.enterOrganizationName);

		if (messageIndex !== -1) {
			const deletedMessages = [...this.messages].slice(messageIndex + 1);
			deletedMessages.forEach(i => this.chat.deleteMessage(i.id));
		}

		// сбрасываем состояние ошибки в процессе регистрации у прогресс бара
		this.store.setRegistrationBlock(false);
	}

	changeCompany() {
		const currentCompanyId = this.store.state?.selectedCompany?.companyId;
		if (currentCompanyId) {
			this.store.setSelectedCompany(null);
			this.registrationService.deleteCompany(currentCompanyId).subscribe();
		}

		this.deleteMessages();
		this.store.changeStep(RegistrationSteps.searchCompany, false);
	}

}
