import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor } from '@angular/common/http';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { RegistrationSession } from '../types/registration.types';
import { SessionService } from '../services/session.service';
import { Observable, Subject, throwError } from 'rxjs';
import { RegistrationService } from '../services/registration.service';
import { DeviceService, LOCAL_STORAGE, WINDOW } from 'shared';

@Injectable()
export class RegistrationTokenInterceptor implements HttpInterceptor {
	refreshTokenInProgress = false;
	tokenRefreshedSource = new Subject<void>();
	tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

	constructor(
		private sessionService: SessionService,
		private registrationService: RegistrationService,
		private deviceService: DeviceService,
		@Inject(LOCAL_STORAGE) readonly localStorage: Storage,
		@Inject(WINDOW) private _window: Window,
	) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): any {
		const registrationServices = [
			'/api/accounts-service',
			'/api/meetingmanager-service',
			'/api/sso',
			'/api/exponea-service'
		];
		const isRegistrationService = registrationServices.some((serviceUrl) =>
			req.url.startsWith(serviceUrl),
		);

		if (!isRegistrationService && !this.registrationService.state.registrationProcessIsActive) {
			return next.handle(req.clone());
		}

		const registrationToken = this.deviceService.isServer
			? null
			: this.localStorage?.getItem(RegistrationSession.RegistrationAccessToken) ||
			  this.sessionService.state.session.accessToken;

		const clonedRequest = this.getRequestWithBearer(req, registrationToken);

		return next
			.handle(clonedRequest)
			.pipe(catchError((error) => this.handleResponseError(error, clonedRequest, next)));
	}

	getRequestWithBearer(request, token) {
		if (token) {
			return request.clone({
				setHeaders: {
					Authorization: `Bearer ${token}`,
				},
			});
		}
		return request.clone();
	}

	handleResponseError(error, request?, next?) {
		if (error.status === 401) {
			return this.refreshToken().pipe(
				switchMap(() => {
					const registrationToken =
						(this.deviceService.isServer
							? null
							: this.localStorage?.getItem(RegistrationSession.RegistrationAccessToken)) ||
						this.sessionService.state.session.accessToken;
					request = this.getRequestWithBearer(request, registrationToken);
					return next.handle(request);
				}),
				catchError((e) => {
					if (e.status !== 401) {
						return this.handleResponseError(e);
					} else {
						this.reloadRegistration();
					}
				}),
			);
		} else if (error.status === 403) {
			this.reloadRegistration();
		}

		return throwError(() => error);
	}

	refreshToken(): Observable<any> {
		if (this.refreshTokenInProgress) {
			return new Observable((observer) => {
				this.tokenRefreshed$.subscribe(() => {
					observer.next();
					observer.complete();
				});
			});
		} else {
			this.refreshTokenInProgress = true;

			return this.sessionService.updateAccessToken().pipe(
				tap(() => {
					this.refreshTokenInProgress = false;
					this.tokenRefreshedSource.next();
				}),
				catchError((err) => {
					this.refreshTokenInProgress = false;
					this.reloadRegistration();
					return throwError(() => err);
				}),
			);
		}
	}

	reloadRegistration() {
		this.sessionService.setSessionData(null);

		if (!this.deviceService.isServer) {
			this._window.location.reload();
		}
	}
}
