import { DatePipe, TitleCasePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { StateService, DateService } from 'shared';
import {
	AcessibleDate,
	AcessibleDatesResponse,
	AcessibleSlot,
	AcessibleSlotsResponse,
	DateSelectionState,
} from '../types/registration.types';

const initState: DateSelectionState = {
	dates: [],
	selectedDate: null,
	slots: [],
	selectedTime: null,
};

@Injectable({
	providedIn: 'root',
})
export class DateSelectionService extends StateService<DateSelectionState> {
	dateSelectionState$: Observable<DateSelectionState> = this.select(state => state);
	dates$: Observable<AcessibleDate[]> = this.select((state) => state.dates);
	selectedDate$: Observable<AcessibleDate> = this.select((state) => state.selectedDate);
	slots$: Observable<AcessibleSlot[]> = this.select((state) => state.slots);
	selectedTime$: Observable<AcessibleSlot> = this.select((state) => state.selectedTime);

	constructor(
		private http: HttpClient,
		private datePipe: DatePipe,
		private titleCasePipe: TitleCasePipe,
		private dateService: DateService,
	) {
		super(initState);
	}

	resetState() {
		this.setState(initState);
	}

	changeTime(slot: AcessibleSlot) {
		if (slot) {
			this.setState({ selectedTime: slot });
		}
	}

	changeDate(date: AcessibleDate) {
		if (date) {
			this.setState({ selectedDate: date });
		}
	}

	getAcessibleDates({
		count = 10,
		geoZone,
	}: {
		count?: number;
		geoZone: string;
	}): Observable<AcessibleSlotsResponse> {
		return this.http
			.post<AcessibleDatesResponse>('/api/meetingmanager-service/client/availabledates', {
				geoZone,
				count,
			})
			.pipe(
				mergeMap((dates) => {
					const now = new Date();
					now.setHours(0, 0, 0, 0);
					const sortedDates = dates
						.map((i) => this.dateService.stringToDate(i))
						.filter((date) => date.getTime() >= now.getTime())
						.sort((a, b) => a.getTime() - b.getTime())
						.map((date) => this.getDateModel(date));

					const selectedDate = sortedDates[0];

					this.setState({
						dates: sortedDates,
						selectedDate,
					});

					return this.getAccessibleSlots({ geoZone, selectedDate });
				}),
			);
	}

	getAccessibleSlots({
		geoZone,
		selectedDate = this.state.selectedDate,
	}): Observable<AcessibleSlotsResponse> {
		return this.http
			.post<AcessibleSlotsResponse>('/api/meetingmanager-service/client/availableslots', {
				geoZone,
				day: selectedDate.iso,
			})
			.pipe(
				tap((response) => {
					if (response) {
						const uniqueSlots = [...new Map(response.map((item) => [item.startAt, item])).values()];

						const slots = uniqueSlots.map((i) => ({
							...i,
							view: this.datePipe.transform(new Date(i.startAt), 'HH:mm'),
							startAt: new Date(i.startAt),
							finishAt: new Date(i.finishAt),
						}));

						const now = new Date();
						let selectedTime;

						if (
							now.getDate() === selectedDate.date.getDate() &&
							now.getFullYear() === selectedDate.date.getFullYear() &&
							now.getMonth() === selectedDate.date.getMonth()
						) {
							selectedTime =
								slots.find((slot) => {
									const ms = slot.startAt.getTime() - new Date().getTime();
									return ms >= 45 * 60 * 1000;
								}) || slots[0];
						} else {
							selectedTime = slots[0];
						}

						this.setState({ slots, selectedTime });
					}
				}),
			);
	}

	getDateHint(date: Date) {
		return this.dateService.getAdverbOfDate(date) || this.datePipe.transform(date, 'EEEE');
	}

	getDateModel(date: Date): AcessibleDate {
		return {
			date,
			iso: this.dateService.ignoreTimezone(date),
			title: this.datePipe.transform(date, 'd MMMM'),
			subtitle: this.titleCasePipe.transform(this.getDateHint(date)),
		};
	}
}
