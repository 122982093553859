import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TypografService } from '../services/typograf.service';

@Pipe({
	name: 'typograf',
	pure: true,
	standalone: true,
})
export class TypografPipe implements PipeTransform {
	constructor(
		private sanitizer: DomSanitizer,
		private typograf: TypografService
	) {}

	transform(htmlCode: string): SafeHtml {
		return this.sanitizer.bypassSecurityTrustHtml(
			this.typograf.execute(htmlCode)
		);
	}
}
