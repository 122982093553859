<div class="d-flex sm:flex-row flex-column flex-ai-end flex-jc-end" *ngIf="opfSelectionIsAvailable">
	<button
		b-shared-button
		class="btn btn--sm sm:mr-8 sm:mb-0 mb-8"
		(click)="selectOpf(opf.IndividualEntrepreneur)"
		[ngClass]="{
			'btn--secondary': selectedOpf !== opf.IndividualEntrepreneur,
			'btn--primary': selectedOpf === opf.IndividualEntrepreneur
		}"
	>
		Нужен счёт для&nbsp;ИП
	</button>
	<button
		b-shared-button
		class="btn btn--sm"
		(click)="selectOpf(opf.LimitedLiabilityCompany)"
		[ngClass]="{
			'btn--secondary': selectedOpf !== opf.LimitedLiabilityCompany,
			'btn--primary': selectedOpf === opf.LimitedLiabilityCompany
		}"
	>
		Нужен счёт для&nbsp;ООО
	</button>
</div>

<form
	[formGroup]="phoneForm"
	(ngSubmit)="submit()"
	class="d-flex w-100p"
	[ngClass]="{ 'mt-8': opfSelectionIsAvailable }"
>
	<div
		class="field field--btn ov-hidden"
		[ngClass]="{
			'field--reg': isOverlay
		}"
	>
		<span class="field__addon field__addon--code-phone ml-8 mr-n4">+7</span>
		<input
			type="tel"
			inputmode="numeric"
			[attr.placeholder]="'(×××) ×××-××-××'"
			autocomplete="off"
			mask="(000) 000-00-00"
			class="field__control pr-4"
			formControlName="phone"
			[validation]="false"
			[dropSpecialCharacters]="false"
			#phoneInput
			id="phoneInputRegistration"
			(paste)="pastePhone($event)"
		>
		<button
			[@lifecycleTrigger]
			b-shared-button
			*ngIf="canSendPhone"
			type="submit"
			class="field__addon btn btn--primary"
			[ngClass]="{
				'brs-12 p-0': isOverlay
			}"
			[disabled]="!canSendPhone"
		>
			<span
				[ngClass]="{
					'd-none': true,
					'lg:d-block': !isOverlay
				}"
			>Отправить</span>
			<svg
				b-shared-svg
				[name]="submitButtonIcon"
				[ngClass]="{
					'icon': true,
					'icon--xl': isOverlay,
					'lg:d-none': !isOverlay
				}"
			></svg>
		</button>
	</div>
</form>
