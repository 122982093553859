<button
	b-shared-button
	class="btn btn--primary btn--sm"
	(click)="openCompanyList()"
>
	Класс, давайте
</button>
<button
	b-shared-button
	class="btn btn--secondary btn--sm mt-8"
	(click)="noRegistrationHolding()"
>
	Не&nbsp;нужно
</button>
<b-shared-overlay
	[overlayId]="onboardingOverlays.holdingCompanySelect"
	type="popup"
	containerClass="popup--sm popup--full-height"
	overlayClass="overlay-content--popup-reg"
	contentClass="mb-auto"
	(hideHandler)="hideHandler()"
	[routable]="false"
>
	<ng-template #header>
		<h3 class="h3">Для каких компаний открыть счёт</h3>
	</ng-template>

	<ng-template #content>
		<ul
			class="ul ul--no-marker px-8"
			*sharedLet="holdingCompanies$ | async"
		>
			<li
				*ngFor="let company of holdingCompanies; let first = first"
				class="mb-8"
			>
				<label
					class="btn-cell"
					[ngClass]="{
						'btn-cell--error': (isShowErrorCheck$ | async)
					}"
					sharedClick
				>
					<span class="d-flex flex-column mr-12">
						<span class="btn-cell__title">
							{{ company?.companyName }}
						</span>

						<span class="btn-cell__subtitle">
							<span class="d-flex flex-wrap">
								<span class="mr-12">
									ИНН: {{ company?.inn }}
								</span>

								<span>
									{{shortName(company?.companyName)}}: {{ company?.ogrn }}
								</span>
							</span>

							<span>
								Дата регистрации: {{ company?.fnsRegistrationDate | date: 'dd.MM.yyyy' }}
							</span>
						</span>
					</span>
					<!--TODO: Переделать на компонент checkbox-->
					<span
						class="checkbox"
					>
						<input
							class="checkbox__input"
							type="checkbox"
							[attr.name]="company.id"
							[value]="company.id"
							[(ngModel)]="company.checked"
							(ngModelChange)="clearCheckedCompanyErrors()"
						>
						<svg
							b-shared-svg
							name="checkbox_sm"
							class="checkbox__box pos-static mr-0"
							height="20"
						></svg>
					</span>
				</label>
			</li>
		</ul>
	</ng-template>

	<ng-template #footer>
		<button
			b-shared-button
			class="btn btn--primary btn--lg flex-grow-1 mt-16 mb-12 mx-8"
			[disabled]="loader"
			[loading]="loader"
			(click)="startRegMultiple()"
		>
			Выбрать
		</button>
	</ng-template>
</b-shared-overlay>
