import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnDestroy,
	OnInit,
	ViewEncapsulation,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { MeetingService } from '../../../../services/meeting.service';
import { OverlayService2, AnalyticsService, EventAction, EventCategory } from 'shared';
import { RegistrationStore } from '../../../../services/registration.store';
import { OnboardingOverlays } from '../../../../types/overlays.types';
import { City } from '../../../../types/cities-list.types';
import { CitiesListService } from '../../../../services/cities-list.service';
import { ChatService } from '../../../../services/chat.service';
import { RegistrationSteps } from '../../../../types/registration.types';
import { SendApplicationErrors } from '../../../../types/cities-list.types';
import { MeetingAssignService } from '../../../../services/meeting-assign.service';

@Component({
	selector: 'b-registration-city-selection',
	templateUrl: './city-selection.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CitySelectionComponent implements OnInit, OnDestroy {
	/** Для реги в шторке */
	@Input() isOverlay: boolean = false;

	subscriptions: Subscription = new Subscription();
	onboardingOverlays = OnboardingOverlays;
	selectedCity: City;

	constructor(
		private meetingService: MeetingService,
		private registrationStore: RegistrationStore,
		private citiesListService: CitiesListService,
		private overlayService: OverlayService2,
		private changeDetector: ChangeDetectorRef,
		private analyticsService: AnalyticsService,
		private chat: ChatService,
		private meetingAssignService: MeetingAssignService,
	) {}

	ngOnInit(): void {
		this.citiesListService.setSelectedCity(null);
		this.meetingService.changeSelectedOffice(null);
		this.meetingService.changeDepartureFlow(false);
		this.meetingService.setSelectedFeature(null);
		this.meetingService.setSelectedPolygon(null);

		this.subscriptions.add(
			this.registrationStore.selectedCity$.subscribe({
				next: selectedCity => {
					this.selectedCity = selectedCity;
					this.changeDetector.detectChanges();
				},
			})
		);
	}

	get selectCompanyCityIcon(): string {
		return !this.isOverlay ? 'icons_16_2_flag' : '';
	}
	ngOnDestroy() {
		this.subscriptions?.unsubscribe();
	}

	showCitiesList() {
		this.analyticsService.sendAction(EventAction.specify_city, EventCategory.user_click);
		this.overlayService.open({
			overlayId: OnboardingOverlays.onboardingSelectCity,
		});
	}

	hideCitiesList() {
		this.overlayService.close(OnboardingOverlays.onboardingSelectCity);
	}

	selectCompanyCity(): void {
		this.registrationStore.setChosenCity(this.selectedCity);
		if (this.selectedCity.isServiced) {
			this.startMeetingForServicedCity();
		} else {
			this.submitApplicationForNotServicedCity();
		}
	}

	private startMeetingForServicedCity(): void {
		this.citiesListService.saveCity(this.selectedCity.displayName).subscribe();
		this.citiesListService.selectCity(this.selectedCity);
		this.meetingService.startMeetingTypeSelection(this.selectedCity).subscribe();
	}

	private submitApplicationForNotServicedCity(): void {
		// TODO: это шаг - хак для прогресс бара
		// Будет поправлено в сторе с назначением видео встречи клиентом
		this.registrationStore.changeStep(RegistrationSteps.notServicedCity);
		this.registrationStore.changeStep(RegistrationSteps.empty);
		this.chat.changeChatLoader(true);

		this.citiesListService.submitApplicationForCity({
			city: this.selectedCity.displayName,
			companyId: this.registrationStore.state.selectedCompany.companyId,
		}).subscribe({
			next: async (response) => {
				const selectNotServicedCityFlow = await this.meetingService.selectNotServicedCity(
					response.city,
					response.maxApplicationCount - response.applicationCount
				);

				this.analyticsService.sendAction(EventAction.city_is_off, EventCategory.user_lead);

				selectNotServicedCityFlow.subscribe();
			},
			error: response => {
				const errorType = response?.error?.type as SendApplicationErrors;

				switch (errorType) {
				case SendApplicationErrors.Failure:
					this.meetingAssignService.refusalToOpenAccount(this.registrationStore.state.selectedCompany);
					break;
				default:
					break;
				}

				this.chat.changeChatLoader(false);
			}
		});
	}

}
