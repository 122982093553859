import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { RegistrationStore } from './registration.store';
import { switchMap, tap } from 'rxjs/operators';
import { MessageType, Senders } from '../types/chat.types';
import { RegistrationSteps, AssignResponse } from '../types/registration.types';
import { ChatService } from './chat.service';
import { DateSelectionService } from './date-selection.service';
import { MeetingService } from './meeting.service';
import { SelectedCompany } from '../types/searchCompany.types';
import { RequisitesService, DeviceService, DateService, minTime, AnalyticsService, EventCategory, EventAction, LOCAL_STORAGE } from 'shared';
import { of } from 'rxjs';
import { DatePipe } from '@angular/common';
import { AssignError, MeetingAssignState } from '../types/meeting.types';
import { StateService } from 'shared';
import { getMessageTimeout } from '../enums/chat.enums';
import { RegistrationService } from './registration.service';
import { HoldingService } from './holding.service';

const initState = {
	meetingId: null,
};

@Injectable({
	providedIn: 'root',
})
export class MeetingAssignService extends StateService<MeetingAssignState> {
	selectedCompany: SelectedCompany;

	constructor(
		private chat: ChatService,
		private dateSelectionService: DateSelectionService,
		private meeting: MeetingService,
		private http: HttpClient,
		private store: RegistrationStore,
		private requisitesService: RequisitesService,
		private deviceService: DeviceService,
		private dateService: DateService,
		private datePipe: DatePipe,
		private analyticsService: AnalyticsService,
		private registrationService: RegistrationService,
		private holdingService: HoldingService,
		@Inject(LOCAL_STORAGE) readonly localStorage: Storage,
	) {
		super(initState);

		this.store.selectedCompany$.subscribe((selectedCompany) => {
			if (selectedCompany) {
				this.selectedCompany = selectedCompany;
			}
		});
	}

	resetState() {
		this.setState(initState);
	}

	assign(cachedMeeting = null): void {
		this.store.changeStep(RegistrationSteps.empty);

		let title;
		let subtitle;
		let view;

		if (cachedMeeting) {
			const selectedDate = this.dateSelectionService.getDateModel(
				this.dateService.stringToDate(cachedMeeting.startAt),
			);
			title = selectedDate.title;
			subtitle = selectedDate.subtitle;
			view = this.datePipe.transform(new Date(selectedDate.date), 'HH:mm');
		} else {
			title = this.dateSelectionService.state.selectedDate.title;
			subtitle = this.dateSelectionService.state.selectedDate.subtitle;
			view = this.dateSelectionService.state.selectedTime.view;
		}

		this.store.setChosenTime(true);
		this.chat.pushMessage({
			from: Senders.client,
			type: MessageType.meetingDate,
			editableStep: RegistrationSteps.datePicker,
			data: {
				date: title.toLowerCase(),
				weekday: subtitle.toLowerCase(),
				time: view,
			},
		});

		this.chat.postponeAction(() => {
			this.chat.changeChatLoader(true);
		}, getMessageTimeout(1)).subscribe();

		const selectedAddress = this.meeting.state.selectedFeature?.properties?.description;
		const organization = this.meeting.state.selectedFeature?.properties?.iconCaption;
		const address = [organization, selectedAddress].filter(Boolean).join(', ');
		const comment = this.meeting.state.comment ||
			this.localStorage?.getItem(`MeetingComment_${this.selectedCompany.companyId}`) ||
			'';

		const url = this.state.meetingId
			? '/api/meetingmanager-service/client/reassign'
			: '/api/meetingmanager-service/client/assign';
		const assignEventAction = this.state.meetingId
			? EventAction.meeting_rescheduled
			: EventAction.meeting_set_up;

		this.setState({
			meetingId: null,
		});

		const observable = cachedMeeting
			? of(cachedMeeting)
			: this.http.post<AssignResponse>(url, {
				companyId: this.selectedCompany.companyId,
				slotId: this.dateSelectionService.state.selectedTime.id,
				address,
				comment,
			  }).pipe(minTime(2000));

		observable
			.pipe(
				tap({
					next: (assignedMeeting) => {
						if (!cachedMeeting) {
							this.analyticsService.sendAction(assignEventAction, EventCategory.user_lead);
						}
						this.setState({
							meetingId: assignedMeeting.meetingId || assignedMeeting.id,
						});

						this.chat.postponeAction(() => {
							this.chat.changeChatLoader(false);
						}, getMessageTimeout(1)).subscribe();

						this.holdingService.getHoldingCompany();
					},
					error: (response) => {
						switch (response?.error?.type) {
						case AssignError.Failure:
							this.refusalToOpenAccount(this.selectedCompany);
							break;
						default:
							this.chat.changeChatLoader(false);
							this.chat.pushMessage({
								from: Senders.bank,
								type: MessageType.errorMessage,
								data: {
									errorMessage: response?.error?.message,
								},
							});
							this.store.changeStep(RegistrationSteps.datePicker);
							break;
						}
					},
				}),
			)
			.subscribe();
	}

	refusalToOpenAccount(selectedCompany: SelectedCompany) {
		let requisite;
		const inn = selectedCompany?.inn;
		const ogrn = selectedCompany?.ogrn;

		if (inn) {
			requisite = `ИНН ${inn}`;
		} else if (ogrn) {
			requisite = `ОГРН ${ogrn}`;
		}

		const messageSubject = [
			'Отказ в открытии счёта',
			requisite,
		]
			.filter(Boolean)
			.join(' ');

		const emailUrl = `mailto:help@blanc.ru?subject=${encodeURIComponent(messageSubject)}`;

		this.store.changeStep(RegistrationSteps.empty);
		this.chat.pushMessage({
			from: Senders.bank,
			type: MessageType.sadNews,
		});

		this.chat.postponeAction(() => {
			this.chat.changeChatLoader(true);
		}, getMessageTimeout(1)).subscribe();

		this.chat.pushMessage({
			from: Senders.bank,
			type: MessageType.openingAccountRefusal,
			data: { organization: selectedCompany?.shortname },
			timeout: getMessageTimeout(2),
		});
		this.chat.pushMessage({
			from: Senders.bank,
			type: MessageType.emailForDetails,
			data: { emailUrl },
			timeout: getMessageTimeout(3),
		});

		this.chat.postponeAction(() => {
			this.chat.changeChatLoader(false);
			this.store.setRegistrationBlock(true);
			this.store.changeStep(RegistrationSteps.accountIsAlreadyOpen);
		}, getMessageTimeout(3)).subscribe();
	}
}
