import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {Observable, of, switchMap} from 'rxjs';
import {tap} from 'rxjs/operators';
import {
	MaskService,
	StateService,
	DeviceService,
	minTime,
	AnalyticsService,
	EventAction,
	EventCategory,
	ExponeaTypes,
	ExponeaActionTypes,
	ExponeaActionStatus,
	FeatureTogglingService,
	FEATURES, SecurityService,
	LOCAL_STORAGE,
} from 'shared';
import { MessageType, Senders } from '../types/chat.types';
import {
	AuthState,
	GetSmsResponse,
	OrganizationalLegalForm,
	RegistrationSource,
	RegistrationSteps,
} from '../types/registration.types';
import { ChatService } from './chat.service';
import { RegistrationService } from './registration.service';
import { RegistrationStore } from './registration.store';
import {ActivatedRoute} from '@angular/router';
import { RegistrationPartner } from '../enums/RegistrationPartner';

const initState: AuthState = {
	confirmToken: null,
	lastNumberEntered: null,
	selectedOpf: null,
	opfSelectionStep: null,
};

@Injectable({
	providedIn: 'root',
})
export class AuthService extends StateService<AuthState> {
	lastNumberEntered$ = this.select((state) => state.lastNumberEntered);

	constructor(
		private chat: ChatService,
		private http: HttpClient,
		private route: ActivatedRoute,
		private registrationService: RegistrationService,
		private store: RegistrationStore,
		private mask: MaskService,
		private deviceService: DeviceService,
		private analyticsService: AnalyticsService,
		private featureToggling: FeatureTogglingService,
		private securityService: SecurityService,
		@Inject(LOCAL_STORAGE) readonly localStorage: Storage,
	) {
		super(initState);
	}

	sendOpfToChat(selectedOpf: OrganizationalLegalForm, canEditOpf: boolean) {
		const observable = selectedOpf ? this.featureToggling.checkFeatureActivity('accounts-service', FEATURES.OpenAccountsInBeginningOfRegistration) : of(false);

		observable
			.subscribe(opfSelectionIsAvailable => {
				if (opfSelectionIsAvailable && selectedOpf) {
					let messageType: MessageType;

					if (selectedOpf === OrganizationalLegalForm.IndividualEntrepreneur) {
						messageType = MessageType.needAccountForIndividualEntrepreneur;
					} else if (selectedOpf === OrganizationalLegalForm.LimitedLiabilityCompany) {
						messageType = MessageType.needAccountForLLC;
					}

					this.chat.pushMessage({
						from: Senders.client,
						type: messageType,
						editableStep: canEditOpf ? RegistrationSteps.opfSelection : null,
					});
				}
			});
	}

	sendPhoneToChat(phone: string) {
		this.chat.pushMessage({
			from: Senders.client,
			type: MessageType.defaultMessageWithCustomContent,
			editableStep: RegistrationSteps.enterPhone,
			data: {
				content: this.mask.parsePhone(phone),
			},
		});
	}

	updateLastEnteredNumber(phone: string) {
		this.setState({ lastNumberEntered: phone });
	}

	resetState() {
		this.setState(initState);
	}

	updateSelectedOpf(selectedOpf: OrganizationalLegalForm) {
		this.setState({ selectedOpf });
	}

	updateOpfSelectionStep(opfSelectionStep: RegistrationSteps) {
		this.setState({ opfSelectionStep });
	}

	updateSmsState({phone, timeout = 60000, token}) {
		const phoneNumber = phone.replace(/\D/g, '');

		if (!phoneNumber) {
			return;
		}

		const lifetime = this.getSmsLifetime();
		const tokens = this.getSmsTokens();

		this.localStorage?.setItem('registrationSmsLifetime', JSON.stringify({
			...lifetime,
			[phoneNumber]: new Date(new Date().getTime() + timeout).toString(),
		}));

		this.localStorage?.setItem('registrationSmsTokens', JSON.stringify({
			...tokens,
			[phoneNumber]: token,
		}));
	}

	deleteSmsState(phone: string) {
		const phoneNumber = phone.replace(/\D/g, '');

		if (!phoneNumber) {
			return;
		}

		const lifetime = this.getSmsLifetime();
		const tokens = this.getSmsTokens();

		delete lifetime[phoneNumber];
		delete tokens[phoneNumber];

		this.localStorage?.setItem('registrationSmsLifetime', JSON.stringify(lifetime));
		this.localStorage?.setItem('registrationSmsTokens', JSON.stringify(tokens));
	}

	deleteOutdatedSms() {
		const lifetime = this.getSmsLifetime();
		const tokens = this.getSmsTokens();

		Object.entries(lifetime).forEach(([phoneNumber, expiresDate]: [string, string]) => {
			const isExpired = new Date().getTime() - new Date(expiresDate).getTime() >= 0;
			if (isExpired) {
				delete lifetime[phoneNumber];
				delete tokens[phoneNumber];
			}
		});

		this.localStorage?.setItem('registrationSmsLifetime', JSON.stringify(lifetime));
		this.localStorage?.setItem('registrationSmsTokens', JSON.stringify(tokens));
	}

	getSmsTokens(): { [key: string]: string } {
		const tokens = this.localStorage?.getItem('registrationSmsTokens');
		return tokens ? JSON.parse(tokens) : {};
	}

	getSmsLifetime(): { [key: string]: string } {
		const lifetime = this.localStorage?.getItem('registrationSmsLifetime');
		return lifetime ? JSON.parse(lifetime) : {};
	}

	checkSmsActivity(phone: string): boolean {
		const phoneNumber = phone.replace(/\D/g, '');

		const lifetime = this.localStorage?.getItem('registrationSmsLifetime');
		const lifetimeState = lifetime ? JSON.parse(lifetime) : {};

		return lifetimeState.hasOwnProperty(phoneNumber)
			&& new Date().getTime() - new Date(lifetimeState[phoneNumber]).getTime() < 0;
	}

	login({ phone, again = false, showPhone = true, selectedOpf = null, canEditOpf = false }): Observable<GetSmsResponse> {
		this.deleteOutdatedSms();

		const rawPhone: string = phone.replace(/\D/g, '');
		const smsIsActive = this.checkSmsActivity(rawPhone);
		const params = this.route.snapshot.queryParams;
		const { isAgentLanding, isLanding, isInternalAgentLanding, partner } = this.registrationService.state;

		this.chat.changeChatLoader(true);

		if (!smsIsActive) {
			this.setState({ confirmToken: null });
		}

		if (selectedOpf) {
			this.sendOpfToChat(selectedOpf, canEditOpf);
		}

		if (showPhone) {
			this.sendPhoneToChat(phone);
			this.store.changeStep(RegistrationSteps.empty, false);
		}

		this.setState({
			selectedOpf,
		});

		let registrationSource;

		if (partner === RegistrationPartner.avito) {
			registrationSource = RegistrationSource.Avito;
		} else if (isAgentLanding) {
			registrationSource = RegistrationSource.Agent;
		} else if (isLanding) {
			registrationSource = RegistrationSource.Landing;
		} else if (isInternalAgentLanding) {
			registrationSource = RegistrationSource.Sales;
		}

		const observable = smsIsActive
			? of({
				confirmToken: this.getSmsTokens()[rawPhone],
				timeoutMs: new Date(this.getSmsLifetime()[rawPhone]).getTime() - new Date().getTime(),
			}) :
			this.securityService.getGuardHashV1(rawPhone)
				.pipe(
					switchMap((hash: string) =>
						this.http.post<GetSmsResponse>('/api/sso/v1/entrance/login',
							{ phoneNumber: rawPhone, hash },
							{ headers: new HttpHeaders({'X-Registration-Source': registrationSource}) }
						).pipe(minTime(2000))
					)
				);

		return observable.pipe(
			tap(
				(response: GetSmsResponse) => {
					if (!smsIsActive) {
						this.updateSmsState({
							phone: rawPhone,
							timeout: response.timeoutMs,
							token: response.confirmToken,
						});
					}

					this.analyticsService.sendAction(EventAction.phone_success, EventCategory.user_lead);
					this.analyticsService.exponeaTrack(ExponeaTypes.registration, {
						action_type: ExponeaActionTypes.phone,
						status: ExponeaActionStatus.success,
						phone: rawPhone,
						device_type: 'desktop',
						host: this.deviceService.isServer ? null : window.location.host,
						path: this.deviceService.isServer ? null : window.location.pathname,
						product: 'rko',
						partner: params.partner
					});
					this.updateLastEnteredNumber(rawPhone);
					this.setState({ confirmToken: response.confirmToken });

					this.registrationService.startSmsStep(again, isInternalAgentLanding);
				},
				(response) => {
					this.chat.changeChatLoader(false);

					if (response.error?.type === 'ServiceUnavailable' || response.status === 500) {
						this.chat.pushMessage({
							from: Senders.bank,
							type: MessageType.cantSendConfirmationCode,
						});

						this.chat.pushMessage({
							from: Senders.bank,
							type: MessageType.bankEmployeeCall,
						});

						this.store.changeStep(RegistrationSteps.empty);

						return;
					}

					if (response.error?.type === 'Validation') {
						this.chat.pushMessage({
							from: Senders.bank,
							type: MessageType.enterNumberRegisteredInRussia,
						});

						this.store.changeStep(RegistrationSteps.enterPhone);
						return;
					}

					if (response.error?.type === 'AccountIsBlocked') {
						// блокирующий фактор при регистрации, установка цвета прогресс бара
						this.store.setRegistrationBlock(true);
					}

					this.chat.pushMessage({
						from: Senders.bank,
						type: MessageType.errorMessage,
						data: {
							errorMessage: response?.error?.message ||
							'Почему-то не&nbsp;получается отправить код подтверждения. Повтори попытку позже',
						},
					});
				}
			),
		);
	}

	goToOpfSelection(phone: string) {
		this.sendPhoneToChat(phone);
		this.setState({
			selectedOpf: null,
		});

		this.store.changeStep(RegistrationSteps.opfSelection);
		this.chat.pushMessage({
			from: Senders.bank,
			type: MessageType.selectOpf,
		});
	}

}
