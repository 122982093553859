import {
	Component,
	ChangeDetectionStrategy,
	Output,
	EventEmitter,
	ElementRef,
} from '@angular/core';
import { OrganizationalLegalForm } from '../../../types/registration.types';

@Component({
	selector: 'b-registration-opf-selection',
	templateUrl: './opf-selection.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpfSelectionComponent {

	@Output() selectOpf: EventEmitter<any> = new EventEmitter<any>();

	opf = OrganizationalLegalForm;

	constructor(
		public elementRef: ElementRef,
	) {}

}
