import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef, HostListener,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	ViewChild, ViewChildren,
	ViewEncapsulation,
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {
	AnalyticsService,
	DeviceService,
	EnvironmentConfig, EventCategory,
	ExponeaTypes,
	FEATURES,
	FeatureTogglingService,
	LOCAL_STORAGE,
	MaskService,
	OverlayService2,
	ScriptLazyLoadingService,
	ScrollService,
	ValidatorsService,
	WINDOW,
} from 'shared';
import {BehaviorSubject, concatMap, filter, fromEvent, Subscription, take} from 'rxjs';
import {Guid} from 'guid-typescript';
import {RegistrationService} from './services/registration.service';
import {ChatService} from './services/chat.service';
import {Message, MessageType, Senders} from './types/chat.types';
import {OrganizationalLegalForm, RegistrationSteps} from './types/registration.types';
import {MeetingService} from './services/meeting.service';
import {DateSelectionService} from './services/date-selection.service';
import {SessionService} from './services/session.service';
import {AuthService} from './services/auth.service';
import {RegistrationStore} from './services/registration.store';
import {animate, AnimationEvent, keyframes, style, transition, trigger} from '@angular/animations';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {LottieAnimationsService} from './services/lottie-animations.service';
import {VerifyService} from './services/verify.service';
import {SmsBlockingService} from './services/sms-blocking.service';
import {AddressesService} from './services/addresses.service';
import {CitiesListService} from './services/cities-list.service';
import {MeetingAssignService} from './services/meeting-assign.service';
import {animationTimings, getMessageTimeout} from './enums/chat.enums';
import {RegistrationPartner} from './enums/RegistrationPartner';
import {ActivatedRoute} from '@angular/router';
import {DOCUMENT} from '@angular/common';


declare const gib: {
	IS_AUTHORIZED: true;
	IS_GUEST: false;
	init(settings: any): void;
	setAuthStatus(status: boolean): void;
	setLogin(identifier: string): void;
	setLoginAndEncrypt(identifier: string, options: boolean): void;
	setSessionID(sessionId: string): void;
	setAttribute(attribute: string, data: any, options: { persistent: boolean, encryption?: string })
};

@Component({
	selector: '[b-registration-component]',
	templateUrl: './registration.component.html',
	styleUrls: ['./registration.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations: [
		trigger('lifecycleTrigger', [
			transition(':enter', [
				style({opacity: 0, height: 0, transform: 'translateY(100%)'}),
				animate(`${animationTimings.init}ms ease-out`, style({
					opacity: 1,
					height: '*',
					transform: 'translateY(0)'
				})),
			]),
			transition(':leave', [
				animate(`${animationTimings.destroy}ms ease-out`, style({opacity: 0, height: 0,})),
			]),
		]),
		trigger('fadeAnimation', [
			transition(':enter', [
				style({opacity: 0, height: 0,}),
				animate('500ms ease-out', style({opacity: 1, height: '*',})),
			]),
			transition(':leave', [
				animate('300ms ease-out', style({opacity: 0, height: 0,})),
			]),
		]),
		trigger('messageTrigger', [
			transition(':enter', [
				style({opacity: 0, height: 0, transform: 'translateY(100%)'}),
				animate(`${animationTimings.init}ms ease-out`, style({
					opacity: 1,
					transform: 'translateY(0)',
					height: '*',
				})),
			]),
			transition(':leave', [
				animate(`${animationTimings.destroy}ms ease-out`, style({maxHeight: 0, opacity: 0, height: 0,})),
			]),
		]),
	],
})
export class RegistrationComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() registrationSection: ElementRef;
	@Input() partner: RegistrationPartner | null = null;
	@Input() isAsideVisible: boolean = true;
	@Input() scrollableChatContainer: boolean = false;
	@Input() chatClass = '';
	/** Список идентификаторов опций, выбранных в лендинге */
	@Input() selectedOptionsIds: string[] | null = null;
	/** Идентификатор тарифа, выбранного в лендинге */
	@Input() selectedPackage: string | null = null;
	/** Для реги в шторке */
	@Input() isOverlay: boolean = false;
	/** Для реги в шторке совместно обычной */
	@Input() withOverlay: boolean = false;

	@ViewChild('stepContainer', {static: false}) stepContainer: any;
	@ViewChild('chatContainer', {static: false}) chatContainer: ElementRef;

	phoneForm: UntypedFormGroup;
	registrationSteps = RegistrationSteps;
	senders = Senders;

	blockingLoader: boolean;
	authLoader: boolean;
	chatLoader: boolean;
	viewportHeight: number;
	topPartIsVisible: boolean;
	bottomPartIsVisible: boolean;
	sectionSmallerThanViewport: boolean;
	numberIsEntered = false;
	enteredPhone: string = null;
	registrationPartner = RegistrationPartner;
	isGIBScriptEnabled = false;

	chatLoaderMessage: Message = {
		from: Senders.bank,
		type: MessageType.waiting,
		id: Guid.create().toString(),
	};

	blockingLoaderMessage: Message = {
		from: Senders.bank,
		type: MessageType.waitSecond,
		id: Guid.create().toString(),
	};


	stepClass = {
		sticky: 'chat__sticky',
		stickyScroll: 'chat__sticky--scroll',
		stickyTransform: 'chat__sticky--transform',
	};
	stepNgClass = {};
	autocompleteStepsNgClass = {};

	subscriptions: Subscription = new Subscription();
	step: RegistrationSteps;
	messages: Message[];
	mutationObserver: MutationObserver;

	isLanding: boolean;
	isAgentLanding: boolean;
	opfSelectionIsAvailable: boolean = false;
	animations = new BehaviorSubject<AnimationEvent[]>([]);
	animations$ = this.animations.asObservable();

	private isComponentInView = false;

	constructor(
		public chat: ChatService,
		public registrationService: RegistrationService,
		private fb: UntypedFormBuilder,
		private validatorsService: ValidatorsService,
		private meeting: MeetingService,
		private dateSelection: DateSelectionService,
		public store: RegistrationStore,
		public sessionService: SessionService,
		private authService: AuthService,
		private changeDetector: ChangeDetectorRef,
		private deviceService: DeviceService,
		@Inject('env') private env: EnvironmentConfig,
		private scrollService: ScrollService,
		public lottieAnimationsService: LottieAnimationsService,
		private verifyService: VerifyService,
		private smsBlockingService: SmsBlockingService,
		private featureToggling: FeatureTogglingService,
		private mask: MaskService,
		private addressesService: AddressesService,
		private route: ActivatedRoute,
		private citiesListService: CitiesListService,
		private meetingAssignService: MeetingAssignService,
		@Inject(DOCUMENT) private document: Document,
		@Inject(LOCAL_STORAGE) readonly localStorage: Storage,
		private lazyLoadingService: ScriptLazyLoadingService,
		private analyticsService: AnalyticsService,
		private elementRef: ElementRef,
		private overlayService: OverlayService2,
		@Inject(WINDOW) private _window: Window,
	) {}

	@HostListener('window:scroll')
	onWindowScroll() {
		if (!this.isComponentInView && this.scrollService.isInViewport(this.elementRef.nativeElement)) {
			this.isComponentInView = true;
			this.analyticsService.sendAction('reg_form_view', EventCategory.user_click);
		}
	}

	ngOnInit() {
		this.subscriptions.add(
			this.registrationService.companySelectedEvent.asObservable().subscribe({
				next: companyId => this.registrationService.sendTariffAndOptions({
					companyId,
					optionIds: this.selectedOptionsIds,
					packageName: this.selectedPackage,
				})
			})
		);

		this.subscriptions.add(
			this.registrationService.isGIBScriptEnabled$
				.pipe(
					filter(Boolean),
					distinctUntilChanged(),
					concatMap(() => this.lazyLoadingService.loadScript('/gib.js'))
				)
				.subscribe(() => {
					gib.init({
						cid: this.env.ibGroupConfig?.cid,
						backUrl: '/api/fl',
						gafUrl: '//ru.id.group-ib.com/id.html',
						forceFirstAlive: true,
					});
					this.isGIBScriptEnabled = true;
				})
		);

		this.subscriptions.add(
			this.animations$
				.pipe(debounceTime(animationTimings.scrollDebounce))
				.subscribe(() => {
					const scrollContainer = this.scrollableChatContainer ?
						(this.isOverlay ? this.document.querySelector('.simplebar-content-wrapper') : this.chatContainer.nativeElement) :
						this.registrationSection.nativeElement;

					this.chat.scrollRegistrationSection(
						scrollContainer,
						this.scrollableChatContainer ? scrollContainer : null,
						this.scrollableChatContainer ? scrollContainer.scrollHeight : null,
					);
				})
		);

		this.subscriptions.add(
			this.store.messages$.subscribe(messages => {
				this.messages = messages;
				this.changeDetector.detectChanges();
			})
		);

		this.subscriptions.add(
			this.store.blockingLoader$.subscribe((blockingLoader) => {
				this.blockingLoader = blockingLoader;
				this.changeDetector.detectChanges();
			}),
		);

		this.subscriptions.add(
			this.chat.chatLoader$.subscribe(chatLoader => {
				this.chatLoader = chatLoader;
				this.changeDetector.detectChanges();
			})
		);

		this.subscriptions.add(
			this.chat.authLoader$.subscribe(authLoader => {
				this.authLoader = authLoader;
				this.changeDetector.detectChanges();
			}),
		);

		if (!this.deviceService.isServer) {
			this.subscriptions.add(
				this.deviceService.viewportHeight$.subscribe((viewportHeight) => {
					this.viewportHeight = viewportHeight;
					this.calculateScroll();
				}),
			);

			const scrollContainer = this.scrollableChatContainer ?
				(this.isOverlay ? this.document.querySelector('.simplebar-content-wrapper') : this.chatContainer.nativeElement) :
				this._window;

			this.subscriptions.add(
				fromEvent(scrollContainer, 'scroll')
					.pipe(this.isOverlay ? debounceTime(100) : debounceTime(20))
					.subscribe(() => this.calculateScroll())
			);
		}

		this.subscriptions.add(
			this.authService.lastNumberEntered$.subscribe(
				(phone) => (this.numberIsEntered = Boolean(phone)),
			),
		);
		this.subscriptions.add(this.store.step$.subscribe(step => {
			this.step = step;

			if (step === RegistrationSteps.start) {
				this.makePhoneForm();
			}

			this.changeDetector.detectChanges();
			if (step === RegistrationSteps.last && this.isGIBScriptEnabled) {
				const userId = this.sessionService.state.session.tokenPayload?.sub;
				gib.setAuthStatus(gib.IS_AUTHORIZED);
				gib.setLogin(userId);
			}
		}));

		this.subscriptions.add(
			this.registrationService.isLanding$.subscribe(isLanding => this.isLanding = isLanding)
		);

		this.subscriptions.add(
			this.registrationService.isAgentLanding.subscribe(isAgentLanding => this.isAgentLanding = isAgentLanding)
		);

		this.registrationService.initRegistration(this.env, this.partner);

		this.store.changeBlockingLoader(true);

		// в агентских лендингах фича всегда выключена:
		if (this.isAgentLanding || this.registrationService.state.isInternalAgentLanding) {
			this.featureToggling.setFeatureActivity(FEATURES.OpenAccountsInBeginningOfRegistration, false);
		}

		this.subscriptions.add(
			this.featureToggling.checkFeatureActivity('accounts-service', FEATURES.OpenAccountsInBeginningOfRegistration).subscribe({
				next: featureIsActive => {
					this.store.changeBlockingLoader(false);
					this.opfSelectionIsAvailable = featureIsActive;
					this.startRegistration();
					this.changeDetector.detectChanges();
				},
				error: () => {
					this.store.changeBlockingLoader(false);
					this.opfSelectionIsAvailable = false;
					this.startRegistration();
					this.changeDetector.detectChanges();
				},
			})
		);
	}

	ngAfterViewInit() {
		if(!this.deviceService.isServer) {
			this.mutationObserver = new MutationObserver((record: MutationRecord[]) => {
				record.forEach((mutation) => {
					if (mutation.addedNodes?.item(0)?.textContent === 'Скопировано') {
						document.body.removeChild(mutation.addedNodes?.item(0));
					}
				});
			});

			this.mutationObserver.observe(document.body, {
				childList: true,
				attributes: false,
				characterData: true,
				subtree: false
			});
		}
	}

	ngOnDestroy() {
		this.registrationService.resetState();
		this.meeting.resetState();
		this.dateSelection.resetState();
		this.chat.resetState();
		this.lottieAnimationsService.resetState();
		this.store.resetState();
		this.sessionService.resetState();
		this.authService.resetState();
		this.smsBlockingService.resetState();
		this.addressesService.resetState();
		this.citiesListService.resetState();
		this.meetingAssignService.resetState();

		this.registrationService.stopRegistration();
		this.subscriptions?.unsubscribe();

		this.chat.unsubscribeFromVisualViewport();

		this.mutationObserver.disconnect();
	}

	startRegistration() {
		if (!this.isOverlay) {
			this.chat.pushMessage({
				from: Senders.bank,
				type: MessageType.hi,
				timeout: getMessageTimeout(1),
			});

			this.chat.pushMessage({
				from: Senders.bank,
				type: MessageType.whatDoYouWant,
				timeout: getMessageTimeout(2),
			});

			if (localStorage.getItem('selectNormalReg')) {
				this.chat.pushMessage({
					from: Senders.client,
					type: MessageType.openAccountBuisnessWant,
					timeout: getMessageTimeout(3),
				});
				this.normalRegistration();
			} else {
				this.chat.postponeAction(() => {
					this.store.changeStep(RegistrationSteps.whereRegistration);
				}, getMessageTimeout(3)).subscribe();
			}
		} else {
			this.normalRegistration();
		}
	}

	normalRegistration() {
		this.sessionService.checkSession().pipe(take(1)).subscribe(
			res => {
				this.chat.postponeAction(() => {
					this.chat.changeChatLoader(true);
				}, getMessageTimeout(4)).subscribe();
				if (!res) {
					if (this.isOverlay) {
						this.chat.pushMessage({
							from: Senders.bank,
							type: MessageType.hi,
							timeout: getMessageTimeout(1),
						});
					}
					this.chat.pushMessage({
						from: Senders.bank,
						type: this.opfSelectionIsAvailable ? MessageType.enterPhoneAndOpf : MessageType.enterPhoneNumber,
						timeout: getMessageTimeout(2),
					});

					this.chat.pushMessage({
						from: Senders.bank,
						type: MessageType.conditions,
						timeout: getMessageTimeout(3),
					});

					this.makePhoneForm();
				} else {
					this.store.changeStep(RegistrationSteps.empty);
					this.chat.postponeAction(() => {
						this.chat.changeChatLoader(false);
					}, getMessageTimeout(1)).subscribe();
				}
			}
		);
	}

	makePhoneForm() {
		this.chat.postponeAction(() => {
			this.phoneForm = this.fb.group({
				phone: ['', [Validators.required, this.validatorsService.fieldLength(15)]],
			});

			this.chat.changeChatLoader(false);
			this.changeDetector.detectChanges();
		}, getMessageTimeout(4))
			.subscribe({
				next: () => {
					const queryParams = this.route.snapshot.queryParams;
					if (queryParams['phone']?.length === 10) {
						this.phoneForm.patchValue({phone: this.mask.parsePhone(queryParams['phone']).trim()});
					}
					this.startMonitoringPhoneControlChanges();
				}
			});
	}

	calculateScroll() {
		if (this.isOverlay) {
			this.stepNgClass[this.stepClass.sticky] = true;
			return;
		}

		const {viewportHeight} = this.scrollService.getViewportSize();
		const viewportHeightIsMore560 = viewportHeight >= 560;

		const registrationSection = this.registrationSection.nativeElement;
		const chatContainer = this.isOverlay ? this.document.querySelector('.simplebar-content-wrapper') : this.chatContainer.nativeElement;
		const registrationSectionRect = registrationSection.getBoundingClientRect();
		const chatContainerRect = chatContainer.getBoundingClientRect();

		this.topPartIsVisible = this.scrollableChatContainer ?
			chatContainer.scrollTop <= 0 :
			viewportHeight - registrationSectionRect.y >= 0 && registrationSectionRect.y >= 0;

		this.bottomPartIsVisible = this.scrollableChatContainer ?
			(chatContainer.scrollHeight - chatContainer.clientHeight) <= chatContainer.scrollTop :
			viewportHeight - chatContainerRect.bottom > 0;

		this.sectionSmallerThanViewport = registrationSectionRect.height < viewportHeight;

		const stepElement = this.stepContainer?.elementRef?.nativeElement;

		this.stepNgClass[this.stepClass.sticky] = !this.sectionSmallerThanViewport;
		this.stepNgClass[this.stepClass.stickyScroll] = !this.bottomPartIsVisible && !this.sectionSmallerThanViewport;
		this.stepNgClass[this.stepClass.stickyTransform] = this.isLanding &&
			this.topPartIsVisible &&
			!this.bottomPartIsVisible &&
			!this.sectionSmallerThanViewport &&
			(!stepElement || stepElement.getBoundingClientRect().height <= 300);

		this.autocompleteStepsNgClass[this.stepClass.sticky] = this.stepNgClass[this.stepClass.sticky] && viewportHeightIsMore560;
		this.autocompleteStepsNgClass[this.stepClass.stickyScroll] = this.stepNgClass[this.stepClass.stickyScroll] && viewportHeightIsMore560;
		this.autocompleteStepsNgClass[this.stepClass.stickyTransform] = this.stepNgClass[this.stepClass.stickyTransform] && viewportHeightIsMore560;

		this.changeDetector.detectChanges();
	}

	get canSendPhone() {
		return !this.phoneControl?.errors?.required && !this.phoneControl?.errors?.fieldLength;
	}

	get phoneControl() {
		return this.phoneForm?.controls?.phone;
	}

	trackByMessages(index: number, item: Message) {
		return item.id;
	}

	selectOpf(selectedOpf: OrganizationalLegalForm) {
		const phone = this.enteredPhone || this.authService.state.lastNumberEntered;
		const rawPhone = phone.replace(/\D/g, '');

		this.localStorage?.setItem(`selectedOpf_${rawPhone}`, selectedOpf);
		this.localStorage?.setItem(`opfSelectionStep_${rawPhone}`, RegistrationSteps.opfSelection);
		this.login({
			phone: this.mask.parsePhone(phone),
			selectedOpf,
			canEditOpf: true,
			showPhone: false,
		});
	}

	submitPhone(selectedOpf: OrganizationalLegalForm): void {
		if (this.phoneControl?.errors?.fieldLength) {
			return;
		}

		this.enteredPhone = `+7 ${this.phoneControl.value}`;
		this.phoneForm.reset();
		if (!this.opfSelectionIsAvailable) {
			this.localStorage?.setItem('selectNormalReg', JSON.stringify(true));
			this.login({phone: this.enteredPhone});
			return;
		}

		const rawPhone = this.enteredPhone.replace(/\D/g, '');
		const opfKey = `selectedOpf_${rawPhone}`;

		if (selectedOpf) {
			this.localStorage?.setItem(opfKey, selectedOpf);
			this.localStorage?.setItem(`opfSelectionStep_${rawPhone}`, RegistrationSteps.enterPhone);
			this.authService.updateOpfSelectionStep(RegistrationSteps.enterPhone);
			this.login({phone: this.enteredPhone, selectedOpf});
		} else {
			this.localStorage?.removeItem(opfKey);
			this.localStorage?.removeItem(`opfSelectionStep_${rawPhone}`);
			this.authService.updateOpfSelectionStep(null);
			this.authService.goToOpfSelection(this.enteredPhone);
			this.phoneForm.reset();
		}
	}

	login({phone = this.enteredPhone, again = false, showPhone = true, selectedOpf = null, canEditOpf = false}) {
		this.subscriptions.add(
			this.authService.login({phone, again, showPhone, selectedOpf, canEditOpf}).subscribe(
				response => {
					this.phoneForm.reset();

					localStorage.setItem('phoneNumber', this.enteredPhone?.replace(/\D/g, ''));

					if (this.registrationService.state.isInternalAgentLanding) {
						this.verifyService.agentSecret({
							phoneNumber: phone.replace(/\D/g, ''),
							confirmToken: response.confirmToken,
						}).subscribe({
							error: () => {
								this.chat.changeChatLoader(false);
								this.store.changeStep(RegistrationSteps.enterSms);
								this.smsBlockingService.startSmsBlockingFlow();
							}
						});
					}
				},
				() => this.phoneForm.reset(),
			),
		);
	}

	start(event: AnimationEvent) {
		const isDestroyAnimationStart = event.toState === 'void' && event.phaseName === 'start';

		if (!this.isOverlay && isDestroyAnimationStart) {
			Object.values((event.element as HTMLElement).children).forEach(child => child.classList.add('pos-absolute'));
		}

		const isInitAnimationStart = event.fromState === 'void' && event.phaseName === 'start';

		if (isInitAnimationStart) {
			this.animations.next([...this.animations.getValue(), event]);
		}
	}

	done(event: AnimationEvent) {
		const isDestroyAnimationEnd = event.toState === 'void' && event.phaseName === 'done';

		if (isDestroyAnimationEnd) {
			this.animations.next([...this.animations.getValue(), event]);
		}
	}

	registrationAccount(): void {
		this.analyticsService.sendAction('open_account_in_form', EventCategory.user_click);

		// this.analyticsService.exponeaTrack(ExponeaTypes.registration, {
		// 	action_type: 'open account click button',
		// });

		this.chat.pushMessage({
			from: Senders.client,
			type: MessageType.openAccountBuisnessWant,
		});

		this.normalRegistration();
	}

	registrationBusiness(): void {
		this.analyticsService.sendAction('register_business', EventCategory.user_click);

		// this.analyticsService.exponeaTrack(ExponeaTypes.registration, {
		// 	action_type: 'open link registration business',
		// });

		this._window.open(`${this._window.location.origin}/reg`, '_blank');
	}

	openRegistrationOverlay() {
		this.overlayService.open({
			overlayId: 'regOverlay'
		});

		this.subscriptions?.unsubscribe();
		this.step = this.registrationSteps.whereRegistration;
	}

	private startMonitoringPhoneControlChanges(): void {
		this.subscriptions.add(this.phoneControl.valueChanges.subscribe((data) => {
			this.store.setPhoneValue(data, this.phoneControl.valid);
		}));
	}
}
