import { Component, Input } from '@angular/core';
import { MeetingService } from '../../../..//services/meeting.service';

@Component({
	selector: 'b-registration-address-list-item',
	templateUrl: './address-list-item.component.html',
})
export class AddressListItemComponent {
	@Input() title: string;
	@Input() address?: string;
	@Input() organization?: string;
	@Input() time: string;
	@Input() iconUrl: string;
	@Input() point;
	@Input() isHint?: boolean;

	constructor(private meeting: MeetingService) {}

	selectPoint() {
		const feature = this.isHint ? this.meeting.getFeatureFromHint(this.point) : this.point;

		this.meeting.selectPoint({ feature }).subscribe();
	}
}
