import { NearestDates } from './NearestDates';
import { Meeting } from './searchCompany.types';
import { Zone } from './Zone';

export enum MapView {
	default = 'default',
	withCafesList = 'withCafesList',
	outOfZoneAddress = 'outOfZoneAddress',
}

export enum ServiceType {
	/** Адрес не обслуживается. Нельзя назначить встречу */
	NotServiced = 'NotServiced',
	/** Точка с адресом обслуживается. Можно назначить встречу */
	PointServiced = 'PointServiced',
	/** Точка с адресом не обслуживается, но в городе из адреса можно назначить встречу в другом месте */
	CityServiced = 'CityServiced',
	/** Город не обслуживается, но доступен для заявки на встречу */
	CityNotServiced = 'CityNotServiced'
}

export interface Hint {
	coordinates: {
		latitude: number;
		longitude: number;
	};
	city: string;
	address: string;
	serviceType: ServiceType;
	containsHouse: boolean;
	geoZone: string;
	organization?: string;
	ogrCategory?: string;
}

export interface Geometry {
	type: string;
	coordinates: any[];
}

export interface Feature {
	type: string;
	id: string;
	geometry: Geometry;
	properties?: any;
	options?: any;
}

export interface GeoJsonResponse {
	type: string;
	features: Feature[];
}

export interface GeoJson {
	points: {
		type: string;
		features: Feature[];
	};
	polygons: {
		type: string;
		features: Feature[];
	};
}

export interface AddressesState {
	addressHints: Hint[];
	selectedHint: Hint | null;
	addressesLoader: boolean;
}

export interface MeetingState {
	map: any;
	geoJson: GeoJson | null;
	selectedAddressMark: any;
	canHoldMeeting: boolean;
	meetingPlacesObject: any;
	mapView: MapView;
	selectedPolygon: Feature | null;
	selectedFeature: Feature | null;
	comment: string | null;
	meeting: Meeting;
	officeZones: Zone[];
	nearestDates: NearestDates;
	selectedOffice: Zone;
	departureFlow: boolean;
}
