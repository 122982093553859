import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SelectedCompany, Company, SearchCompaniesRequest } from '../types/searchCompany.types';
import {
	StateService,
	ScriptLazyLoadingService,
	minTime,
	AnalyticsService,
	EventAction,
	EventCategory,
	DeviceService,
	EnvironmentConfig, SharedService
} from 'shared';
import { Message, MessageType, Senders } from '../types/chat.types';
import { RegistrationState, RegistrationSteps } from '../types/registration.types';
import { ChatService } from './chat.service';
import { RegistrationStore } from './registration.store';
import { SmsBlockingService } from './sms-blocking.service';
import { catchError, forkJoin, from, Observable, of, Subject, take } from 'rxjs';
import { CitiesListService } from './cities-list.service';
import { AddressesService } from './addresses.service';
import { MeetingService } from './meeting.service';
import { filter, first, switchMap, tap } from 'rxjs/operators';
import { getMessageTimeout } from '../enums/chat.enums';
import { RegistrationPartner } from '../enums/RegistrationPartner';
import { DOCUMENT } from '@angular/common';

const initState: RegistrationState = {
	environment: {},
	registrationProcessIsActive: false,
	isLanding: false,
	isAgentLanding: false,
	isInternalAgentLanding: false,
	partner: null,
	isAvitoRegistration: false,
};

@Injectable({
	providedIn: 'root',
})
export class RegistrationService extends StateService<RegistrationState> {
	isLanding$ = this.select((state) => state.isLanding);
	isAgentLanding = this.select((state => state.isAgentLanding));
	isGIBScriptEnabled$ = this.select(({ isLanding, isAvitoRegistration }) =>
		this.sharedService.isUrlOfProd && this.env.ibGroupConfig?.enabled && (isLanding || isAvitoRegistration)
	);
	public readonly companySelectedEvent = new Subject<string>();

	constructor(
		private http: HttpClient,
		private chat: ChatService,
		private smsBlockingService: SmsBlockingService,
		private store: RegistrationStore,
		private lazyScript: ScriptLazyLoadingService,
		private citiesListService: CitiesListService,
		private addressesService: AddressesService,
		private meetingService: MeetingService,
		private analyticsService: AnalyticsService,
		private deviceService: DeviceService,
		@Inject('env') private env: EnvironmentConfig,
		private sharedService: SharedService,
		@Inject(DOCUMENT) private _document: Document,
	) {
		super(initState);
	}

	searchCompanies(companyInfo: SearchCompaniesRequest): Observable<Company[]> {
		return this.http.post<Company[]>('/api/dadata-service/company/find', {
			query: companyInfo.searchValue,
			limit: 10,
		});
	}

	deleteCompany(companyId: string) {
		return this.http.post('/api/accounts-service/registration/deletecompany', { companyId });
	}

	sendTariffAndOptions(data: {
		companyId: string,
		packageName?: string | null,
		optionIds?: string[] | null,
	}): void {
		if (!data?.companyId) return;

		if (data?.packageName) {
			this.http.post(`/api/tariff-service/companies/${data.companyId}/packages/default`, {
				packageName: data.packageName,
			}).subscribe();
		}

		if (data?.optionIds?.length) {
			this.http.post(`/api/tariff-service/companies/${data.companyId}/options/connect`, {
				optionIds: data.optionIds,
				isRegistrationChoice: true
			}).subscribe();
		}
	}

	selectCompany({ company, withRequest = true, isManual = false, accountOpened = false }) {
		this.store.changeStep(RegistrationSteps.empty);

		this.store.setSelectedCity(null);
		this.citiesListService.selectCity(null);

		this.chat.displaySelectedCompany(company);
		this.store.setChosenCompany(company);
		this.chat.postponeAction(() => {
			this.chat.changeChatLoader(true);
		}, getMessageTimeout(1)).subscribe();

		const cityName = company?.city || company?.definedCity;

		if (cityName && !accountOpened) {
			this.citiesListService.getSingleCity(cityName).subscribe((companyCity) => {
				if (companyCity) {
					this.store.setSelectedCity(companyCity);

					if (companyCity.isServiced) {
						this.citiesListService.selectCity(companyCity);
					}
				}
			});
		}

		this.addressesService.resetState();
		this.citiesListService.resetState();
		this.meetingService.resetState();

		let startUrl;
		let model;

		if (isManual) {
			startUrl = '/api/accounts-service/registration/manualstart';
			model = { innOrOgrn: company?.inn || company?.ogrn };
		} else {
			startUrl = '/api/accounts-service/registration/start';
			model = { Ogrn: company?.ogrn };
		}

		return forkJoin({
			loadYmaps: accountOpened ? of(null) : this.loadYmaps(),
			selectedCompany: withRequest
				? this.http.post<SelectedCompany>(startUrl, model).pipe(minTime(2000))
				: of(company as SelectedCompany),
		}).pipe(
			take(1),
			tap({
				next: ({ selectedCompany }) => {
					if (withRequest) {
						this.analyticsService.sendAction(EventAction.entity_success, EventCategory.user_lead);

						if (selectedCompany.companyId) {
							this.companySelectedEvent.next(selectedCompany.companyId);
						}
					}
					this.store.setSelectedCompany(selectedCompany as SelectedCompany);

					if (accountOpened) {
						this.notifyAboutSuccessfulAccountOpening();
						this.chat.setEditableStep(RegistrationSteps.enterPhone);
					}
				},
				error: (result) => {
					switch (result?.error?.type) {
					case 'AlreadyExistsInOtherScope':
						this.chat.pushMessage({
							from: Senders.bank,
							type: MessageType.selectAnotherCompany,
						});
						this.chat.changeChatLoader(false);
						// блокирующий фактор при регистрации, установка цвета прогресс бара
						this.store.setRegistrationBlock(true);
						this.store.changeStep(RegistrationSteps.accountIsAlreadyOpen);
						break;
					case 'AlreadyExistsInSelfScope':
						this.notifyAboutSuccessfulAccountOpening();
						this.chat.setEditableStep(RegistrationSteps.enterPhone);
						break;
					default:
						this.chat.pushMessage({
							from: Senders.bank,
							type: MessageType.errorMessage,
							data: {
								errorMessage: result?.error?.message,
							},
						});
						this.chat.changeChatLoader(false);
						this.store.setRegistrationBlock(true);
						this.store.changeStep(RegistrationSteps.empty);
						this.chat.setEditableStep(RegistrationSteps.searchCompany);
						break;
					}
				},
			}),
		);
	}

	notifyAboutSuccessfulAccountOpening() {
		const messages: Message[] = [];

		messages.push({
			from: Senders.bank,
			type: MessageType.accountForCompanyIsAlreadyOpen,
		});

		messages.push({
			from: Senders.bank,
			type: MessageType.timeToDownloadApp,
		});

		if (this.deviceService.isDesktopDevice) {
			messages.push({
				from: Senders.bank,
				type: MessageType.pointCameraAtQrCode,
			});
			messages.push({
				from: Senders.bank,
				type: MessageType.pointQrCode,
			});
		}

		messages.push({
			from: Senders.bank,
			type: MessageType.viewRequsities,
		});

		if (this.deviceService.isMobileDevice) {
			messages.push({
				from: Senders.client,
				type: MessageType.downloadApp,
			});
		}

		messages.push({
			from: Senders.bank,
			type: MessageType.openAccountToAnotherNumber,
		});

		messages.forEach((message, index) => {
			this.chat.pushMessage({
				...message,
				timeout: getMessageTimeout(index),
			});
		});

		this.chat.postponeAction(() => {
			this.chat.changeChatLoader(false);
			this.store.changeStep(RegistrationSteps.last);
		}, getMessageTimeout(messages.length - 1)).subscribe();
	}

	initRegistration(
		environment: any,
		partner: RegistrationPartner | null = null
	) {
		this.store.changeStep(RegistrationSteps.empty);
		this.setState({
			environment,
			registrationProcessIsActive: true,
			isLanding: environment.project === 'landing',
			isAgentLanding: environment.project === 'agent-landing',
			isInternalAgentLanding: environment.project === 'agent-landing-internal',
			partner,
			isAvitoRegistration: partner === RegistrationPartner.avito,
		});
	}

	stopRegistration() {
		this.setState({
			registrationProcessIsActive: false,
		});
	}

	sendExponeaId(exponeaId: string) {
		return;
	// this.http.post('/api/exponea-service/registration/cookie',
	// 	{
	// 		cookie: exponeaId,
	// 		platform: 'web',
	// 	})
	// 	.subscribe();
	}

	resetState() {
		this.setState({
			...initState,
			isLanding: this.state.isLanding,
			isAgentLanding: this.state.isAgentLanding,
			isInternalAgentLanding: this.state.isInternalAgentLanding,
		});
	}

	goToMeetingStep({ sessionFlow = false, virtualMeeting = false }: {
		sessionFlow?: boolean,
		virtualMeeting?: boolean,
	}) {
		this.chat.pushMessage({
			from: Senders.bank,
			type: MessageType.remainsToAssignMeeting,
			timeout: getMessageTimeout(1),
		});

		if (!virtualMeeting) {
			this.chat.pushMessage({
				from: Senders.bank,
				type: MessageType.specifyMeetingCity,
				timeout: getMessageTimeout(2),
			});
		}

		this.chat.postponeAction(() => {
			this.chat.changeChatLoader(false);
			if (!sessionFlow) {
				this.store.changeStep(RegistrationSteps.citySelection);
			}
		}, getMessageTimeout(2)).subscribe();
	}

	startCompanySearch(selectedCompany?: SelectedCompany): void {
		const companyNotFound = Boolean(selectedCompany && !selectedCompany?.foundInPublicRefs);

		this.store.changeStep(RegistrationSteps.empty);
		this.chat.pushMessage({
			from: Senders.bank,
			type: MessageType.enterOrganizationName,
		});

		this.chat.postponeAction(() => {
			this.chat.changeChatLoader(true);
		}, getMessageTimeout(1)).subscribe();

		if (companyNotFound) {
			this.chat.pushMessage({
				from: Senders.bank,
				type: MessageType.taxHasNotAddedCompany,
				timeout: getMessageTimeout(2),
			});

			this.chat.pushMessage({
				from: Senders.bank,
				type: MessageType.enterRequisities,
				timeout: getMessageTimeout(3),
			});
		}

		this.chat.postponeAction(
			() => {
				this.chat.changeChatLoader(false);
				this.store.changeStep(RegistrationSteps.searchCompany);
			},
			getMessageTimeout(companyNotFound ? 3 : 2)
		).subscribe();
	}

	startSmsStep(again: boolean, codeFromServer: boolean): void {
		if (!codeFromServer) {
			this.store.changeStep(RegistrationSteps.enterSms);
		}

		this.chat.pushMessage({
			from: Senders.bank,
			type: again
				? MessageType.sentCode
				: MessageType.confirmPhoneNumber,
		});

		this.chat.changeChatLoader(false);

		if (!codeFromServer) {
			this.smsBlockingService.startSmsBlockingFlow();
		}
	}

	private loadYmaps(): Observable<any> {
		const { host, apikeys, lang, mode, version } = this.state.environment.yandexMapsConfig;
		const url = `https://${host}/${version}/?apikey=${apikeys.maps}&lang=${lang}&mode=${mode}&csp=true`;

		return this.lazyScript.loadScript(url);
	}

	finishRegistration(selectedCompany: SelectedCompany) {
		this.chat.postponeAction(() => {
			this.chat.changeChatLoader(true);
		}, getMessageTimeout(1)).subscribe();
		const companyId = selectedCompany?.companyId;
		this.http.get<string[]>(`/api/meetingmanager-service/common/companies/${companyId}/documentsToMeet`).pipe(catchError(() => []))
			.pipe(
				tap({
					next: (documents) => {

						const messages: Message[] = [];
						const buttons: Message[] = [];

						messages.push({
							from: Senders.bank,
							type: MessageType.registrationCompleted,
						});

						if (documents?.length) {
							messages.push({
								from: Senders.bank,
								type: MessageType.documentsList,
								data: { documents },
							});
						}

						if(localStorage.getItem('regHoldingState') === 'on') {
							this.chat.pushMessage({
								from: Senders.client,
								type: MessageType.registrationMultipleCompany,
							});
						} else if (localStorage.getItem('regHoldingState') === 'off') {
							this.chat.pushMessage({
								from: Senders.client,
								type: MessageType.noCompanyRegistrationMultiple,
							});
						}

						messages.push({
							from: Senders.bank,
							type: MessageType.accountAlreadyReserved,
						});

						if (this.deviceService.isDesktopDevice) {
							messages.push({
								from: Senders.bank,
								type: MessageType.pointCameraAtQrCode,
							});
							messages.push({
								from: Senders.bank,
								type: MessageType.pointQrCode,
							});
						}

						buttons.push({
							from: Senders.client,
							type: MessageType.viewRequsities,
						});

						if (this.deviceService.isMobileDevice) {
							buttons.push({
								from: Senders.client,
								type: MessageType.downloadApp,
							});
						}
						buttons.push({
							from: Senders.bank,
							type: MessageType.openAccountToAnotherNumber,
						});

						messages.forEach((message, index) => {
							this.chat.pushMessage({
								...message,
								timeout: index ? getMessageTimeout(index) : null,
							});
						});

						this.chat.postponeAction(() => {
							buttons.forEach(message => {
								this.chat.pushMessage(message);
							});
							this.store.changeStep(RegistrationSteps.last);
							this.chat.changeChatLoader(false);
						}, getMessageTimeout(messages.length - 1)).subscribe();

						this.registerNinjaClient(companyId).subscribe();
					},
				}),
			)
			.subscribe();
	}

	registerNinjaClient(companyId: string) {
		if(!companyId) return;
		const ninja = this._document.defaultView['SalesNinja'];

		return from(ninja.getCustomerIdAsync()).pipe(
			filter(clientId => Boolean(clientId)),
			switchMap(clientId => this.http.post(`/api/accounts-service/companies/${companyId}/salesninja`, { clientId })
				.pipe(first()))
		);
	}
}
