<form
	[formGroup]="innOgrnForm"
	(ngSubmit)="submit($event)"
	*ngIf="innOgrnForm"
	class="d-flex"
	[ngClass]="{
		'mt-4': isOverlay
	}"
>
	<div
		class="field field--btn"
		[ngClass]="{
			'field--reg': isOverlay
		}"
		b-shared-validation
		[control]="innOgrnControl"
		[errors]="{ invalidInnAndOgrn: invalidInnAndOgrn }"
		pos="top left"
	>
		<input
			type="text"
			inputmode="numeric"
			[placeholder]="isOverlay ? 'ИНН или ОГРН' : ''"
			autocomplete="off"
			class="field__control"
			formControlName="innOgrn"
			(blur)="onBlur()"
		/>
		<button
			[@lifecycleTrigger]
			b-shared-button
			*ngIf="canSend"
			type="submit"
			class="field__addon btn btn--primary"
			[ngClass]="{
				'brs-12 p-0': isOverlay
			}"
			[disabled]="!canSend"
		>
			<span
				[ngClass]="{
					'd-none': true,
					'lg:d-block': !isOverlay
				}"
			>Отправить</span>
			<svg
				b-shared-svg
				[name]="submitButtonIcon"
				[ngClass]="{
					'icon': true,
					'icon--xl': isOverlay,
					'lg:d-none': !isOverlay
				}"
			></svg>
		</button>

		<ng-template #invalidInnAndOgrn>
			<p>Эти цифры не&nbsp;похожи на&nbsp;ИНН или ОГРН</p>
			<p class="mt-4">Ты вводишь верные данные?</p>
		</ng-template>
	</div>
</form>
