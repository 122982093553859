import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { StateService } from 'shared';
import { CitiesListState, CitiesListView, City, ApplicationForCity, SendApplicationReuqestBody } from '../types/cities-list.types';
import { MeetingService } from './meeting.service';
import { AddressesService } from './addresses.service';
import { mergeMap, take, of, catchError } from 'rxjs';

const initState: CitiesListState = {
	selectedCity: null,
	view: CitiesListView.default,
	filteredCitiesList: [],
	availableCities: [],
};
@Injectable({
	providedIn: 'root',
})
export class CitiesListService extends StateService<CitiesListState> {
	selectedCity$ = this.select((state) => state.selectedCity);
	view$ = this.select((state) => state.view);
	filteredCitiesList$ = this.select((state) => state.filteredCitiesList);

	constructor(
		private http: HttpClient,
		private meetingService: MeetingService,
		private addressesService: AddressesService,
	) {
		super(initState);
	}

	resetState() {
		this.setState(initState);
	}

	changeView(view: CitiesListView) {
		this.setState({ view });
	}

	resetCitiesList() {
		this.setState({
			filteredCitiesList: [...this.state.availableCities],
		});
	}

	changeCitiesList(list: City[]) {
		this.setState({
			filteredCitiesList: list,
		});
	}

	filterCities(searchFilter: string) {
		return this.searchCities(searchFilter).pipe(
			tap({
				next: (results) => {
					this.changeCitiesList(results);

					if (results.length) {
						this.changeView(CitiesListView.default);
					} else {
						this.changeView(CitiesListView.empty);
					}
				},
				error: () => {
					this.changeView(CitiesListView.empty);
				},
			}),
		);
	}

	searchCities(searchValue: string) {
		return this.http.post<City[]>('/api/meetingmanager-service/maps/suggestion/city', {
			query: searchValue,
			limit: 100,
		});
	}

	getSingleCity(city: string) {
		return this.http.post<City>('/api/meetingmanager-service/maps/suggestion/singlecity', { city });
	}

	setSelectedCity(selectedCity: City) {
		this.setState({ selectedCity });
	}

	selectCity(selectedCity: City): void {
		this.meetingService.resetMapState();
		this.addressesService.setSelectedHint(null);
		this.setSelectedCity(selectedCity);

		if (selectedCity && !this.meetingService.state.geoJson) {
			this.meetingService
				.getGeoJson()
				.pipe(
					tap({
						next: () => {
							if (this.meetingService.state.map) {
								this.meetingService.createServiceZones();
								this.meetingService.createMeetingPlaces();
							}
						}
					}),
					take(1),
				)
				.subscribe();
		}
	}

	submitApplicationForCity(body: SendApplicationReuqestBody) {
		return this.http.post<ApplicationForCity>('/api/meetingmanager-service/client/application', body);
	}

	getApplicationForCity() {
		return this.http.get<ApplicationForCity>('/api/meetingmanager-service/client/application');
	}

	saveCity(city: string) {
		return this.http.post('/api/meetingmanager-service/client/city', { city });
	}

	getSavedCity() {
		return this.http.get<string>('/api/meetingmanager-service/client/city');
	}

	getSavedCityData() {
		return this.getSavedCity()
			.pipe(
				mergeMap((cityName: string) => cityName ?
					this.getSingleCity(cityName).pipe(
						catchError(() => of(null)),
						tap({
							next: city => this.setSelectedCity(city)
						})
					) :
					of(null)),
				take(1),
			);
	}

}
