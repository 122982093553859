import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { RegistrationComponent } from './registration.component';
import { MessageComponent } from './components/chat/message/message.component';
import { DefaultMessageComponent } from './components/chat/default-message/default-message.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import {
	CommonFormsModule,
	SvgModule,
	ButtonModule,
	EmojiModule,
	TimeCounterModule,
	AutocompleteModule,
	SafeHtmlPipeModule,
	TextareaModule,
	DropdownModule,
	OverlayModule,
	ImageModule,
	LetModule,
	ClickModule,
} from 'shared';
import {
	CompanySearchComponent,
	AddressSearchComponent,
	CitiesListComponent,
	MeetingPlaceMapComponent,
	AddressListItemComponent,
	DateSelectionComponent,
	RequisitesComponent,
	PhoneComponent,
	NoCompanyInfoStepComponent,
	ChangeCompanyButtonComponent,
	SmsConfirmComponent,
	OpfSelectionComponent,
	CitySelectionComponent,
	MeetingTypeSelectionComponent,
	StepAnimationComponent,
	HoldingCompanyComponent,
	ProgressBarComponent,
} from './components';
import { LottieAnimationsService } from './services/lottie-animations.service';
import { SimplebarAngularModule } from 'simplebar-angular';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
	providers: [
		LottieAnimationsService,
	],
	declarations: [
		RegistrationComponent,
		MessageComponent,
		DefaultMessageComponent,
		CompanySearchComponent,
		AddressSearchComponent,
		CitiesListComponent,
		MeetingPlaceMapComponent,
		AddressListItemComponent,
		DateSelectionComponent,
		RequisitesComponent,
		PhoneComponent,
		NoCompanyInfoStepComponent,
		ChangeCompanyButtonComponent,
		SmsConfirmComponent,
		OpfSelectionComponent,
		CitySelectionComponent,
		MeetingTypeSelectionComponent,
		StepAnimationComponent,
		HoldingCompanyComponent,
	],
	imports: [
		CommonModule,
		CommonFormsModule,
		FormsModule,
		ReactiveFormsModule,
		InputMaskModule,
		ButtonModule,
		InputTextModule,
		AutocompleteModule,
		DropdownModule,
		InputTextareaModule,
		SvgModule,
		EmojiModule,
		ImageModule,
		NgxMaskModule.forRoot(),
		TimeCounterModule,
		RouterModule,
		SafeHtmlPipeModule,
		TextareaModule,
		OverlayModule,
		LetModule,
		SimplebarAngularModule,
		ClickModule,
		ProgressBarComponent,
	],
	exports: [
		RegistrationComponent,
		StepAnimationComponent,
	],
})
export class RegistrationCommonModule {
	public static forRoot(environment: any): ModuleWithProviders<RegistrationCommonModule> {
		return {
			ngModule: RegistrationCommonModule,
			providers: [
				{
					provide: 'env',
					useValue: environment,
				},
			],
		};
	}
}
