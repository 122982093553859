import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import jwtDecode from 'jwt-decode';
import {
	JwtPayloadType,
	MeetingStatus,
	OrganizationalLegalForm,
	RegistrationSession,
	RegistrationSteps,
	SessionDataResponse,
	SessionState,
} from '../types/registration.types';
import { catchError, mergeMap, takeWhile, tap } from 'rxjs/operators';
import { defer, iif, map, Observable, of, switchMap, take, throwError } from 'rxjs';
import { DeviceService, FEATURES, FeatureTogglingService, LOCAL_STORAGE, MaskService, StateService } from 'shared';
import { CompanyStatus, Meeting, MeetingType, RegistrationStatus, SelectedCompany } from '../types/searchCompany.types';
import { AuthService } from './auth.service';
import { RegistrationService } from './registration.service';
import { ChatService } from './chat.service';
import { MessageType, Senders } from '../types/chat.types';
import { MeetingService } from './meeting.service';
import { RegistrationStore } from './registration.store';
import { MeetingAssignService } from './meeting-assign.service';
import { Feature, Hint } from '../types/city-selection.types';
import { CitiesListService } from './cities-list.service';
import { ApplicationForCity } from '../types/cities-list.types';

const initState: SessionState = {
	session: {
		accessToken: null,
		refreshToken: null,
		expiresIn: null,
		tokenPayload: null,
	},
	clientCompanies: [],
};

@Injectable({
	providedIn: 'root',
})
export class SessionService extends StateService<SessionState> {
	constructor(
		private http: HttpClient,
		private authService: AuthService,
		private registrationService: RegistrationService,
		private chat: ChatService,
		private meetingService: MeetingService,
		private store: RegistrationStore,
		private meetingAssignService: MeetingAssignService,
		private maskService: MaskService,
		private deviceService: DeviceService,
		private featureToggling: FeatureTogglingService,
		private citiesListService: CitiesListService,
		@Inject(LOCAL_STORAGE) readonly localStorage: Storage,
	) {
		super(initState);
	}

	decodeJwt(jwt: string): JwtPayloadType {
		const jwtPayload = jwtDecode<JwtPayloadType>(jwt);
		jwtPayload.PhoneNumber = this.maskService.parsePhone(jwtPayload.PhoneNumber);

		this.setState({
			session: {
				...this.state.session,
				tokenPayload: jwtPayload,
			},
		});

		return jwtPayload;
	}

	updateAccessToken(
		refreshToken: string = this.deviceService.isServer
			? null
			: this.localStorage?.getItem(
				RegistrationSession.RegistrationRefreshToken || this.state.session.refreshToken,
			  ),
	): Observable<SessionDataResponse> {
		return this.http.post<SessionDataResponse>('/api/sso/entrance/renew', { refreshToken }).pipe(
			tap((response) => {
				this.setSessionData(response);
			}),
		);
	}

	setSessionData(response: SessionDataResponse | null): void {
		if (!response || !response.accessToken) {
			if (!this.deviceService.isServer) {
				Object.keys(RegistrationSession).forEach((item) => this.localStorage?.removeItem(item));
			}
			this.setState({
				session: initState.session,
			});
			return;
		}

		this.decodeJwt(response.accessToken);
		if (
			!this.deviceService.isServer &&
			!this.registrationService.state.isAgentLanding &&
			!this.registrationService.state.isInternalAgentLanding
		) {
			this.localStorage?.setItem(RegistrationSession.RegistrationAccessToken, response.accessToken);

			this.localStorage?.setItem(RegistrationSession.RegistrationRefreshToken, response.refreshToken);

			const expiresDate = new Date();
			expiresDate.setSeconds(expiresDate.getSeconds() + response.expiresIn);
			this.localStorage?.setItem(RegistrationSession.AccessTokenExpiresDate, expiresDate.toString());
		}

		this.setState({
			session: {
				...this.state.session,
				...response,
			},
		});
	}

	resetState() {
		this.setState(initState);
	}

	getClientCompanies(): Observable<SelectedCompany[]> {
		return this.http.get<SelectedCompany[]>('/api/accounts-service/companies/smart').pipe(
			take(1),
			takeWhile(() => this.state?.clientCompanies?.length === 0),
			tap((clientCompanies) => {
				this.setState({ clientCompanies });
			}),
		);
	}

	getClientMeetings() {
		return this.http.get<Meeting[]>('/api/meetingmanager-service/client/info');
	}

	goToCompanySelectionStep(isAuthFlow: boolean = false, selectedCompany?: SelectedCompany) {
		if (!isAuthFlow) {
			const phone = this.state.session.tokenPayload.PhoneNumber.replace(/\D/g, '');
			const opf = this.localStorage?.getItem(`selectedOpf_${phone}`) as OrganizationalLegalForm;
			const selectOpfIsActive = Boolean(this.featureToggling.cachedFeatures[FEATURES.OpenAccountsInBeginningOfRegistration]);

			if (opf && selectOpfIsActive) {
				const isSelectedOnOpfStep = this.localStorage?.getItem(`opfSelectionStep_${phone}`) === RegistrationSteps.opfSelection;

				if (isSelectedOnOpfStep) {
					this.authService.sendPhoneToChat(this.state.session.tokenPayload.PhoneNumber);
					this.chat.pushMessage({
						from: Senders.bank,
						type: MessageType.selectOpf,
					});
					this.authService.sendOpfToChat(opf, isSelectedOnOpfStep);
				} else {
					this.authService.sendOpfToChat(opf, isSelectedOnOpfStep);
					this.authService.sendPhoneToChat(this.state.session.tokenPayload.PhoneNumber);
				}
			} else {
				this.authService.sendPhoneToChat(this.state.session.tokenPayload.PhoneNumber);
			}

			const lastValidSmsRegistrationCode = this.deviceService.isServer
				? null
				: this.localStorage?.getItem('LastValidSmsRegistrationCode');
			if (lastValidSmsRegistrationCode) {
				this.registrationService.startSmsStep(false, false);
				this.chat.pushMessage({
					from: Senders.client,
					type: MessageType.defaultMessageWithCustomContent,
					data: {
						content: lastValidSmsRegistrationCode,
					},
				});
			}
		}
		this.registrationService.startCompanySearch(selectedCompany);
	}

	resetLoaders() {
		this.store.changeBlockingLoader(false);
		this.chat.changeChatLoader(false);
		this.chat.changeAuthLoader(false);
		this.chat.setAccumulation(false);
		this.store.showAccumulatedMessages();
	}

	getClientData(flow: 'auth' | 'reloadSession' = 'reloadSession') {
		this.chat.setAccumulation(true);

		if (flow === 'auth') {
			this.chat.changeAuthLoader(true);
		} else if (flow === 'reloadSession') {
			this.store.changeBlockingLoader(true);
		}

		const phone = this.state.session?.tokenPayload?.PhoneNumber;

		if (phone) {
			this.authService.updateLastEnteredNumber(phone);
			this.authService.updateSelectedOpf(this.localStorage?.getItem(`selectedOpf_${phone.replace(/\D/g, '')}`) as OrganizationalLegalForm);
			this.authService.updateOpfSelectionStep(this.localStorage?.getItem(`opfSelectionStep_${phone.replace(/\D/g, '')}`) as RegistrationSteps);
		}

		this.getClientCompanies()
			.pipe(
				catchError((response) => {
					if (response?.error?.type === 'CompanyIsNotExists') {
						this.goToCompanySelectionStep(flow === 'auth');
					} else {
						this.chat.pushMessage({
							from: Senders.bank,
							type: MessageType.errorMessage,
						});

						this.store.changeStep(RegistrationSteps.enterPhone);
					}
					this.resetLoaders();
					return throwError(() => response);
				}),
			)
			.subscribe({
				next: (companies: SelectedCompany[]) => {
					const currentAccountCompany = companies.find(company => company.companyId === localStorage.getItem('currentCompany') && company.registrationStatus !== RegistrationStatus.Refusal);
					const openedAccountCompany = companies.find(company => company.status === CompanyStatus.AccountOpened);
					const noneCompany = companies.find(company => company.status === CompanyStatus.None);
					const registrationCompany = companies.find(company => company.status === CompanyStatus.Registration);
					const registrationCompanyNoRefusal = companies.find(company => company.status === CompanyStatus.Registration && company.registrationStatus !== RegistrationStatus.Refusal);
					const closedAccountCompany = companies.find(company => company.status === CompanyStatus.AccountClosed);

					// выбор целевой компании по статусам, указанным в порядке приоритета
					const targetCompany = [
						currentAccountCompany,
						openedAccountCompany,
						noneCompany,
						registrationCompanyNoRefusal,
						registrationCompany,
						closedAccountCompany,
					].find(Boolean);

					if (!targetCompany) {
						this.resetLoaders();
						return;
					}

					if (targetCompany.status === CompanyStatus.AccountClosed) {
						this.setSessionData(null);
						this.store.changeStep(RegistrationSteps.enterPhone);
						this.resetLoaders();
						return;
					}

					this.goToCompanySelectionStep(flow === 'auth', registrationCompany);

					const accountOpened = targetCompany.status === CompanyStatus.AccountOpened;
					const isRefusal = targetCompany.registrationStatus === RegistrationStatus.Refusal;
					let applicationForCity: ApplicationForCity | null = null;

					localStorage.setItem('currentCompany', targetCompany.companyId); // выбираем текущую компанию

					this.registrationService
						.selectCompany({
							company: targetCompany,
							withRequest: false,
							accountOpened,
						})
						.pipe(
							catchError((response) => {
								this.resetLoaders();
								return throwError(() => response);
							}),
							switchMap(() => {
								return iif(
									() => accountOpened || isRefusal,
									of(null),
									this.citiesListService.getApplicationForCity()
										.pipe(
											tap({
												next: application => {
													applicationForCity = application;
												}
											}),
											catchError(() => of(null)),
										)
								);
							}),
							mergeMap(() => {
								return iif(
									() => Boolean(applicationForCity) || accountOpened || isRefusal,
									of([]),
									this.getClientMeetings()
								);
							}),
							take(1),
						)
						.subscribe({
							next: async (meetings: Meeting[]) => {
								if (isRefusal) {
									this.meetingAssignService.refusalToOpenAccount(targetCompany);
									this.resetLoaders();
									return;
								}

								if (accountOpened) {
									this.resetLoaders();
									return;
								}

								if (applicationForCity) {
									this.registrationService.goToMeetingStep({ sessionFlow: true });
									const selectNotServicedCityFlow = await this.meetingService.selectNotServicedCity(
										applicationForCity.city,
										applicationForCity.maxApplicationCount - applicationForCity.applicationCount
									);

									selectNotServicedCityFlow.subscribe(() => {
										this.resetLoaders();
									});
									return;
								}
								const meetingOnRegistration = meetings?.find(
									(i) =>
										i.companyId === targetCompany.companyId &&
										[MeetingStatus.None, MeetingStatus.Planned].includes(i?.status),
								);

								const isVirtualMeeting = meetingOnRegistration?.meetType === MeetingType.Video;

								const meetingOnAccountOpening = meetings?.find(
									(i) =>
										i.companyId === targetCompany.companyId &&
										[MeetingStatus.InProgress, MeetingStatus.Completed].includes(i?.status),
								);

								const cancelledMeeting = meetings?.find(
									(i) =>
										i.companyId === targetCompany.companyId &&
										i?.status === MeetingStatus.Cancelled,
								);

								if (meetingOnRegistration) {
									const comment = meetingOnRegistration.comment || '';
									if (!this.deviceService.isServer) {
										this.localStorage?.setItem(`MeetingComment_${targetCompany.companyId}`, comment);
									}
									this.meetingService.changeComment(comment);
									this.meetingService.changeActiveMeeting(meetingOnRegistration);
								} else {
									this.store.changeStep(RegistrationSteps.citySelection);

									if (meetingOnAccountOpening) {
										this.meetingService.changeActiveMeeting(meetingOnRegistration);
										this.chat.pushMessage({
											from: Senders.bank,
											type: MessageType.waitingAccountOpening,
										});

										this.chat.pushMessage({
											from: Senders.bank,
											type: MessageType.timeToDownloadApp,
										});

										if (this.deviceService.isDesktopDevice) {
											this.chat.pushMessage({
												from: Senders.bank,
												type: MessageType.pointCameraAtQrCode,
											});
											this.chat.pushMessage({
												from: Senders.bank,
												type: MessageType.pointQrCode,
											});
										}

										this.chat.pushMessage({
											from: Senders.bank,
											type: MessageType.viewRequsities,
										});

										if (this.deviceService.isMobileDevice) {
											this.chat.pushMessage({
												from: Senders.client,
												type: MessageType.downloadApp,
											});
										}

										this.chat.pushMessage({
											from: Senders.bank,
											type: MessageType.openAccountToAnotherNumber,
										});

										this.store.switchTheChangeability(false);
										this.store.changeStep(RegistrationSteps.accountOpeningInProcess);
										this.resetLoaders();
										return;
									}
								}

								if (!meetingOnRegistration && cancelledMeeting) {
									this.registrationService.goToMeetingStep({ sessionFlow: true, virtualMeeting: isVirtualMeeting });
									this.meetingService.changeActiveMeeting(cancelledMeeting);
									this.resetLoaders();
									return;
								}

								if (isVirtualMeeting) {
									this.registrationService.goToMeetingStep({ sessionFlow: true, virtualMeeting: isVirtualMeeting });
									this.meetingAssignService.assign(meetingOnRegistration);
									this.resetLoaders();
									return;
								}

								this.meetingFlow(targetCompany, meetingOnRegistration)
									.subscribe({
										next: () => {
											if (meetingOnRegistration) {
												this.meetingAssignService.assign(meetingOnRegistration);
											}
										},
									});
							},
							error: () => {
								this.meetingFlow(targetCompany).subscribe();
							},
						});
				},
			});
	}

	meetingFlow(targetCompany: SelectedCompany, meeting?: Meeting) {
		return this.citiesListService.getSavedCityData().pipe(
			tap({
				next: city => {
					this.registrationService.goToMeetingStep({ sessionFlow: false });

					if (!city) {
						this.resetLoaders();
					}
				}
			}),
			mergeMap(city => {
				return defer(
					() => Boolean(city?.isServiced) ?
						this.meetingService.startMeetingTypeSelection(city).pipe(
							map(({ officeZones }) => {
								const feature = this.localStorage?.getItem(`SelectedAddressFeature_${targetCompany.companyId}`);
								const polygon = this.localStorage?.getItem(`SelectedZone_${targetCompany.companyId}`);
								const featureData = feature ? JSON.parse(feature) : null;
								const polygonData = polygon ? JSON.parse(polygon) : null;

								if (feature && polygon) {
									const isOfficeMeeting = featureData?.properties?.organizationId === 'office';

									if (!isOfficeMeeting) {
										this.meetingService.changeDepartureFlow(true);
										this.chat.pushMessage({
											from: Senders.client,
											type: MessageType.comeToMe,
											editableStep: RegistrationSteps.meetingTypeSelection,
										});

										this.chat.pushMessage({
											from: Senders.bank,
											type: MessageType.alright,
										});
									}
								}

								return { featureData, polygonData, officeZones };
							}),
							mergeMap(({ featureData, polygonData, officeZones }) => {
								return defer(
									() => Boolean(featureData && polygonData) ?
										this.meetingService
											.selectPoint({
												feature: featureData,
												pointPolygon: polygonData,
												sessionFlow: true,
											}).pipe(
												tap({
													next: () => this.store.changeStep(RegistrationSteps.datePicker)
												})
											) :
										defer(
											() => Boolean(meeting?.meetType === MeetingType.Office) ?
												defer(() => {
													const targetOffice = officeZones.find(office => office.officeAddress === meeting.address);

													const point = {
														type: 'Feature',
														id: '10000',
														geometry: {
															coordinates: [
																targetOffice.officeCoordinates.latitude,
																targetOffice.officeCoordinates.longitude
															],
															type: 'Point'
														},
														properties: {
															description: targetOffice.officeAddress,
															organizationId: 'office',
														},
													};

													const zone = {
														id: targetOffice.geoZone.toString(),
														type: 'Feature',
														geometry: { coordinates: [0, 0], type: 'Polygon' },
													};

													return this.meetingService.selectPoint({
														feature: point,
														pointPolygon: zone,
														sessionFlow: true,
													});
												}) :
												!meeting ? of(null) : this.http.post<Hint[]>('/api/meetingmanager-service/maps/suggestion/address', {
													query: meeting.address,
													city: meeting.city,
													limit: 1,
													includeOrgs: true,
												}).pipe(
													catchError(() => of(null)),
													mergeMap(hints => {
														const address = hints?.[0];
														let point: Feature, zone: Feature;

														if (address) {
															point = this.meetingService.getFeatureFromHint(address);
															zone = {
																id: address.geoZone,
																type: 'Feature',
																geometry: { coordinates: [0, 0], type: 'Polygon' },
															};
														}

														return defer(
															() => Boolean(address) ?
																this.meetingService.selectPoint({
																	feature: point,
																	pointPolygon: zone,
																	sessionFlow: true,
																}) :
																of(null)
														);
													})
												)
										)
								);
							}),
						) :
						of(null)
				);
			}),
			tap({
				next: () => this.resetLoaders(),
				error: () => this.resetLoaders(),
			}),
			take(1),
		);
	}

	checkSession(): Observable<boolean> {
		if (!this.deviceService.isServer) {
			const expiresIn = this.localStorage?.getItem(RegistrationSession.AccessTokenExpiresDate);
			const accessToken = this.localStorage?.getItem(RegistrationSession.RegistrationAccessToken);
			this.setSessionData({
				accessToken,
				refreshToken: this.localStorage?.getItem(RegistrationSession.RegistrationRefreshToken),
				expiresIn: expiresIn ? Number(expiresIn) : null,
			});

			if (
				accessToken &&
				!this.registrationService.state.isAgentLanding &&
				!this.registrationService.state.isInternalAgentLanding
			) {
				setTimeout(() => {
					this.getClientData('reloadSession');
				}, 5000);
				return of(true);
			} else {
				this.store.changeStep(RegistrationSteps.enterPhone);
				return of(false);
			}
		}
	}
}
