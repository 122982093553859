import { animate, style, transition, trigger } from '@angular/animations';
import {
	Component,
	Input,
	Output,
	EventEmitter,
	ChangeDetectionStrategy,
	OnInit,
	OnDestroy,
	ChangeDetectorRef,
	ElementRef,
	ViewChild,
	AfterViewInit,
} from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FeatureTogglingService, FEATURES, AnalyticsService, ExponeaTypes } from 'shared';
import { OrganizationalLegalForm } from '../../../types/registration.types';

@Component({
	selector: 'b-registration-phone',
	templateUrl: './phone.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('lifecycleTrigger', [
			transition(':enter', [
				style({ opacity: 0, transform: 'translate3d(0, 100%, 0)' }),
				animate('280ms cubic-bezier(.44,.57,.63,.88)', style({ opacity: 1, transform: 'translate3d(0, 0, 0)' })),
			]),
			transition(':leave', [
				animate('200ms ease-out', style({ opacity: 0 })),
			]),
		]),
	],
})
export class PhoneComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() phoneForm: UntypedFormGroup;
	@Input() phoneControl: AbstractControl;
	@Input() canSendPhone: boolean;

	/** Для реги в шторке */
	@Input() isOverlay: boolean = false;

	@Output() submitPhone: EventEmitter<any> = new EventEmitter<any>();

	subscriptions: Subscription = new Subscription();
	opfSelectionIsAvailable: boolean = false;
	opf = OrganizationalLegalForm;
	selectedOpf: OrganizationalLegalForm = null;
	@ViewChild('phoneInput') phoneInput?: ElementRef<HTMLInputElement>;

	constructor(
		private featureToggling: FeatureTogglingService,
		private changeDetector: ChangeDetectorRef,
		public elementRef: ElementRef,
		private analyticsService: AnalyticsService,
	) {}

	ngOnInit() {
		this.subscriptions.add(
			this.featureToggling.checkFeatureActivity('accounts-service', FEATURES.OpenAccountsInBeginningOfRegistration).subscribe(isActive => {
				this.opfSelectionIsAvailable = isActive;
				this.changeDetector.detectChanges();
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions?.unsubscribe();
	}

	get submitButtonIcon(): string {
		return this.isOverlay
			? 'icons_scaling_arrow-up'
			: 'icons_16_2_arrow-up';
	}

	submit() {
		this.analyticsService.exponeaTrack(ExponeaTypes.web_action, {
			action_type: 'button click step 1 authorization',
		});

		this.submitPhone.emit(this.selectedOpf);
	}

	selectOpf(opf: OrganizationalLegalForm) {
		this.selectedOpf = opf;
	}

	pastePhone(event: ClipboardEvent): void {
		if (!this.phoneControl) {
			return;
		}

		this.phoneControl.patchValue('');

		const clipboardPhoneNumber = event.clipboardData
			.getData('text')
			.replace(/\D/g, '')
			.replace(/^[7-8]/, '');

		this.phoneControl.patchValue(clipboardPhoneNumber);
	}

	ngAfterViewInit(): void {
		this.focusPhoneInput();
	}

	private focusPhoneInput(): void {
		if (!this.phoneInput) {
			return;
		}

		this.phoneInput.nativeElement.focus();
	}
}
