import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { finalize, tap } from 'rxjs/operators';
import { AnalyticsService, ExponeaTypes, LOCAL_STORAGE, StateService } from 'shared';
import { ChatService } from './chat.service';
import { RegistrationStore } from './registration.store';
import { CompanyHolding, HoldingState } from '../types/holding.types';
import { RegistrationSteps } from '../types/registration.types';
import { Observable, of } from 'rxjs';
import { MessageType, Senders } from '../types/chat.types';
import { RegistrationService } from './registration.service';
import { SelectedCompany } from '../types/searchCompany.types';
import { getMessageTimeout } from '../enums/chat.enums';

const initState: HoldingState = {
	companiesHolding: [],
};

@Injectable({
	providedIn: 'root',
})
export class HoldingService extends StateService<HoldingState> {
	company: SelectedCompany;

	companiesHolding$: Observable<CompanyHolding[]> = this.select((state) => state.companiesHolding);

	constructor(
		private http: HttpClient,
		private chat: ChatService,
		private store: RegistrationStore,
		private registrationService: RegistrationService,
		private analyticsService: AnalyticsService,
		@Inject(LOCAL_STORAGE) readonly localStorage: Storage,
	) {
		super(initState);
		this.store.selectedCompany$.subscribe((selectedCompany) => {
			if (selectedCompany) {
				this.company = selectedCompany;
			}
		});
	}

	getHoldingCompany() {
		this.chat.postponeAction(() => {
			this.chat.changeChatLoader(true);
		}, getMessageTimeout(1)).subscribe();

		const cachedCompany = JSON.parse(localStorage.getItem('affiliatedCompany'));

		const observable = cachedCompany
			? of(cachedCompany)
			: this.http.get<{ affiliatedCompanies: CompanyHolding[] }>('/api/accounts-service/registration/affiliated');

		return observable.pipe(
			tap({
				next: ({affiliatedCompanies}) => {
					if (localStorage.getItem('regHoldingState')) {
						this.chat.pushMessage({
							from: Senders.bank,
							type: MessageType.haveHolding,
						});
						this.registrationService.finishRegistration(this.company);
						return;
					}

					if (affiliatedCompanies?.length > 0) {
						this.chat.pushMessage({
							from: Senders.bank,
							type: MessageType.haveHolding,
						});
						this.store.changeStep(RegistrationSteps.haveHolding);
						affiliatedCompanies.forEach(company => company.checked = true);
						this.localStorage.setItem('affiliatedCompany', JSON.stringify({affiliatedCompanies}));
						this.setState({companiesHolding: affiliatedCompanies});
					} else {
						this.registrationService.finishRegistration(this.company);
					}
				},
				error: () => {
					this.finishRegistration();
				}
			}),
			finalize(() =>
				this.chat.postponeAction(() => {
					this.chat.changeChatLoader(false);
				}, getMessageTimeout(1)).subscribe()
			),
		).subscribe();
	}


	startRegMultiple(companiesHolding: CompanyHolding[]) {
		const ogrns = [];
		const exponeaCompanies = [];

		companiesHolding.forEach(company => {
			exponeaCompanies.push({
				name: company.companyName,
				opened_date: company.fnsRegistrationDate,
				company_id: company.id,
				selected: company.checked
			});
			if (!company.checked) return;
			ogrns.push(company.ogrn);
		});
		this.setState({ companiesHolding });

		return this.http.post<{ affiliatedCompanies: CompanyHolding[] }>('/api/accounts-service/registration/startMultiple', {
			ogrns
		}).pipe(
			tap({
				next: () => {
					this.localStorage.setItem('regHoldingState', 'on');
					this.analyticsService.exponeaTrack(ExponeaTypes.web_action, {
						action_type: 'button click open an account',
						'selected company': JSON.stringify(exponeaCompanies),
						'transition screen': 'registrated'
					});
					this.finishRegistration();
				},
				error: (response) => {
					this.chat.pushMessage({
						from: Senders.bank,
						type: MessageType.errorMessage,
						data: {
							errorMessage: response?.error?.message,
						},
					});
				}
			})
		);
	}

	finishRegistration() {
		this.registrationService.finishRegistration(this.company);
		this.localStorage.setItem('affiliatedCompany', null);
	}

	noRegistrationHolding() {
		this.localStorage.setItem('regHoldingState', 'off');
		this.finishRegistration();
	}


	resetState() {
		this.setState(initState);
		localStorage.removeItem('regHoldingState');
		localStorage.removeItem('affiliatedCompany');
	}
}
