import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { RegistrationStore } from '../../../services/registration.store';
import { SelectedCompany } from '../../../types/searchCompany.types';
import { BankRequisities, DeviceService, WINDOW } from 'shared';
import { Subscription } from 'rxjs';


interface Requisite {
	title: string;
	value: string;
}

@Component({
	selector: 'b-registration-requisites',
	templateUrl: './requisites.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequisitesComponent implements OnInit, OnDestroy {
	selectedCompany: SelectedCompany;
	subscriptions = new Subscription();
	copied: boolean = false;
	timeout;

	constructor(
		private store: RegistrationStore,
		private changeDetector: ChangeDetectorRef,
		private deviceService: DeviceService,
		@Inject(WINDOW) private _window: Window,
	) {}

	ngOnInit() {
		this.subscriptions.add(
			this.store.selectedCompany$.subscribe((selectedCompany) => {
				if (selectedCompany) {
					this.selectedCompany = selectedCompany;
					this.changeDetector.detectChanges();
				}
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions?.unsubscribe();
	}

	get requisities(): Requisite[] {
		let requisities: Requisite[] = [];

		if (this.selectedCompany) {
			requisities = [
				...requisities,
				{ title: 'Наименование', value: this.selectedCompany?.shortname },
				{ title: 'ОГРН', value: this.selectedCompany?.ogrn },
				{ title: 'ИНН', value: this.selectedCompany?.inn },
				{ title: 'КПП', value: this.selectedCompany?.kpp },
				{ title: 'Номер счёта', value: this.selectedCompany?.accountNumber },
			];
		}

		requisities.push(
			{ title: 'Банк', value: BankRequisities.name },
			{ title: 'Бик', value: BankRequisities.bik },
			{ title: 'Номер кс', value: BankRequisities.correspondentAccount },
		);

		return requisities;
	}

	trackByRequisities(index: number, requisite: Requisite) {
		return `${requisite.title}${index}`;
	}

	copy() {
		if (this.deviceService.isServer) {
			return;
		}

		const text = this.requisities
			.filter(req => req?.title && req?.value)
			.map((i) => `${i.title}: ${i.value}\n`)
			.join('');

		this._window.navigator.clipboard.writeText(text);

		this.copied = true;
		this.changeDetector.detectChanges();
		clearTimeout(this.timeout);

		this.timeout = setTimeout(() => {
			this.copied = false;
			this.changeDetector.detectChanges();
		}, 2000);
	}

	get clipboardIsSupported(): boolean {
		return Boolean(!this.deviceService.isServer && this._window.navigator.clipboard);
	}
}
