<form class="chat__actions" [formGroup]="smsForm" *ngIf="smsForm" (ngSubmit)="submitSms()">
	<ng-container [ngSwitch]="smsStep">
		<ng-container *ngSwitchCase="smsStepStatus.default">
			<button
				b-shared-button
				class="btn btn--secondary btn--sm ml-auto"
				(click)="codeNotCome()"
			>
				<ng-container *ngIf="isOverlay; else defaultTemplate">
					Прислать новый код
				</ng-container>

				<ng-template #defaultTemplate>
					Код не&nbsp;пришёл :(
				</ng-template>
			</button>
		</ng-container>

		<ng-container *ngSwitchCase="smsStepStatus.codeNotCome">
			<button
				b-shared-button
				class="btn btn--secondary btn--sm"
				(click)="changePhoneNumber()"
				[ngClass]="{
					'mb-8': isOverlay
				}"
			>
				Изменить номер
				<ng-container *ngIf="isOverlay">
					телефона
				</ng-container>
			</button>
			<button
				b-shared-button
				(click)="sendCodeAgain()"
				[disabled]="isOverlay && smsBlockingService.timeToUnlockSmsSending$ | async"
				[ngClass]="codeButtonClasses"
				[attr.class]="isOverlay ? 'btn btn--sm btn--secondary order-1' : 'btn btn--sm btn--primary mt-8'"
				(animationend)="animationEnd()"
				#repeatCodeButton
			>
				<ng-container *ngIf="isOverlay; else defaultTemplate">
					Прислать новый код
				</ng-container>

				<ng-template #defaultTemplate>
					Отправить код ещё раз
				</ng-template>
				<ng-container
					*ngIf="smsBlockingService.timeToUnlockSmsSending$ | async as time"
				>
					<ng-container *ngIf="isOverlay">
						через
					</ng-container>
					<span
						class="text-disabled ml-4"
						role="timer"
					>
						{{ time | timeCounter }}
					</span>
				</ng-container>
			</button>
		</ng-container>

		<ng-container *ngSwitchCase="smsStepStatus.sendNewCode">
			<button b-shared-button class="btn btn--secondary btn--sm ml-auto" (click)="codeNotCome()">
				Выслать новый код
			</button>
		</ng-container>
	</ng-container>

	<label
		class="field mt-8"
		[ngClass]="{
			'field--reg order-2': isOverlay
		}"
	>
		<input
			type="text"
			inputmode="numeric"
			[attr.placeholder]="'••••'"
			class="field__control"
			formControlName="smsCode"
			maxlength="4"
			#smsInput
		/>
	</label>
</form>
