export interface City {
	id: string;
	displayName: string;
	distance?: number;
	isServiced: boolean;
	coordinates: {
		latitude: number;
		longitude: number;
	};
	enabled: boolean;
}

export enum CitiesListView {
	empty,
	noService,
	default,
}

export interface CitiesListState {
	selectedCity: City;
	view: CitiesListView;
	filteredCitiesList: City[];
	availableCities: City[];
}

export interface SendApplicationReuqestBody {
	/** Наименование города, полученное из подсказок при поиске города: */
	city: string;
	/** Идентификатор компании, для которой оставляется заявка на встречу: */
	companyId: string;
};

export interface ApplicationForCity {
	/** Наименование города: */
	city: string;
	/** Часовой пояс города: */
	timezone: number;
	/**
	 * Количество имеющихся заявок на встречу в городе.
	 * Если фактическое количество больше, чем maxApplicationCount, значение applicationCount будет равно maxApplicationCount.
	 * И никогда не будет больше него.
	 */
	applicationCount: number;
	/** Необходимое количество заявок, чтобы в город приехал клиентский менеджер */
	maxApplicationCount: number;
};

export enum SendApplicationErrors {
	/** У клиента уже есть запланированная встреча в обслуживаемом городе */
	ExistAssignedMeet = 'ExistAssignedMeet',
	/** Указанный населенный пункт не является городом */
	IsNotCity = 'IsNotCity',
	/** Невозможно оставить заявку встречу, с описанием причины. Как правило, из-за блокировки в результате полученного отказа при проверке юрлица */
	Failure = 'Failure',
};
