<ng-container [ngSwitch]="message?.type">
	<div *ngSwitchCase="messageType.waiting" class="chat__message-body">
		<div>
			<p class="loader-dots my-8">
				<span class="loader-dots__dot svg"></span>
			</p>
		</div>
	</div>

	<b-registration-default-message *ngSwitchCase="messageType.waitSecond">
	</b-registration-default-message>

	<div *ngSwitchCase="messageType.errorMessage" class="chat__message-body">
		<div>
			<p
				*ngIf="message?.data?.errorMessage; else defaultError"
				class="text-md"
				[innerHTML]="message?.data?.errorMessage | safeHtml"
			></p>

			<ng-template #defaultError>
				<p class="text-md">
					Произошла ошибка при выполнении операции. Повтори попытку позже
				</p>
			</ng-template>
		</div>
	</div>

	<b-registration-default-message
		*ngSwitchCase="messageType.defaultMessageWithCustomContent"
		class="chat__message-body"
	>
		{{ message.data.content }}
	</b-registration-default-message>

	<div *ngSwitchCase="messageType.timer" class="chat__message-body">
		<div>
			<div
				*ngIf="message.data.timer | async as timer"
				class="text-md"
				role="timer"
			>
				{{ timer | timeCounter }}
			</div>
		</div>
	</div>

	<b-registration-default-message *ngSwitchCase="messageType.hi" class="chat__message-body">
		Привет! Это банк для бизнеса Бланк. Помогу тебе стать клиентом за&nbsp;3&nbsp;шага и&nbsp;5&nbsp;минут
	</b-registration-default-message>
	<b-registration-default-message *ngSwitchCase="messageType.whatDoYouWant" class="chat__message-body">
		Что хочешь сделать?
	</b-registration-default-message>
	

	<b-registration-default-message *ngSwitchCase="messageType.openAccountBuisnessWant" class="chat__message-body">
		Открыть счёт для бизнеса
	</b-registration-default-message>

	<b-registration-default-message *ngSwitchCase="messageType.enterPhoneNumber" class="chat__message-body">
		Введи номер телефона, чтобы открыть счёт
	</b-registration-default-message>

	<b-registration-default-message *ngSwitchCase="messageType.enterPhoneAndOpf" class="chat__message-body">
		Чтобы открыть счёт, введи номер телефона и&nbsp;выбери тип своей компании: ИП&nbsp;или ООО
	</b-registration-default-message>

	<b-registration-default-message *ngSwitchCase="messageType.conditions" class="chat__message-body">
		Продолжая, ты&nbsp;принимаешь условия 
		<a href="/agreement.pdf" target="_blank">пользовательского соглашения{{ isAvito ? ' банка' : '' }}</a>
	</b-registration-default-message>

	<b-registration-default-message
		*ngSwitchCase="messageType.needAccountForIndividualEntrepreneur"
		class="chat__message-body"
	>
		Нужен счёт для&nbsp;ИП
	</b-registration-default-message>

	<b-registration-default-message *ngSwitchCase="messageType.needAccountForLLC" class="chat__message-body">
		Нужен счёт для ООО
	</b-registration-default-message>

	<b-registration-default-message *ngSwitchCase="messageType.selectOpf" class="chat__message-body">
		Отлично, а&nbsp;теперь выбери тип компании
	</b-registration-default-message>

	<b-registration-default-message *ngSwitchCase="messageType.changePhoneNumber" class="chat__message-body">
		Изменить номер
	</b-registration-default-message>

	<b-registration-default-message
		*ngSwitchCase="messageType.enterNewPhoneNumber"
		class="chat__message-body"
	>
		Введи новый номер
	</b-registration-default-message>

	<b-registration-default-message *ngSwitchCase="messageType.noMyCompany" class="chat__message-body">
		Нет моей компании
	</b-registration-default-message>

	<b-registration-default-message
		*ngSwitchCase="messageType.taxHasNotAddedCompany"
		class="chat__message-body"
	>
		Хмм, может налоговая ещё не&nbsp;добавила компанию
	</b-registration-default-message>

	<b-registration-default-message *ngSwitchCase="messageType.enterRequisities" class="chat__message-body">
		Тогда укажи, пожалуйста, ИНН или ОГРН
	</b-registration-default-message>

	<b-registration-default-message *ngSwitchCase="messageType.recentlyRegistered" class="chat__message-body">
		Я&nbsp;недавно зарегистрировался
	</b-registration-default-message>

	<b-registration-default-message *ngSwitchCase="messageType.codeNotCome" class="chat__message-body">
		Код не&nbsp;пришёл
	</b-registration-default-message>

	<b-registration-default-message *ngSwitchCase="messageType.sendNewCode" class="chat__message-body">
		Выслать новый код
	</b-registration-default-message>

	<b-registration-default-message
		*ngSwitchCase="messageType.clarifyingNumberCorrectness"
		class="chat__message-body"
	>
		Номер указан верно?<br>{{ message.data.phoneNumber }}
	</b-registration-default-message>

	<b-registration-default-message
		*ngSwitchCase="messageType.clarifyingOfTypoPresence"
		class="chat__message-body"
	>
		Если есть опечатка&nbsp;&mdash; измени номер. Если всё верно&nbsp;&mdash; запроси код ещё раз
	</b-registration-default-message>

	<b-registration-default-message
		*ngSwitchCase="messageType.cantSendConfirmationCode"
		class="chat__message-body"
	>
		Почему-то не&nbsp;получается отправить код подтверждения
	</b-registration-default-message>

	<b-registration-default-message *ngSwitchCase="messageType.bankEmployeeCall" class="chat__message-body">
		Сейчас тебе позвонит сотрудник банка и&nbsp;поможет зарегистрировать счёт. Получится даже быстрее
	</b-registration-default-message>

	<b-registration-default-message
		*ngSwitchCase="messageType.enterNumberRegisteredInRussia"
		class="chat__message-body"
	>
		Введи номер, зарегистрированный в&nbsp;России. Он&nbsp;должен начинаться с&nbsp;+79...
	</b-registration-default-message>

	<b-registration-default-message *ngSwitchCase="messageType.selectedCompany" class="chat__message-body">
		<ng-container *ngIf="message?.data?.companyRequisitiesList">
			<ng-container *ngFor="let requisite of message.data.companyRequisitiesList; let last = last">
				{{ requisite }}<br *ngIf="!last">
			</ng-container>
		</ng-container>
	</b-registration-default-message>

	<b-registration-default-message *ngSwitchCase="messageType.meetingDate" class="chat__message-body">
		{{ message.data.date }} ({{ message.data.weekday }}) в&nbsp; {{ message.data.time }}
	</b-registration-default-message>

	<b-registration-default-message *ngSwitchCase="messageType.haveHolding" class="chat__message-body">
		У&nbsp;тебя есть другие компании. На&nbsp;этой встрече мы&nbsp;можем сразу открыть счета и&nbsp;для них
	</b-registration-default-message>

	<b-registration-default-message *ngSwitchCase="messageType.registrationMultipleCompany" class="chat__message-body">
		Откройте счёт сразу для нескольких компаний
	</b-registration-default-message>

	<b-registration-default-message
		*ngSwitchCase="messageType.noCompanyRegistrationMultiple"
		class="chat__message-body"
	>
		Не&nbsp;нужно
	</b-registration-default-message>

	<b-registration-default-message
		*ngSwitchCase="messageType.registrationCompleted"
		class="chat__message-body"
	>
		Регистрация завершена
	</b-registration-default-message>

	<b-registration-default-message
		*ngSwitchCase="messageType.documentsList"
		class="chat__message-body"
	>
		На&nbsp;встрече понадобятся:
		<ol class="ol mt-12" ngProjectAs="optionalContent">
			<li class="mt-4" *ngFor="let document of message.data.documents; trackBy: trackByItem">
				<span [innerHtml]="document | safeHtml"></span>
			</li>
		</ol>
	</b-registration-default-message>

	<b-registration-default-message
		*ngSwitchCase="messageType.accountAlreadyReserved"
		class="chat__message-body"
	>
		Счёт уже зарезервирован и&nbsp;ждёт в&nbsp;приложении
		{{ isAvito ? ' банка' : '' }}
	</b-registration-default-message>

	<ng-container *ngSwitchCase="messageType.pointCameraAtQrCode">
		<b-registration-default-message
			class="chat__message-body"
		>
			Наведи камеру на&nbsp;QR-код и&nbsp;нажми на&nbsp;ссылку<ng-container *ngIf="isAvito">,
				так ты&nbsp;попадёшь в&nbsp;магазин и&nbsp;сможешь скачать приложение банка
			</ng-container>
		</b-registration-default-message>
	</ng-container>

	<ng-container *ngSwitchCase="isOverlay && messageType.pointQrCode">
		<div class="chat__message-body">
			<svg
				b-shared-svg
				name="marketing_qr-code"
				title="Скачать мобильное приложение"
				width="200"
				aria-hidden="false"
				class="w-100p"
			></svg>
		</div>
	</ng-container>

	<ng-container *ngSwitchCase="messageType.viewRequsities">
		<div class="w-100p" *ngIf="selectedCompany?.companyId">

			<div class="d-flex flex-column flex-ai-end">
				<a
					b-shared-button
					class="btn btn--primary btn--sm"
					[href]="linkWeblk"
					target="_blank"
				>
					Войти в&nbsp;Личный кабинет
				</a>
				<button
					b-shared-button
					class="btn btn--secondary btn--sm mt-8"
					(click)="showRequisities()"
				>
					Посмотреть реквизиты
				</button>
			</div>

			<b-shared-overlay
				[overlayId]="onboardingOverlays.onboardingRequisities"
				type="popup"
				containerClass="popup--sm"
				[overlayClass]="isOverlay ? 'overlay-content--popup-reg' : ''"
				[routable]="false"
			>
				<ng-template #header>
					<h3 class="h3">Реквизиты</h3>
				</ng-template>

				<ng-template #content>
					<b-registration-requisites class="d-block px-8 pb-12"></b-registration-requisites>
				</ng-template>
			</b-shared-overlay>
		</div>
	</ng-container>

	<ng-container *ngSwitchCase="messageType.shareBank">
		<div class="w-100p">
			<div class="d-flex flex-jc-end">
				<button
					b-shared-button
					class="btn btn--secondary lg:btn--sm btn--lg lg:w-auto w-100p"
					(click)="shareBank()"
				>
					{{ shared ? 'Скопировано' : 'Поделиться Бланком' }}
				</button>
			</div>
		</div>

		<b-shared-overlay
			[overlayId]="onboardingOverlays.shareBank"
			[routable]="false"
			type="popup"
			containerClass="popup--sm"
		>
			<ng-template #header>
				<p class="text-sm px-8">
					Чтобы посоветовать Бланк, скопируй ссылку,
					а&nbsp;затем отправь её&nbsp;другу в&nbsp;любом мессенджере
				</p>
			</ng-template>

			<ng-template #content>
				<div class="px-8 pb-12">
					<button
						*ngIf="clipboardIsSupported"
						b-shared-button
						class="btn btn--primary btn--lg w-100p"
						(click)="copy()"
						icon="icons_24_1-5_check"
						[iconClass]="{
							'icon--xl': true,
							'd-none': !shared
						}"
						[ngClass]="{
							'btn-copy': shared
						}"
					>
						{{ shared ? 'Скопировано' : 'Скопировать ссылку' }}
					</button>
				</div>
			</ng-template>
		</b-shared-overlay>
	</ng-container>

	<div class="w-100p" *ngSwitchCase="messageType.downloadApp">
		<div class="d-flex flex-jc-end">
			<a
				b-shared-button
				class="btn btn--primary btn--lg w-100p"
				[href]="getUrlWithCookies('https://link.blanc.ru/app')"
				target="_blank"
			>
				Скачать приложение{{ isAvito ? ' банка' : '' }}
			</a>
		</div>
	</div>

	<b-registration-default-message *ngSwitchCase="messageType.meetHere" class="chat__message-body">
		Встретимся здесь
	</b-registration-default-message>

	<b-registration-default-message *ngSwitchCase="messageType.decideOfDate" class="chat__message-body">
		Хорошо, осталось определиться с&nbsp;датой и&nbsp;временем
	</b-registration-default-message>

	<div
		*ngSwitchCase="messageType.meetingPlaceOnMap"
		class="chat__message-body"
	>
		<b-registration-meeting-place-map [message]="message"></b-registration-meeting-place-map>
	</div>

	<b-registration-default-message
		*ngSwitchCase="messageType.remainsToAssignMeeting"
		class="chat__message-body"
	>
		Осталось только назначить встречу
	</b-registration-default-message>

	<b-registration-default-message *ngSwitchCase="messageType.specifyMeetingCity" class="chat__message-body">
		Укажи город, где можем встретиться
	</b-registration-default-message>

	<b-registration-default-message
		*ngSwitchCase="messageType.selectAnotherCompany"
		class="chat__message-body"
	>
		Счёт для этой компании уже открыт.
		<br>
		Выбери другую компанию
	</b-registration-default-message>

	<b-registration-default-message
		*ngSwitchCase="messageType.accountForCompanyIsAlreadyOpen"
		class="chat__message-body"
	>
		Счёт для этой компании уже открыт
	</b-registration-default-message>

	<b-registration-default-message
		*ngSwitchCase="messageType.enterOrganizationName"
		class="chat__message-body"
	>
		Найди свою компанию по&nbsp;ИНН, ОГРН, названию или ФИО гендира.
		Если компании нет&nbsp;&mdash; ты&nbsp;всегда можешь
		<a
			class="link"
			[href]="linkReg"
			target="_blank"
			(click)="openReg()"
		>
			открыть&nbsp;ИП или ООО в&nbsp;Бланке
		</a>
	</b-registration-default-message>

	<b-registration-default-message *ngSwitchCase="messageType.sentCode" class="chat__message-body">
		Отправили!
	</b-registration-default-message>

	<b-registration-default-message *ngSwitchCase="messageType.confirmPhoneNumber" class="chat__message-body">
		Подтверди номер кодом из&nbsp;смс
	</b-registration-default-message>

	<div *ngSwitchCase="messageType.codeVerifyThrottled" class="chat__message-body">
		<div>
			<p class="text-md" *ngIf="message?.data?.count; else withoutCount">
				Ты&nbsp;{{ message.data.count }}&nbsp;раз неверно ввёл код
			</p>
			<ng-template #withoutCount>
				<p class="text-md">
					Ты&nbsp;неверно ввёл код
				</p>
			</ng-template>
		</div>
	</div>

	<div *ngSwitchCase="messageType.blockingCodeEntry" class="chat__message-body">
		<div>
			<p class="text-md" *ngIf="message?.data?.time; else withoutTime">
				Мы&nbsp;временно заблокировали ввод кода из&nbsp;смс. Попробуй снова через 
				<span role="timer">{{ message?.data?.time }}</span>
			</p>
			<ng-template #withoutTime>
				<p class="text-md">
					Мы&nbsp;временно заблокировали ввод кода из&nbsp;смс. Повтори попытку позже
				</p>
			</ng-template>
		</div>
	</div>

	<b-registration-default-message
		*ngSwitchCase="messageType.tryEnteringCodeAgain"
		class="chat__message-body"
	>
		Попробуй ещё раз. Введи последний код из&nbsp;смс или запроси новый
	</b-registration-default-message>

	<b-registration-default-message
		*ngSwitchCase="messageType.waitingAccountOpening"
		class="chat__message-body"
	>
		Мы&nbsp;уже открываем счёт, осталось не&nbsp;долго
	</b-registration-default-message>

	<b-registration-default-message
		*ngSwitchCase="messageType.timeToDownloadApp"
		class="chat__message-body"
	>
		Самое время скачать приложение{{ isAvito ? ' банка' : '' }}
	</b-registration-default-message>

	<b-registration-default-message
		*ngSwitchCase="messageType.sadNews"
		class="chat__message-body"
	>
		Грустные новости <b-shared-emoji emoji="😔" classEmoji="h2" class="ml-4"></b-shared-emoji>
	</b-registration-default-message>

	<b-registration-default-message
		*ngSwitchCase="messageType.openingAccountRefusal"
		class="chat__message-body"
	>
		Не&nbsp;сможем открыть счёт 
		<ng-container *ngIf="message?.data?.organization">для {{ message.data.organization }}</ng-container>
	</b-registration-default-message>

	<b-registration-default-message *ngSwitchCase="messageType.emailForDetails" class="chat__message-body">
		За&nbsp;подробностями можно обратиться на&nbsp;почту{{ isAvito ? ' банка' : '' }}
		<a [attr.href]="message.data.emailUrl">
			help&#64;blanc.ru
		</a>
	</b-registration-default-message>

	<div class="w-100p" *ngSwitchCase="messageType.openAccountToAnotherNumber">
		<div
			class="d-flex lg:flex-jc-end flex-jc-center"
		>
			<button
				b-shared-button
				(click)="openAnotherAccount()"
				[ngClass]="{
					'link text-sm': !isOverlay,
					'btn btn--secondary btn--sm ml-auto': isOverlay
				}"
			>
				Открыть ещё один счёт на&nbsp;другой номер
			</button>
		</div>
	</div>

	<b-registration-default-message
		*ngSwitchCase="messageType.myCity"
		class="chat__message-body"
	>
		Мой город&nbsp;&mdash; {{ message?.data?.city }}
	</b-registration-default-message>

	<b-registration-default-message
		*ngSwitchCase="messageType.excellent"
		class="chat__message-body"
	>
		Принято! Менеджер уже получил всю информацию и&nbsp;скоро позвонит,
		чтобы назначить встречу и&nbsp;ответить на&nbsp;все вопросы
	</b-registration-default-message>

	<b-registration-default-message
		*ngSwitchCase="messageType.accountReservedAndDownloadApp"
		class="chat__message-body"
	>
		Мы&nbsp;зарезервировали для тебя счёт, самое время скачать приложение{{ isAvito ? ' банка' : '' }}
	</b-registration-default-message>

	<b-registration-default-message
		*ngSwitchCase="messageType.accountReservedAndQr"
		class="chat__message-body"
	>
	Мы&nbsp;зарезервировали для тебя счёт. Осталось скачать приложение{{ isAvito ? ' банка' : '' }}&nbsp;&mdash; 
	наведи камеру на&nbsp;QR-код<ng-container *ngIf="isAvito">. 
		Так ты&nbsp;попадёшь в&nbsp;магазин и&nbsp;сможешь скачать приложение банка
	</ng-container>
	</b-registration-default-message>

	<b-registration-default-message
		*ngSwitchCase="messageType.requestsHasBeenCollected"
		class="chat__message-body"
	>
		А&nbsp;ещё мы&nbsp;собрали достаточно заявок, наш менеджер скоро приедет к&nbsp;тебе
	</b-registration-default-message>

	<b-registration-default-message
		*ngSwitchCase="messageType.nearestDateOfDepartureMeeting"
		class="chat__message-body"
	>
		Начиная с&nbsp;{{ message?.data?.nearestDate | date: 'd MMMM' }} приедем 
		в&nbsp;удобное время и&nbsp;место, чтобы подписать документы
	</b-registration-default-message>

	<b-registration-default-message
		*ngSwitchCase="messageType.nearestDateOfOfficeMeeting"
		class="chat__message-body"
	>
		<ng-container *ngIf="message?.data?.withDeparture; else withoutDeparture">
			Или ты&nbsp;можешь 
		</ng-container>
		<ng-template #withoutDeparture>
			Можешь 
		</ng-template>
		приехать к&nbsp;нашему менеджеру, 
		начиная с&nbsp;{{ message?.data?.nearestDate | date: 'd MMMM' }}
	</b-registration-default-message>

	<b-registration-default-message
		*ngSwitchCase="messageType.hereIsHisAddress"
		class="chat__message-body"
	>
		Вот его адрес
	</b-registration-default-message>

	<b-registration-default-message
		*ngSwitchCase="messageType.alright"
		class="chat__message-body"
	>
		Хорошо
	</b-registration-default-message>

	<b-registration-default-message
		*ngSwitchCase="messageType.comeToMe"
		class="chat__message-body"
	>
		Приедьте ко&nbsp;мне
	</b-registration-default-message>

	<b-registration-default-message
		*ngSwitchCase="messageType.messagePublicPlace"
		class="chat__message-body"
	>
		Выбери для встречи свой офис или общественное место. Например, кафе, коворкинги или ТЦ.
		Там наверняка есть вкусный кофе, ароматный чай и&nbsp;булочки
	</b-registration-default-message>

</ng-container>

<div
	*ngIf="isEditable"
	class="chat__message-actions"
	[ngClass]="{
		'mb-0': (isEditableClientMessage$ | async) === true
	}"
>
	<button
		type="button"
		(click)="changeData($event)"
		class="link text-sm"
	>Изменить</button>
</div>
