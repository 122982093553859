import { Inject, Injectable } from '@angular/core';
import { Observable, of, timer, tap, take, fromEvent, filter, Subscription } from 'rxjs';
import { Guid } from 'guid-typescript';
import { ChatState, Message, MessageType, Senders } from '../types/chat.types';
import { RegistrationStore } from './registration.store';
import { RegistrationSteps } from '../types/registration.types';
import { DatePipe } from '@angular/common';
import { Company, SelectedCompany } from '../types/searchCompany.types';
import animateScrollTo from 'animated-scroll-to';
import { DeviceService, ScrollService, StateService, WINDOW } from 'shared';
import { animationTimings } from '../enums/chat.enums';

const initState: ChatState = {
	chatLoader: false,
	accumulation: false,
	editableStep: null,
	authLoader: false,
};

@Injectable({
	providedIn: 'root',
})
export class ChatService extends StateService<ChatState> {
	blockingLoader = false;
	visualViewportSubscription: Subscription;
	messageTimeouts = new Set();

	chatLoader$: Observable<boolean> = this.select((state) => state.chatLoader);
	editableStep$: Observable<RegistrationSteps> = this.select((state => state.editableStep));
	authLoader$ = this.select((state => state.authLoader));

	constructor(
		private store: RegistrationStore,
		private datePipe: DatePipe,
		private scrollService: ScrollService,
		private deviceService: DeviceService,
		@Inject(WINDOW) readonly _window: Window,
	) {
		super(initState);

		this.store.blockingLoader$.subscribe((blockingLoader) => (this.blockingLoader = blockingLoader));
	}

	resetState() {
		this.setState(initState);
		/** При обнулении стейта сбрасываем все активные таймауты, чтобы они не срабатывали после обнуления стейта: */
		this.messageTimeouts.forEach((timeoutId: any) => clearTimeout(timeoutId));
	}

	setAccumulation(accumulation: boolean) {
		this.setState({ accumulation });
	}

	setEditableStep(editableStep: RegistrationSteps) {
		this.setState({ editableStep });
	}

	getMessageContainerClass(message: Message) {
		const classes = {
			'chat__message': true,
			'chat__message--incoming': message?.from === Senders.bank,
			'chat__message--outgoing': message?.from === Senders.client,
			'chat__message--map': message?.type === MessageType.meetingPlaceOnMap,
			'mt-12': message?.type === MessageType.openAccountToAnotherNumber,
		};

		return Object.keys(classes)
			.filter((i) => classes[i])
			.join(' ');
	}

	addMessage(message: Message) {
		if (this.state.accumulation) {
			this.store.addStoredMessage(message);
		} else {
			this.store.addMessage(message);
		}
	}

	pushMessage(message: Message) {
		if (!message.id) {
			message.id = Guid.create().toString();
		}

		message.containerClass = this.getMessageContainerClass(message);

		if (message.timeout && !this.blockingLoader && !this.state.authLoader) {
			const timeoutId = setTimeout(() => {
				this.addMessage(message);
				this.messageTimeouts.delete(timeoutId);
			}, message.timeout);

			this.messageTimeouts.add(timeoutId);
		} else {
			this.addMessage(message);
		}
	}

	postponeAction(action: () => void, timeout: number) {
		if (timeout && !this.blockingLoader && !this.state.authLoader) {
			return timer(timeout).pipe(
				tap({
					next: () => action()
				}),
				take(1),
			);
		} else {
			action();
			return of(null).pipe(take(1));
		}
	}

	changeChatLoader(chatLoader: boolean) {
		this.setState({ chatLoader });
	}

	changeAuthLoader(authLoader: boolean) {
		this.setState({ authLoader });
	}

	deleteMessage(id: string): void {
		this.store.deleteMessage(id);
	}

	displaySelectedCompany(company: SelectedCompany & Company): void {
		const companyRequisitiesList = [];

		const name = company?.companyName || company?.shortname;
		const city = company?.city || company?.definedCity;
		const date = company?.registrationDate || company?.fnsRegistrationDate;

		if (name) {
			companyRequisitiesList.push(name);
		}
		if (company.inn) {
			companyRequisitiesList.push(`ИНН: ${company.inn}`);
		}
		if (company.ogrn) {
			companyRequisitiesList.push(`ОГРН: ${company.ogrn}`);
		}
		if (date || city) {
			const text = [date ? this.datePipe.transform(date, 'dd.MM.yyyy') : null, city]
				.filter(Boolean)
				.join(', ');

			if (text) {
				companyRequisitiesList.push(text);
			}
		}
		this.pushMessage({
			from: Senders.client,
			type: MessageType.selectedCompany,
			editableStep: company.hasOwnProperty('foundInPublicRefs') && !company.foundInPublicRefs ?
				RegistrationSteps.noCompanyInfoInTaxService :
				RegistrationSteps.searchCompany,
			data: { companyRequisitiesList },
		});
	}

	scrollRegistrationSection(registrationSection: HTMLElement, elementToScroll: HTMLElement | null = null, coords: number | null = null) {
		if (this.deviceService.openedInIframe) {
			this._window?.parent?.postMessage({
				type: 'blancScrollRegistration',
			}, '*');
			return;
		}

		const sectionIsVisible = this.scrollService.isInViewport(registrationSection);

		if (!sectionIsVisible) {
			return;
		}

		return (animateScrollTo as unknown as { default: typeof animateScrollTo }).default(
			typeof coords === 'number' ?
				coords :
				this.scrollService.getElementScrollPositions(registrationSection).bottom -
				(this._window.visualViewport ? this._window.visualViewport.height : this.scrollService.getViewportSize().viewportHeight),
			{
				cancelOnUserAction: true,
				minDuration: animationTimings.scroll,
				maxDuration: animationTimings.scroll,
				elementToScroll: elementToScroll || this._window,
			}
		).then(() => {
			const registrationInputs = Object.values<HTMLInputElement>(registrationSection.querySelectorAll('input'));
			const enabledInputs = registrationInputs.filter(inputElement => !inputElement?.disabled);

			if (enabledInputs.length) {
				const lastEnabledInput = enabledInputs[enabledInputs.length - 1];

				if (!lastEnabledInput?.focus) return;

				this.subscribeToVisualViewport(registrationSection);
				lastEnabledInput.focus();
			}
		});
	}

	subscribeToVisualViewport(registrationSection: HTMLElement) {
		if (this.visualViewportSubscription) return;
		/**
		 * Перед тем, как открылась клавиатура при фокусе инпута в реге на мобильном устройстве,
		 * подписываемся на изменения размеров видимой области без учёта размера клавиатуры. (visualViewport)
		 * При открытии клавиатуры скроллим страницу в нижнюю часть секции с регистрацией,
		 * чтобы были видны все новые сообщения:
		 */

		if (this._window.visualViewport) {
			this.visualViewportSubscription = fromEvent(this._window.visualViewport, 'resize')
				.pipe(
					filter(() => {
						return this.deviceService.isMobileDevice &&
							document?.activeElement &&
							registrationSection.contains(document.activeElement) &&
							document.activeElement.tagName === 'INPUT' &&
							this.scrollService.isInViewport(document.activeElement as any);
					}),
				)
				.subscribe(() => {
					animateScrollTo(
						this.scrollService.getElementScrollPositions(registrationSection).bottom - this._window.visualViewport.height,
						{
							cancelOnUserAction: false,
							minDuration: animationTimings.scroll,
							maxDuration: animationTimings.scroll,
						}
					);
				});
		}
	}

	unsubscribeFromVisualViewport() {
		this.visualViewportSubscription?.unsubscribe();
	}

}
