import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { ScrollService, DropdownComponent } from 'shared';
import { Subscription } from 'rxjs';
import { DateSelectionService } from '../../../services/date-selection.service';
import { MeetingAssignService } from '../../../services/meeting-assign.service';
import { MeetingService } from '../../../services/meeting.service';
import {
	AcessibleDate,
	AcessibleSlot,
	DateSelectionState,
} from '../../../types/registration.types';
import {RegistrationService} from '../../../services/registration.service';

@Component({
	selector: 'b-registration-date-selection',
	templateUrl: './date-selection.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateSelectionComponent implements OnInit, OnDestroy {
	slotsLoader = false;

	state: DateSelectionState = {
		dates: [],
		selectedDate: null,
		slots: [],
		selectedTime: null,
	};

	subscriptions: Subscription = new Subscription();

	@Input() registrationSection: ElementRef;
	@Input() scrollableChatContainer: boolean = false;

	/** Для реги в шторке */
	@Input() isOverlay: boolean = false;

	@ViewChild('dateDropdown') dateDropdown: DropdownComponent;
	@ViewChild('timeDropdown') timeDropdown: DropdownComponent;

	constructor(
		public dateSelectionService: DateSelectionService,
		private meetingAssignService: MeetingAssignService,
		private meetingService: MeetingService,
		private ref: ChangeDetectorRef,
		private scrollService: ScrollService,
		private registrationService: RegistrationService,
		public elementRef: ElementRef,
	) {}

	ngOnInit(): void {
		this.subscriptions.add(
			this.dateSelectionService.dateSelectionState$.subscribe((state) => {
				this.state = state;
				this.ref.detectChanges();
			}),
		);

		if (this.registrationService.state.isLanding && !this.scrollableChatContainer) {
			this.subscriptions.add(
				this.scrollService.subscribeOnTopPartVisible(this.registrationSection, () => {
					this.dateDropdown?.hideOverlay?.();
					this.timeDropdown?.hideOverlay?.();
					this.ref.detectChanges();
				})
			);
		}
	}

	ngOnDestroy(): void {
		this.subscriptions?.unsubscribe();
	}

	changeDate(selectedDate) {
		this.dateSelectionService.changeDate(selectedDate);

		this.slotsLoader = true;
		this.ref.detectChanges();

		this.subscriptions.add(
			this.dateSelectionService
				.getAccessibleSlots({
					selectedDate,
					geoZone: this.meetingService.state.selectedPolygon.id,
				})
				.subscribe({
					next: () => {
						this.slotsLoader = false;
						this.ref.detectChanges();
					},
					error: () => {
						this.slotsLoader = false;
						this.ref.detectChanges();
					},
				})
		);
	}

	changeTime(value) {
		this.dateSelectionService.changeTime(value);
	}

	assign(): void {
		if (this.slotsLoader) {
			return;
		}
		this.meetingAssignService.assign();
	}

	getUniqueDateProp = (date: AcessibleDate) => date?.iso || date?.title;
	getUniqueSlotProp = (slot: AcessibleSlot) => slot.id;

	get submitButtonIcon(): string {
		return this.isOverlay
			? 'icons_scaling_arrow-up'
			: 'icons_16_2_arrow-up';
	}
}
